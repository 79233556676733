import { ProvidedActivity } from "../../../session-sdk";
import getArrayWithoutValue from "../../../utils/get_array_without_value";
import { NotatorCareProvisionTabContext } from "../containers/care_provision_list_renderer";
import { NotatorTools, useNotatorTools } from "../tools";
import { CareProvision, CareProvisionMode, OtherField } from "../types/care_provision";
import { NotatorStudentTools } from "./use_edit_session_student";
import produce from "immer";
export default function useCareProvisionDraftEditor<T extends CareProvision>(
  notatorTools: NotatorTools,
  studentTools: NotatorStudentTools,
  context: NotatorCareProvisionTabContext<T>,
) {
  const { readOnly } = useNotatorTools();
  const { editDraftStudent: editStudent } = studentTools;
  const config = getConfig(context.dotNotationIndexer, studentTools);

  // ----- DRAFT PROVIDED LIST UPDATERS -----

  function incrementByName(name: string, v: number) {
    const isAlreadyProvided = getIsAlreadyProvided(name);

    if (isAlreadyProvided) {
      const newCareProvisions = config.studentDraftProvidedItems
        .map((a) => {
          if (a.name === name) {
            a.increments = Math.max(0, a.increments! + v);
          }

          return a;
        })
        .filter((a) => a.increments! > 0);

      editStudent(config.studentCareProvisionListQuery, newCareProvisions);
    } else {
      const provisionsWithRequested: CareProvision[] = [
        ...config.studentDraftProvidedItems,
        { increments: 1, name: name },
      ];

      editStudent(config.studentCareProvisionListQuery, provisionsWithRequested);
    }
  }

  function toggleName(name: string) {
    if (readOnly) return;
    const isAlreadyProvided = getIsAlreadyProvided(name);

    if (isAlreadyProvided) {
      const provisionsWithoutRequested: CareProvision[] = config.studentDraftProvidedItems!.filter(
        (a) => a.name !== name,
      );

      editStudent(config.studentCareProvisionListQuery, provisionsWithoutRequested);
    } else {
      const provisionsWithRequested: CareProvision[] = [
        ...config.studentDraftProvidedItems,
        { increments: 1, name: name },
      ];

      editStudent(config.studentCareProvisionListQuery, provisionsWithRequested);
    }
  }

  function updateCustomCareProvisions(v: OtherField[], tabView: string, selectedStudentIndex?: number) {
    const validatedOthers = v.filter((e) => !e.status.unsaveable).map((e) => e.name) ?? [];
    const newCustomCareProvisionsLedger: string[] = [
      ...context.savedCustomCareProvisionLedger,
      ...validatedOthers,
    ];

    const provisionsThatWereCheckedBeforeTheyWereTyped = v.filter(
      (o) =>
        !o.status.unsaveable &&
        o.increments === 1 &&
        // @ts-ignore
        !studentTools.draftStudent.careProvisionLedger?.[tabView]?.some((a) => a.name === o.name),
    );

    if (provisionsThatWereCheckedBeforeTheyWereTyped.length > 0) {
      provisionsThatWereCheckedBeforeTheyWereTyped.forEach((provision) => {
        if (
          typeof selectedStudentIndex === "number" &&
          // @ts-ignore
          notatorTools.draftSession?.sessionJournal?.customCareProvisionLedger?.[tabView]
        ) {
          const updatedSession = produce(notatorTools.draftSession, (draft) => {
            // @ts-ignore
            draft.sessionJournal!.customCareProvisionLedger![tabView]!.push(provision.name);
            // @ts-ignore
            draft.studentJournalList![selectedStudentIndex].careProvisionLedger![tabView]?.push({
              name: provision.name,
              increments: 1,
            });
          });
          notatorTools.setDraftSession(updatedSession);
        }
      });
    } else {
      notatorTools.editDraft(
        "sessionJournal.customCareProvisionLedger." + context.dotNotationIndexer,
        newCustomCareProvisionsLedger,
      );
    }
  }

  function deleteCustom(id: string) {
    const newCustomCareProvisionLedger = getArrayWithoutValue(
      context.draftCustomCareProvisionLedger,
      id,
    );

    notatorTools.editDraft(
      "sessionJournal.customCareProvisionLedger." + context.dotNotationIndexer,
      newCustomCareProvisionLedger,
    );
  }

  function setTimeForAdlIadl(
    name: string,
    totalMinutes: number,
    increment: number,
    averageMinutes: number,
  ) {
    const isAlreadyProvided = getIsAlreadyProvided(name);

    if (isAlreadyProvided) {
      const newCareProvisions = produce(config.studentDraftProvidedItems, (draft) => {
        return draft.map((a: ProvidedActivity) => {
          if (a.name === name) {
            return {
              ...a,
              totalMinutesSpent: totalMinutes,
              increments: increment,
              averageMinutes: averageMinutes,
            };
          }
          return a;
        });
      });

      editStudent(config.studentCareProvisionListQuery, newCareProvisions);
    } else {
      const provisionsWithRequested: CareProvision[] = [
        ...config.studentDraftProvidedItems,
        {
          increments: increment,
          name: name,
          totalMinutesSpent: totalMinutes,
          averageMinutes: averageMinutes,
        },
      ];
      editStudent(config.studentCareProvisionListQuery, provisionsWithRequested);
    }
  }

  // ----- RETURN STATEMENT -----

  return {
    incrementByName,
    toggleName,
    updateCustomCareProvisions,
    deleteCustom,
    setTimeForAdlIadl,
  };

  // ----- HELPERS -----

  function getConfig(mode: CareProvisionMode, studentTools: NotatorStudentTools) {
    const { draftStudent: editedStudent } = studentTools;

    return {
      studentCareProvisionListQuery: "careProvisionLedger." + mode,
      studentDraftProvidedItems: getStudentCareProvisions(),
    };

    function getStudentCareProvisions(): CareProvision[] {
      switch (mode) {
        case "activities":
          return editedStudent.careProvisionLedger!.activities ?? [];
        case "accommodations":
          return editedStudent.careProvisionLedger!.accommodations ?? [];
        case "modifications":
          return editedStudent.careProvisionLedger!.modifications ?? [];
        default:
          throw new Error(
            "Fallthrough in switch statement. Has a new care provision been introduced?",
          );
      }
    }
  }

  function getIsAlreadyProvided(name: string) {
    return Boolean(config.studentDraftProvidedItems.find((a) => a.name === name));
  }
}
