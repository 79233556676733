import { useEffect, useState } from "react";
import isEmptyOrSpaces from "../../../../utils/is_empty_or_spaces";
import Box from "../../../../design/components-dev/BoxExtended";
import { TextField, Tooltip } from "@mui/material";
import XNGToggleGroup from "../../../../design/low-level/button_togglegroup";
import { XNGIconRenderer, XNGICONS } from "../../../../design";
import usePalette from "../../../../hooks/usePalette";
import { getSizing } from "../../../../design/sizing";
import { BORDER_RADIUSES } from "../../../../design/borderRadiuses";

type CareProvisionMode = "activities" | "accommodations" | "modifications";
type OtherStatus = { unsaveable: boolean; userFeedback: string };
type OtherField = { increments: number; name: string; status: OtherStatus };

// This is a container or "smart" component, it's in charge of its own state.
export function UnlockedCareProvisionsContainer(props: {
  onBlur: (v: OtherField[]) => void;
  validationDependencies: {
    defaultIDs: string[];
    savedCustomCareProvisionLedger: string[];
    mode: CareProvisionMode;
  };
  onDelete: (index: number) => void;
}) {
  const { otherFields, otherFieldsSetter } = useOtherFields({
    validationDependencies: {
      defaultIDs: props.validationDependencies.defaultIDs,
      customCareProvisionLedger: props.validationDependencies.savedCustomCareProvisionLedger,
      mode: props.validationDependencies.mode,
    },
  });

  return (
    <>
      {otherFields.map((other, i) => {
        const renderValidBecauseItsTheOnlyOneOnTheScreen = otherFields.length === 1;
        const renderValidBecauseItsTheLastItem = i === otherFields.length + -1;
        const overrideStatus =
          renderValidBecauseItsTheOnlyOneOnTheScreen || renderValidBecauseItsTheLastItem;

        return (
          <UnsavedCustomCareProvisionControl
            key={i}
            status={overrideStatus ? { unsaveable: false, userFeedback: "" } : other.status}
            name={other.name}
            onBlur={() => props.onBlur(otherFields)}
            onChange={(e) => otherFieldsSetter.updateNameByIndex(i, e)}
            onDelete={() => props.onDelete(i)}
          />
        );
      })}
    </>
  );
}

function useOtherFields(deps: {
  validationDependencies: {
    defaultIDs: string[];
    customCareProvisionLedger: string[];
    mode: CareProvisionMode;
  };
}) {
  // Get Refresh Switch
  // const { softRefreshSwitch: refreshSwitch } = useNotatorTools();
  const defaultIDs = deps.validationDependencies.defaultIDs.map((id) => {
    return { increments: 0, name: id, status: { unsaveable: false, userFeedback: "" } };
  });
  // ------ SINGLE STATE ------
  const [otherFields, setOtherFields] = useState<OtherField[]>([...defaultIDs, getBlankOther()]);
  // ------ STATE UPDATE HANDLERS ------

  function updateNameByIndex(
    i: number,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const _edited = [...otherFields];
    _edited[i].status = validateOther(e.target.value);
    _edited[i].name = e.target.value;
    setOtherFields(_edited);
  }

  function updateCheckByIndex(i: number) {
    const _edited = [...otherFields];
    _edited[i].increments = Number(!Boolean(_edited[i].increments));
    setOtherFields(_edited);
  }

  function reset() {
    setOtherFields([getBlankOther()]);
  }

  function appendEmpty() {
    const _edited = [...otherFields];
    _edited.push(getBlankOther());
    setOtherFields(_edited);
  }

  // ------ LISTENING STATE UPDATERS ------

  // On `edited` change
  useEffect(() => {
    // Append if necessary
    const hasEmptyProvision = otherFields.filter((e) => e.name === "").length > 0;
    if (!hasEmptyProvision) {
      appendEmpty();
    }
  }, [otherFields]);

  useEffect(() => {
    setOtherFields([...defaultIDs]);
  }, [deps.validationDependencies.defaultIDs.length]);

  // On refresh
  // useEffect(() => {
  //   reset();
  // }, [refreshSwitch]);

  // ----- VALIDATION -----

  function validateOther(otherID: string): OtherStatus {
    const careProvisionStr = getCapitalizedSingleCareProvisionString(
      deps.validationDependencies.mode,
    );

    const entryIsSpaces = isEmptyOrSpaces(otherID);
    if (entryIsSpaces) return { unsaveable: true, userFeedback: "" };

    const entryAlreadyFoundInDefaults = deps.validationDependencies.defaultIDs
      .map((id) => normalize(id))
      .includes(normalize(otherID));
    if (entryAlreadyFoundInDefaults)
      return {
        unsaveable: true,
        userFeedback: `${careProvisionStr} already exists, this will not save`,
      };

    const entryAlreadyFoundInLockedOthers = deps.validationDependencies.customCareProvisionLedger
      ?.map((a) => normalize(a))
      ?.includes(normalize(otherID));
    if (entryAlreadyFoundInLockedOthers)
      return {
        unsaveable: true,
        userFeedback: `${careProvisionStr} already exists, this will not save`,
      };

    const entryAlreadyFoundInUnlockedOthers = otherFields
      .map((o) => o.name.toLowerCase())
      .includes(otherID.toLowerCase());
    if (entryAlreadyFoundInUnlockedOthers)
      return {
        unsaveable: true,
        userFeedback: `${careProvisionStr} already exists, this will not save`,
      };

    return { unsaveable: false, userFeedback: "" };
  }

  // ----- RETURN STATEMENT -----

  return { otherFields: otherFields, otherFieldsSetter: { updateNameByIndex, updateCheckByIndex } };

  // ----- HELPERS -----
  function getBlankOther(): OtherField {
    return { increments: 0, name: "", status: { unsaveable: true, userFeedback: "" } };
  }
}

// is this what it's called?
function normalize(str: string) {
  return str.toLowerCase().trim();
}

function UnsavedCustomCareProvisionControl(props: {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  status: OtherStatus;
  onDelete: () => void;
}) {
  const palette = usePalette();
  const options = [
    {
      onClick: () => props.onDelete(),
      label: <XNGIconRenderer color={palette.contrasts[5]} i={<XNGICONS.Close />} size="12px" />,
      sx: {
        bgcolor: palette.danger[2],
        ":hover": { bgcolor: palette.danger[2] },
        ":active": { bgcolor: palette.danger[2] },
      },
    },
  ];
  return (
    <Box
      sx={{
        "#show": {
          display: "none",
        },
        ":hover": {
          bgcolor: palette.contrasts[4],
          cursor: "pointer",
          "#show": {
            display: "flex",
          },
        },
        minHeight: getSizing(5),
        position: "relative",
        alignItems: "center",
        borderRadius: BORDER_RADIUSES[0],
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Tooltip
          open={props.status.userFeedback !== ""}
          title={props.status.userFeedback}
          placement="bottom"
        >
          <TextField
            fullWidth
            error={props.status.unsaveable}
            onBlur={(e) => props.onBlur(e)}
            size="small"
            onChange={(e) => props.onChange(e)}
            value={props.name}
          />
        </Tooltip>
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="noselect"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // position: "absolute",
            // right: "0px",
            // bottom: "0px",
            // height: "100%",
          }}
          id="show"
        >
          <XNGToggleGroup options={options} />
        </Box>
      </Box>
    </Box>
  );
}

function getCapitalizedSingleCareProvisionString(mode: CareProvisionMode): string {
  switch (mode) {
    case "accommodations":
      return "Accommodation";
    case "modifications":
      return "Modification";
    case "activities":
      return "Activity";
    default:
      throw new Error(
        "Fall-through in switch statement. Has a new care provision been introduced?",
      );
  }
}
