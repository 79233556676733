import { useMemo } from "react";
import dayjs from "dayjs";
import { useNotatorTools } from "../tools";
import { PERSONAL_CARE_SERVICES_NAME } from "../validation/constants";

const useCheckIfPersonalCareSession = () => {
  const notatorTools = useNotatorTools();

  // Use memoization to avoid recalculation on every render
  const isPersonalCareSession = useMemo(() => {
    const sessionDetails = notatorTools?.session?.meetingDetails;
    const serviceAreaName = notatorTools?.session?.service?.area?.name;

    if (!sessionDetails?.startTime || !serviceAreaName) return false;

    const sessionDate = dayjs(sessionDetails.startTime);
    const cutoffDate = dayjs("2024-10-01");

    return (
         notatorTools.session.service?.area?.name?.trim().toLocaleLowerCase() === PERSONAL_CARE_SERVICES_NAME.trim().toLocaleLowerCase() && (sessionDate.toDate() >= cutoffDate.toDate())
    );
  }, [notatorTools]);

  return isPersonalCareSession;
};

export default useCheckIfPersonalCareSession;
