
import { ExtendedStudentProgressReportRecordUIPaginatedReportResponse, ExtendedStudentProgressReportRecordUIWithIsPosted } from "../../types/extended_progress_repoting_types"
import { ProgressReportingStateType } from "../../types/progress_reporting_context_type" 
import { save,StorageKeys} from "../../utils/storage" 
import { GeneralCommentsPayloadManager, GoalObjectiveStatusPayloadManager, IsMakingAdequateProgressPayloadManager, MapUserInputsToRootPayloadManager, ProgressMeasuredPayloadManager } from "../payload.manager"
import { ProgressReportingAction, ProgressReportingActionType } from "../progress_reporting_actions"
import {produce} from "immer"

export enum ReportTypes {
    Goal = "Goal",
    Objective = "Objective"
}

export enum ReportStatus{
    Posted = "posted",
    Unposted = "unposted"
}

export enum GoalOrObjectiveStatus{
    Active = "active",
    InActive = "inactive", 
    Yes ="y",
    No = "n"

}
 
export function progressReportingReducer(state:ProgressReportingStateType,action:ProgressReportingAction):ProgressReportingStateType{
    switch(action.type){
        case ProgressReportingActionType.OPEN_PROGRESS_REPORTING_EDIT_SECTION: 
            const updatedState:ProgressReportingStateType = {
                ...state,
                isEditing: true
            }
            return updatedState
        case ProgressReportingActionType.CLOSE_PROGRESS_REPORTING_EDIT_SECTION:  
            return {
                ...state,
                isEditing: false
            }
        case ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_USER_PROFILE:
            return {
                ...state,
                currentlyLoggedInUserProfile: action.payload
            }
        case ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER:
            return {
                ...state,
                currentlyLoggedInServiceProvider: action.payload,
                settingsPopup: {
                    ...state.settingsPopup,
                    serviceProviderSelected: action.payload
                }
            }
        case ProgressReportingActionType.SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION:
            return {
                ...state,
                campusesSelectedForProgressReporting:action.payload
            }
        /**
         * SETTINGS REDUCERS
         */
        case ProgressReportingActionType.TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS: 
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showObjectives: action.payload
                }
            }

        case ProgressReportingActionType.TOGGLE_SHOW_CHART_FROM_SETTINGS:
                return {
                    ...state,
                    settingsPopup: {
                        ...state.settingsPopup,
                        showChart: action.payload
                    }
                }
        case ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showAggregatedData: action.payload
                }
            }
        case ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showLastSession: action.payload
                }
            }

        case ProgressReportingActionType.SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    serviceProviderSelected: action.payload
                }
            }    
        case ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showTeamAggregatedData: action.payload
                }
            }
        case ProgressReportingActionType.TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showActiveGoals: action.payload
                }
            }
        case ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES:
            return {
                ...state,
                allServiceProvidersUnderSelectedCampuses: action.payload
            }
        case ProgressReportingActionType.SET_YEAR_RANGE_FOR_CAMPUS:
            return {
                ...state,
                schoolYearRange:action.payload
            }
        case ProgressReportingActionType.SET_REPORTING_PERIOD: 
            return {
                ...state,
                reportingPeriods:action.payload
            }
        case ProgressReportingActionType.SET_STUDENT_PROGRESS_REPORT_FETCHED: 
            save(StorageKeys.ProgressReport,action.payload)  
            return {
                ...state,
                studentProgressReportFetched:action.payload
            }
 
        case ProgressReportingActionType.UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA:{
        /**
         * Payload example:
         * @example
         * {
         *   "goalId": number,
         *   "period": number,
         *   "generalComment": string
         * progressReportType
         * }
         */  
        const updateGeneralCommentsPayload:GeneralCommentsPayloadManager = action.payload 
        // if it is team aggregated
        if(state.settingsPopup.showTeamAggregatedData){
             const updatedTeamAggregatedData = state?.teamAggregatedProgressReport?.map(
                studentProgress=>{  
                      return {
                        ...studentProgress,
                        allStudentProgressReportUnderCurrentStudent: studentProgress?.allStudentProgressReportUnderCurrentStudent?.map(
                            studentProgressReportUnderCurrentStudent=>{ 
                              const isGoalType = (studentProgressReportUnderCurrentStudent?.goal.goalId === updateGeneralCommentsPayload?.goalId) && updateGeneralCommentsPayload.progressReportType === ReportTypes.Goal
                               if(isGoalType){ 
                                   return {
                                       ...studentProgressReportUnderCurrentStudent,
                                       goal:{
                                              ...studentProgressReportUnderCurrentStudent?.goal,
                                              goalProgressDetails:studentProgressReportUnderCurrentStudent?.goal?.goalProgressDetails?.map( (goalProgressDetail,goalProgressRowPosition) => { 
                                                                        const shouldUpdate = goalProgressDetail?.period === updateGeneralCommentsPayload?.period &&  
                                                                        updateGeneralCommentsPayload?.rowPosition === goalProgressRowPosition 
                                                                        if (shouldUpdate) { 
                                                                            return {
                                                                            ...goalProgressDetail, 
                                                                            generalComments: updateGeneralCommentsPayload?.generalComment,
                                                                            };
                                                                        } 
                                                                        return goalProgressDetail;  
                                                                      } 
                                                                    ) 
                                            }
                                    }
                               }else{ 
                                  return {
                                      ...studentProgressReportUnderCurrentStudent,
                                      objectives: studentProgressReportUnderCurrentStudent?.objectives?.map(
                                          (objective,objectiveRowPosition) => {
                                                 if(objectiveRowPosition === updateGeneralCommentsPayload?.objectiveProgressArrayIndex){ 
                                                     return {
                                                           ...objective,
                                                           objectiveProgressDetails: objective?.objectiveProgressDetails.map(
                                                             (objectiveProgressDetail,objectiveProgressRowPosition) => { 
                                                             const shouldUpdate = objectiveProgressDetail.period === updateGeneralCommentsPayload?.period &&  
                                                                                                                     updateGeneralCommentsPayload?.rowPosition === objectiveProgressRowPosition && 
                                                             
                                                                                                                    updateGeneralCommentsPayload?.objectiveId === objective?.objectiveId
                                                             
                                                            if (shouldUpdate) { 
                                                                 return {
                                                                     ...objectiveProgressDetail, 
                                                                     generalComments: updateGeneralCommentsPayload?.generalComment,
                                                                 };
                                                             } 
                                                             return objectiveProgressDetail;  
                                                             }
                                                         )  
                                                     }
                                                 }
                                                 return objective
                                          }
                                      )
                                  }
                               } 
                            }
                        )
                    }
                }
             )

             return {
                 ...state,
                 teamAggregatedProgressReport: updatedTeamAggregatedData
             }
        } 

        // if it is normal aggregation under currently selected service provider
        const updatedPageRecords = state?.studentProgressReportFetched?.pageRecords?.map(
            (studentProgress) => {
                const isGoalType = studentProgress?.goal?.goalId === updateGeneralCommentsPayload?.goalId && updateGeneralCommentsPayload.progressReportType === ReportTypes.Goal && studentProgress.serviceProviderId === state.settingsPopup.serviceProviderSelected?.id
                const isObjectiveType = studentProgress?.goal?.goalId === updateGeneralCommentsPayload?.goalId && updateGeneralCommentsPayload.progressReportType === ReportTypes.Objective && studentProgress.serviceProviderId === state.settingsPopup.serviceProviderSelected?.id
                if(isGoalType){ 
                    return {
                            ...studentProgress,
                            goal: {
                                ...studentProgress?.goal,
                                goalProgressDetails:studentProgress?.goal?.goalProgressDetails?.map(
                                    (goalProgressDetail,goalProgressRowPosition) => { 
                                                    const shouldUpdate = goalProgressDetail.period === updateGeneralCommentsPayload?.period &&  
                                                    updateGeneralCommentsPayload.rowPosition === goalProgressRowPosition
                                                    if (shouldUpdate) { 
                                                        return {
                                                        ...goalProgressDetail, 
                                                        generalComments: updateGeneralCommentsPayload.generalComment,
                                                        };
                                                    } 
                                                    return goalProgressDetail;  
                                    }
                                  )
                            }
                            };
                }
                else if(isObjectiveType){ 
                    return {
                        ...studentProgress,
                        objectives: studentProgress?.objectives?.map(
                            (objective,objectiveRowPosition) => {
                                    if(objectiveRowPosition === updateGeneralCommentsPayload?.objectiveProgressArrayIndex){ 
                                            return {
                                                ...objective,
                                                objectiveProgressDetails: objective.objectiveProgressDetails.map(
                                                    (objectiveProgressDetail,objectiveProgressRowPosition) => { 
                                                    const shouldUpdate = objectiveProgressDetail.period === updateGeneralCommentsPayload?.period &&  
                                                    updateGeneralCommentsPayload.rowPosition === objectiveProgressRowPosition && 
                                                    updateGeneralCommentsPayload?.objectiveId === objective.objectiveId
                                                    if (shouldUpdate) { 
                                                        return {
                                                        ...objectiveProgressDetail, 
                                                        generalComments: updateGeneralCommentsPayload.generalComment,
                                                        };
                                                    } 
                                                    return objectiveProgressDetail;  
                                                    }
                                                )  
                                            }
                                    }
                                    return objective
                            }
                        )
                    }
                }
                return studentProgress; 
            }
          );
          let studentProgressReportFetched = {
            ...state.studentProgressReportFetched,
            pageRecords: updatedPageRecords as ExtendedStudentProgressReportRecordUIWithIsPosted[],
          }
          save(StorageKeys.ProgressReport,studentProgressReportFetched)
          return {
            ...state,
            studentProgressReportFetched
          };
        }

        case ProgressReportingActionType.SET_TRANSFORMED_PROGRESS_REPORT:
            return {
                ...state,
                 studentProgressReportTransformed:action.payload
            }
        case ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS: {
                const { goalId, period, progressReportType, status, rowPosition, objectiveId,objectiveProgressArrayIndex }: GoalObjectiveStatusPayloadManager = action.payload;
                
                const currentUserName = `${state.currentlyLoggedInServiceProvider?.firstName} ${state.currentlyLoggedInServiceProvider?.lastName}`;
            
                // Handle team aggregated data update
                if (state.settingsPopup.showTeamAggregatedData) {
                    const updatedTeamAggregatedData = state?.teamAggregatedProgressReport?.map(studentProgress => ({
                        ...studentProgress,
                        allStudentProgressReportUnderCurrentStudent: studentProgress.allStudentProgressReportUnderCurrentStudent.map(
                            studentProgressReportUnderCurrentStudent => {
                                const isGoalType = studentProgressReportUnderCurrentStudent?.goal?.goalId === goalId && progressReportType === ReportTypes.Goal;
                                if (isGoalType) {
                                    return {
                                        ...studentProgressReportUnderCurrentStudent,
                                        goal: {
                                            ...studentProgressReportUnderCurrentStudent.goal,
                                            goalProgressDetails:studentProgressReportUnderCurrentStudent?.goal?.goalProgressDetails.map(
                                                (goalProgressDetail, goalProgressRowPosition) => {
                                                    const shouldUpdate = goalProgressDetail.period === period && rowPosition === goalProgressRowPosition;
                                                    
                                                    if (shouldUpdate) { 
                                                        return {
                                                            ...goalProgressDetail,
                                                            progressReportedBy: currentUserName,
                                                            reportFinalizedBy:currentUserName,
                                                            isFinalized: true
                                                        };
                                                    }
                                                    return goalProgressDetail;
                                                }
                                            )
                                        }
                                    };
                                } else {
                                    return {
                                        ...studentProgressReportUnderCurrentStudent,                                       
                                        objectives: studentProgressReportUnderCurrentStudent?.objectives?.map(
                                            (objective,objectiveProgressIndex) => {
                                                  if(objectiveProgressIndex === objectiveProgressArrayIndex){
                                                            return {
                                                                    ...objective,
                                                                    objectiveProgressDetails: objective.objectiveProgressDetails?.map(
                                                                    (objectiveProgressDetail,objectiveProgressRowPosition) => { 
                                                                                        const shouldUpdate = objectiveProgressDetail.period === period && objectiveProgressRowPosition === rowPosition && objective?.objectiveId === objectiveId
                                                                                        if (shouldUpdate) { 
                                                                                            return {
                                                                                                    ...objectiveProgressDetail, 
                                                                                                    progressReportedBy: currentUserName,
                                                                                                    reportFinalizedBy:currentUserName,
                                                                                                    isFinalized:true
                                                                                                };
                                                                                        } 
                                                                                        return objectiveProgressDetail;  
                                                                    }
                                                                )
                                                                
                                                            }
                                                  }
                                                  return objective;
                                            }
                                        )
                                    };
                                }
                            }
                        )
                    }));

                    return {
                        ...state, 
                        teamAggregatedProgressReport: updatedTeamAggregatedData,
                        isFinalizingProgressReport: false,
                    };
                }
            
                // Handle individual student progress update
                const updatedPageRecordWithPostUnpostStatus = state?.studentProgressReportFetched?.pageRecords?.map(
                    studentProgress => { 
                        const isGoalType = studentProgress?.goal?.goalId === goalId && progressReportType === ReportTypes.Goal && studentProgress.serviceProviderId === state.settingsPopup?.serviceProviderSelected?.id;
                        const isObjectiveType = studentProgress?.goal?.goalId === goalId && progressReportType === ReportTypes.Objective && studentProgress.serviceProviderId === state.settingsPopup?.serviceProviderSelected?.id;
            
                        if (isGoalType) {
                            return {
                                ...studentProgress,
                                goal: {
                                    ...studentProgress.goal,
                                    goalProgressDetails:studentProgress?.goal?.goalProgressDetails?.map(
                                        (goalProgressDetail, goalProgressDetailsRowPosition) => {
                                            const shouldUpdate = goalProgressDetail?.period === period && 
                                                               goalProgressDetailsRowPosition === rowPosition;
                                            
                                            if (shouldUpdate) { 
                                                return {
                                                    ...goalProgressDetail, 
                                                       progressReportedBy: currentUserName,
                                                       isFinalized: true ,
                                                       reportFinalizedBy:currentUserName,
                                                };
                                            }
                                            return goalProgressDetail;
                                        }
                                    )
                                }
                            };
                        }
            
                        else if (isObjectiveType) {
                            return {
                                ...studentProgress,
                                objectives: studentProgress?.objectives?.map(
                                    (objective,objectiveProgressIndex) => {
                                          if(objectiveProgressIndex === objectiveProgressArrayIndex){
                                                    return {
                                                            ...objective,
                                                            objectiveProgressDetails: objective?.objectiveProgressDetails?.map(
                                                            (objectiveProgressDetail,objectiveProgressRowPosition) => { 
                                                                                const shouldUpdate = objectiveProgressDetail?.period === period && objectiveProgressRowPosition === rowPosition && objective?.objectiveId === objectiveId
                                                                                if (shouldUpdate) { 
                                                                                    return {
                                                                                            ...objectiveProgressDetail,  
                                                                                            progressReportedBy: currentUserName,
                                                                                            isFinalized: true,
                                                                                            reportFinalizedBy:currentUserName,
                                                                                           
                                                                                        };
                                                                                } 
                                                                                return objectiveProgressDetail;  
                                                            }
                                                        )
                                                        
                                                    }
                                          }
                                          return objective;
                                    }
                                )
                            };
                        }
            
                        return studentProgress;
                    }
                ) as ExtendedStudentProgressReportRecordUIWithIsPosted[]
            
                const updatedStudentProgress = {
                    ...state.studentProgressReportFetched,
                    pageRecords: updatedPageRecordWithPostUnpostStatus
                };
            
                return {
                    ...state,
                    studentProgressReportFetched: updatedStudentProgress,
                    isFinalizingProgressReport: false
                };
            }
        case ProgressReportingActionType.MAP_TEAM_AGGREGATED_FINALIZED_DATA_TO_ROOT_PROGRESS_REPORT_UNDER_EACH_SERVICE_PROVIDERS:{
                const { 
                    goalId, 
                    period, 
                    progressReportType, 
                    rowPosition, 
                    objectiveId,
                    objectiveProgressArrayIndex,
                    generalComments,
                    isMakingAdequateProgress:makingAdequateProgress,
                    progressMeasured
                }: MapUserInputsToRootPayloadManager = action.payload;
                const currentUserName = `${state.currentlyLoggedInServiceProvider?.firstName} ${state.currentlyLoggedInServiceProvider?.lastName}`;
                // finalize all goals or objectives if team aggregated and report is finalized 
                if(!state?.settingsPopup?.showTeamAggregatedData)return state
                const updatedStudentProgress = produce(state.studentProgressReportFetched, (draft:ExtendedStudentProgressReportRecordUIPaginatedReportResponse) => {
                    draft.pageRecords?.forEach(studentProgress => {
                        const isGoalType = 
                            studentProgress?.goal?.goalId === goalId && 
                            progressReportType === ReportTypes.Goal  
                
                        const isObjectiveType = 
                            studentProgress?.goal?.goalId === goalId && 
                            progressReportType === ReportTypes.Objective  
                        
                        if (isGoalType) {
                            studentProgress.goal?.goalProgressDetails?.forEach((goalProgressDetail, goalProgressDetailsRowPosition) => {
                                const shouldUpdate = 
                                    goalProgressDetail?.period === period && 
                                    goalProgressDetailsRowPosition === rowPosition;
                                const wasPosted = goalProgressDetail?.status?.toLocaleLowerCase() == ReportStatus.Posted
                                if (shouldUpdate && wasPosted) {
                                    Object.assign(goalProgressDetail, {
                                      
                                                progressReportedBy: currentUserName,
                                                isFinalized: true,
                                                generalComments,
                                                progressMeasured,
                                                makingAdequateProgress
                                             
                                    });
                                }
                            });
                        } else if (isObjectiveType) {
                            studentProgress.objectives?.forEach((objective, objectiveProgressIndex) => {
                                if (objectiveProgressIndex === objectiveProgressArrayIndex) {
                                    objective.objectiveProgressDetails?.forEach((objectiveProgressDetail, objectiveProgressRowPosition) => {
                                        const shouldUpdate = 
                                            objectiveProgressDetail?.period === period && 
                                            objectiveProgressRowPosition === rowPosition && 
                                            objective?.objectiveId === objectiveId;
                                        const wasObjectivePosted = objectiveProgressDetail?.status?.toLocaleLowerCase() === ReportStatus?.Posted
                                        if (shouldUpdate&&wasObjectivePosted) {
                                            Object.assign(objectiveProgressDetail, {
                                           
                                                    progressReportedBy: currentUserName,
                                                    isFinalized: true,
                                                    generalComments,
                                                    progressMeasured,
                                                    makingAdequateProgress 
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                });

                return {
                    ...state,
                    studentProgressReportFetched: updatedStudentProgress
                }
                                
        }
        case ProgressReportingActionType.SET_PRINTING_SIGNAL:
            return {
                ...state,
                printingSignal:action.payload
            }
        case ProgressReportingActionType.SET_GENERATING_XLSX_SIGNAL: 
            return {
                ...state,
                isGeneratingXLSX:action.payload
            }
        case ProgressReportingActionType.UPDATE_POLLING_STATUS:
                return {
                    ...state,
                    pollingStatus:action.payload
                }
        case ProgressReportingActionType.UPDATE_SUMMARY_GENERATION_STATUS:
            return {
                ...state,
                 summaryGenerationStatus:action.payload
            }

        // UPDATE POST UNPOST STATUS OF TRANSFORMED
        case ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_USED_IN_FILTER:
            return {
                ...state,
                serviceProvidersSelectedOnFilter:action.payload
            }
        case ProgressReportingActionType.SET_AGGREGATED_GROUPED_PROGRESS_REPORT:
            return {
                ...state,
                aggregatedProgressReport:action.payload
            }
        case ProgressReportingActionType.SET_TEAM_AGGREGATED_GROUPED_PROGRESS_REPORT:
            return {
                ...state,
                teamAggregatedProgressReport:action.payload
            }
        case ProgressReportingActionType.UPDATE_PROGRESS_MEASURED_IN_REPORTING_DATA:{
            const updateProgressMeasuredPayload:ProgressMeasuredPayloadManager = action.payload 

            if(state.settingsPopup.showTeamAggregatedData){
                const updatedTeamAggregatedData = state?.teamAggregatedProgressReport?.map(
                   studentProgress=>({
                         ...studentProgress,
                         allStudentProgressReportUnderCurrentStudent : studentProgress.allStudentProgressReportUnderCurrentStudent.map(
                             studentProgressReportUnderCurrentStudent=>{
                                const isGoalType = studentProgressReportUnderCurrentStudent?.goal.goalId === updateProgressMeasuredPayload?.goalId && updateProgressMeasuredPayload.progressReportType === ReportTypes.Goal; 
                                if(isGoalType){ 
                                    return {
                                        ...studentProgressReportUnderCurrentStudent,
                                        goal: {
                                            ...studentProgressReportUnderCurrentStudent?.goal,
                                           goalProgressDetails: studentProgressReportUnderCurrentStudent?.goal?.goalProgressDetails.map((goalProgressDetail,goalProgressRowPosition) => { 
                                            const shouldUpdate = goalProgressDetail.period === updateProgressMeasuredPayload?.period && 
                                                updateProgressMeasuredPayload.rowPosition === goalProgressRowPosition;
                                            if (shouldUpdate) { 
                                                return {
                                                ...goalProgressDetail, 
                                                aggregatedProgress: updateProgressMeasuredPayload.progressMeasured,
                                                };
                                            } 
                                            return goalProgressDetail;  
                                            } )}
                                    } 
                                }else{ 
                                    return {
                                        ...studentProgressReportUnderCurrentStudent,
                                        objectives: studentProgressReportUnderCurrentStudent?.objectives?.map((objective,objectiveArrayIndexPosition)=>{
                                             if(objectiveArrayIndexPosition === updateProgressMeasuredPayload?.objectiveProgressArrayIndex){
                                                return {...objective, 
                                                        objectiveProgressDetails: objective?.objectiveProgressDetails?.map(
                                                            (objectiveProgressDetail,objectiveProgressDetailRowPosition) => { 
                                                                const shouldUpdate = objectiveProgressDetail.period === updateProgressMeasuredPayload?.period && 
                                                                objectiveProgressDetailRowPosition === updateProgressMeasuredPayload?.rowPosition && objective?.objectiveId === updateProgressMeasuredPayload?.objectiveId
                                                                if(shouldUpdate)
                                                                    return {
                                                                        ...objectiveProgressDetail, 
                                                                        aggregatedProgress: updateProgressMeasuredPayload.progressMeasured
                                                                    } 
                                                                return objectiveProgressDetail
                                                                
                                                            }
                                                        ) 
                                                    }
                                             }

                                             return objective
                                        })
                                    }
                                }  
                             }
                         ) 
                   })
                )
   
                return {
                    ...state,
                    teamAggregatedProgressReport: updatedTeamAggregatedData
                }
           } 
            const updatedProgressReportedData = state?.studentProgressReportFetched?.pageRecords?.map(
                (studentProgress) => {
                    const isGoalType = studentProgress?.goal?.goalId === updateProgressMeasuredPayload.goalId && updateProgressMeasuredPayload.progressReportType === ReportTypes.Goal && studentProgress.serviceProviderId === state.settingsPopup?.serviceProviderSelected?.id;;
                    const isObjectiveType = studentProgress?.goal?.goalId === updateProgressMeasuredPayload.goalId && updateProgressMeasuredPayload.progressReportType === ReportTypes.Objective && studentProgress.serviceProviderId === state.settingsPopup?.serviceProviderSelected?.id;
                    if(isGoalType){
                                return {
                                ...studentProgress,
                                goal: {
                                    ...studentProgress?.goal,
                                    goalProgressDetails:studentProgress?.goal?.goalProgressDetails?.map(
                                    (goalProgressDetail,goalProgressDetailsRowPosition) => { 
                                    const shouldUpdate = goalProgressDetail.period === updateProgressMeasuredPayload.period && goalProgressDetailsRowPosition === updateProgressMeasuredPayload.rowPosition
                                    if (shouldUpdate) {
                                            return {
                                              ...goalProgressDetail, 
                                              progressMeasured: updateProgressMeasuredPayload.progressMeasured
                                            } 
                                    }
                                    return goalProgressDetail;  
                                    }
                                )}
                                };
                    }else if(isObjectiveType){
                        return {
                            ...studentProgress,
                            objectives:studentProgress.objectives?.map((objective,objectiveArrayIndexPosition)=>{
                                if(objectiveArrayIndexPosition === updateProgressMeasuredPayload?.objectiveProgressArrayIndex){
                                    
                                    return {
                                       ...objective,
                                      objectiveProgressDetails: objective?.objectiveProgressDetails?.map((objectiveProgressDetail,objectiveProgressArrayIndexPosition)=>{
                                        const shouldUpdate = objectiveProgressDetail.period === updateProgressMeasuredPayload?.period &&  updateProgressMeasuredPayload.rowPosition === objectiveProgressArrayIndexPosition;
                                        if(shouldUpdate){
                                            return {
                                                ...objectiveProgressDetail, 
                                                progressMeasured: updateProgressMeasuredPayload.progressMeasured
                                            }
                                        }
                                        return objectiveProgressDetail
                                      })
                                    
                                    }
                                }
                                return objective
                            }) 
                        }
                    } 
                    
                    return studentProgress; 
                }
            ) as ExtendedStudentProgressReportRecordUIWithIsPosted[]
 
              return {
                ...state,
                studentProgressReportFetched:{
                    ...state.studentProgressReportFetched,
                    pageRecords: updatedProgressReportedData,
                  }
                
              }};
        case ProgressReportingActionType.SET_PERFORMING_AGGREGATION_LOADING: 
            return {
                ...state,
                isPerformingAggregation: !state.isPerformingAggregation
            }
        case ProgressReportingActionType.SET_FINALIZING_PROGRESS_LOADER:
            return {
                ...state,
                isFinalizingProgressReport: true
            }
        case ProgressReportingActionType.SET_PROGRESS_REPORT_GENERATED_MASTER_COPY:{
            return {
                ...state,
                progressReportGeneratedMasterCopy:action.payload
            }
        }
 

        case ProgressReportingActionType.HANDLE_MAKING_ADEQUATE_PROGRESS: {
          const isMakingAdequateProgressPayload: IsMakingAdequateProgressPayloadManager = action.payload;
          let updatedState = produce(state, (draft:ProgressReportingStateType) => {
            if (draft.settingsPopup.showTeamAggregatedData) {
              draft.teamAggregatedProgressReport?.forEach(studentProgress => {
                studentProgress.allStudentProgressReportUnderCurrentStudent?.forEach(studentProgressReport => {
                  const isGoalType = studentProgressReport.goal.goalId === isMakingAdequateProgressPayload.goalId &&
                    isMakingAdequateProgressPayload.progressReportType === ReportTypes.Goal;
        
                  if (isGoalType) {
                    studentProgressReport.goal.goalProgressDetails?.forEach((goalProgressDetail, goalProgressRowPosition) => {
                      const shouldUpdate = goalProgressDetail.period === isMakingAdequateProgressPayload.period &&
                        isMakingAdequateProgressPayload.rowPosition === goalProgressRowPosition;
        
                      if (shouldUpdate) {
                        goalProgressDetail.makingAdequateProgress = isMakingAdequateProgressPayload.isMakingAdequateProgress;
                      }
                    });
                  } else {
                    studentProgressReport.objectives?.forEach((objective, objectiveRowPosition) => {
                      if (objectiveRowPosition === isMakingAdequateProgressPayload.objectiveProgressArrayIndex) {
                        objective.objectiveProgressDetails?.forEach((objectiveProgressDetail, objectiveProgressRowPosition) => {
                          const shouldUpdate = objectiveProgressDetail.period === isMakingAdequateProgressPayload.period &&
                            isMakingAdequateProgressPayload.rowPosition === objectiveProgressRowPosition &&
                            isMakingAdequateProgressPayload.objectiveId === objective.objectiveId;
        
                          if (shouldUpdate) {
                            objectiveProgressDetail.makingAdequateProgress = isMakingAdequateProgressPayload.isMakingAdequateProgress;
                          }
                        });
                      }
                    });
                  }
                });
              });
            } else {
              draft?.studentProgressReportFetched?.pageRecords?.forEach(studentProgress => {
                const isGoalType = (studentProgress.goal?.goalId === isMakingAdequateProgressPayload.goalId &&
                  isMakingAdequateProgressPayload.progressReportType === ReportTypes.Goal &&
                  studentProgress.serviceProviderId === draft.settingsPopup.serviceProviderSelected?.id);
        
                const isObjectiveType = (studentProgress.goal?.goalId === isMakingAdequateProgressPayload.goalId &&
                  isMakingAdequateProgressPayload.progressReportType === ReportTypes.Objective &&
                  studentProgress.serviceProviderId === draft.settingsPopup.serviceProviderSelected?.id);
        
                if (isGoalType) {
                  studentProgress.goal.goalProgressDetails?.forEach((goalProgressDetail, goalProgressRowPosition) => {
                    const shouldUpdate = goalProgressDetail.period === isMakingAdequateProgressPayload.period &&
                      isMakingAdequateProgressPayload.rowPosition === goalProgressRowPosition;
        
                    if (shouldUpdate) {
                      goalProgressDetail.makingAdequateProgress = isMakingAdequateProgressPayload.isMakingAdequateProgress;
                    }
                  });
                } else if (isObjectiveType) {
                  studentProgress.objectives?.forEach((objective, objectiveRowPosition) => {
                    if (objectiveRowPosition === isMakingAdequateProgressPayload.objectiveProgressArrayIndex) {
                      objective.objectiveProgressDetails?.forEach((objectiveProgressDetail, objectiveProgressRowPosition) => {
                        const shouldUpdate = objectiveProgressDetail.period === isMakingAdequateProgressPayload.period &&
                          isMakingAdequateProgressPayload.rowPosition === objectiveProgressRowPosition &&
                          isMakingAdequateProgressPayload.objectiveId === objective.objectiveId;
        
                        if (shouldUpdate) {
                          objectiveProgressDetail.makingAdequateProgress = isMakingAdequateProgressPayload.isMakingAdequateProgress;
                        }
                      });
                    }
                  });
                }
              });
            }
        
        }); 
         return updatedState
        }
        

        default:
            const exhaustiveCheck: any = action.type;
            throw new Error(`Unhandled progress reporting action: ${exhaustiveCheck}`);
    }
} 

 