import { Box, Stack, Typography } from "@mui/material"; 
import MSBSemiCirclePieChart from "../../amcharts/semi_circle_pie_chart";

function SemiCircleChartDemo() {
 
    return (
        <Stack gap="2rem">
            <Typography>Our standard component for creating pie charts.</Typography>

            <Stack>
                <Typography variant="h6">Required Prop</Typography>
 
                <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <li>
                        <code>data: {"{value: number, category: string, color: color hexaCode in string}[]"} </code>{" "}
                        <Typography display="inline" sx={{ml:2}}>Your semiccircle pie chart data </Typography>
                    </li>
                </Box> 
              
            </Stack>

            <Stack gap="1rem">
                <Typography variant="h6">Sales per day</Typography>
                <MSBSemiCirclePieChart data={[
                    {value: 50,category: "Sales", color: "#ff6384"},
                    {value: 10,category: "Marketing", color: "#36a2eb"}, 
                    {value: 60,category: "Advertising", color: "#cc65fe"}, 
                    {value: 65,category: "Content", color: "#ffce56"}]} />
            </Stack>
        </Stack>
    );
}

export default SemiCircleChartDemo;
