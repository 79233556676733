import { Box } from "@mui/material";
import { DataGrid, /* GridPaginationModel */ } from "@mui/x-data-grid";
import { useMemo, useRef } from "react";
import { MSBDataGridNoRowsOverlay } from "../../../../../../fortitude";
import { UnpostedSessionsReportData } from "../../hooks/api/use_api_mutate_poll_unposted_sessions_report";
import useSessionLogsReportsTableColumnDefinition from "../../hooks/use_unposted_sessions_reports_table_column_definition";
import { MutationStatus } from "@tanstack/react-query";
import { LoadingOverLay, CustomToolBar } from "./unposted_sessions_report_table_slots_components";

type Props = {
  rows: NonNullable<UnpostedSessionsReportData["pageRecords"]>;
  // paginationState: {
  //   paginationModel: GridPaginationModel;
  //   setPaginationModel: (paginationModel: GridPaginationModel) => void;
  // };
  totalRecords?: number;
  api: {
    reports: {
      status: MutationStatus;
    };
    csv: {
      status: MutationStatus;
      mutate: () => void;
    };
  };
};

const minDataGridHeight = "400px";
/**
 * Component for displaying the Unposted Sessions Reports Table.
 *
 * @param {Readonly<Props>} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Unposted Sessions Reports Table component.
 *
 * @remarks
 * This component uses the `DataGrid` component from MUI to display the table.
 * It supports server-side pagination and displays custom overlays for loading and no data states.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.rows - The data rows to be displayed in the table.
 * @param {Object} props.paginationState - The pagination state object.
 * @param {number} props.paginationState.totalRecords - The total number of records.
 * @param {Object} props.paginationState.paginationModel - The pagination model object.
 * @param {number} props.paginationState.paginationModel.pageSize - The number of rows per page.
 * @param {number} props.paginationState.paginationModel.page - The current page number.
 * @param {Function} props.paginationState.setPaginationModel - The function to set the pagination model.
 * @param {string} props.apiStatus - The current API status ('idle', 'pending', 'success').
 */
export function UnpostedSessionsReportsTable(props: Readonly<Props>): JSX.Element {
  const columnDef = useSessionLogsReportsTableColumnDefinition();

  const rowCountRef = useRef(props.totalRecords ?? 0);

  const rowCount = useMemo(() => {
    if (props.totalRecords !== undefined) {
      rowCountRef.current = props.totalRecords;
    }
    return rowCountRef.current;
  }, [props.totalRecords]);

  return (
    <Box
      aria-label="unposted-sessions-reports-table"
      sx={{
        display: "flex",
        mb: 4,
        flexDirection: "column",
        maxHeight: "600px",
        height: props.api.reports.status === "idle" ? minDataGridHeight : "600px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
      }}
    >
      <DataGrid
        sx={{ "--DataGrid-overlayHeight": minDataGridHeight }}
        rows={props.rows.map((row) => ({ id: crypto.randomUUID(), ...row }))}
        columns={columnDef}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: MSBDataGridNoRowsOverlay(
            props.api.reports.status === "success"
              ? "No Data found"
              : "To view data, please generate a report",
          ),
          loadingOverlay: LoadingOverLay({
            mainText: "Hang tight! We're gathering this info for you",
            loadingText: "generating report",
            iconOnly: props.rows.length > 0,
            isLoading: props.api.reports.status === "pending",
          }),
          toolbar: CustomToolBar({
            csvBtn: {
              loading: props.api.csv.status === "pending",
              onClick: props.api.csv.mutate,
              disabled:
                props.api.reports.status !== "success" ||
                props.api.csv.status === "pending" ||
                props.rows.length === 0,
            },
          }),
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        // NOTE: Uncomment the following lines to enable server-side pagination, at the moment the backend does not support it
        // pagination
        // paginationMode="server"
        // initialState={{
        //   pagination: { paginationModel: { pageSize: 10, page: 0 } },
        // }}
        // paginationModel={props.paginationState.paginationModel}
        // onPaginationModelChange={props.paginationState.setPaginationModel}
        // pageSizeOptions={[10, 20, 50, 100]}
        hideFooterPagination
        pageSizeOptions={[]}
        loading={props.api.reports.status === "pending"}
      />
    </Box>
  );
}
