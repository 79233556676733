import { useMemo, useState, useEffect, useCallback } from "react";
import Box from "../../../../../design/components-dev/BoxExtended";
import { AddRationaleModal } from "../../../modals/add_rationale";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import XNGHyperlink from "../../../../../design/low-level/hyperlink";
import AttendanceTabView from "../../../tab-views/attendance";
import { XNGICONS, XNGIconRenderer } from "../../../../../design";
import { getSizing } from "../../../../../design/sizing";
import { SessionResponse, StudentJournal } from "../../../../../session-sdk";
import { EditDraftFunctionType } from "../../../tools/types";
import useBreakpointHelper from "../../../../../design/hooks/use_breakpoint_helper";
import SessionTimesTabView from "../../../tab-views/session_times";
import { CareProvisionAccordion } from "./care_provision_accordion";
import { NotatorTools, useNotatorTools } from "../../../tools";
import { FutureTabs, NotatorTab } from "../../../types/types";
import ActivitiesTabView from "../../../tab-views/activities";
import AccommodationsTabView from "../../../tab-views/accommodations";
import ModificationsTabView from "../../../tab-views/modifications";
import GoalsObjectivesTabView from "../../../tab-views/goals_objectives/goals_objectives";
import ObservationsTabView from "../../../tab-views/observations/observations";
import XNGToggle from "../../../../../design/low-level/button_toggle";
import { useFetchedStudentPrescribedCareProvisions } from "../../../hooks/use_fetched_student_prescribed_care_provisions";
import { useStudentDefaultCareProvisions } from "../../../hooks/use_default_care_provisions";
import MSBSemiCirclePieChart from "../../../../../fortitude/amcharts/semi_circle_pie_chart";
import useCheckIfPersonalCareSession from "../../../hooks/use_check_is_personal_care_session"; 
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import useSessionTime from "../../../hooks/useSessionTime";
import useSemiCircleChartData from "../../../hooks/use_semi_circle_chart_clr";
import { NotatorSectionName } from "@xng/profile";

export interface AllStudentViewportProps {
  studentJournal: StudentJournal;
  studentIndex: number;
  expanded: boolean;
  handleStudentAccordionExpansion: (index: number) => void;
  draftSession: SessionResponse;
  editDraftSession: EditDraftFunctionType;
  applyFuture: Array<FutureTabs[]>;
  setApplyFuture: React.Dispatch<React.SetStateAction<Array<FutureTabs[]>>>;
}

export function StudentAccordion(props: Readonly<AllStudentViewportProps>) {
  const {
    studentJournal,
    studentIndex,
    expanded,
    handleStudentAccordionExpansion,
    draftSession,
    editDraftSession,
  } = props;

  const notatorTools = useNotatorTools();
  const views = useAccordionViews(props, notatorTools, studentIndex);
  const isMobile = useBreakpointHelper().isMobile;
  const [showRationaleModal, setShowRationaleModal] = useState<boolean>(false);
  const [expandedProvisions, setExpandedProvisions] = useState<String[]>([]);
  // this will be updated in the future
  const [supportServiceMinutes, setSupportServiceMinutes] = useState<number|null>(null)
  const isPersonalCareSession = useCheckIfPersonalCareSession() 
  const sessionMinutes = useSessionTime()
  const memoisedTotalBillableMinutes = useMemo(() => draftSession?.studentJournalList?.[studentIndex]?.careProvisionLedger?.activities?.reduce((total, activity) => total + (Number(activity.totalMinutesSpent ?? 0)), 0), [draftSession,studentIndex]);
  const chartData = useSemiCircleChartData(memoisedTotalBillableMinutes,sessionMinutes,supportServiceMinutes as number) 

  useEffect(() => {
    //@ts-ignore
    setSupportServiceMinutes(draftSession.studentJournalList?.[studentIndex]?.studentAttendanceRecord?.serviceSupportMinutes)
    //@ts-ignore
  }, [draftSession.studentJournalList?.[studentIndex]?.studentAttendanceRecord?.serviceSupportMinutes]);

  let { notatorSections } = notatorTools;
  // Filters out Session Times and Attendance Tabs since they are rendered separately in this view
  notatorSections = notatorSections = notatorSections.filter((section) => section.sectionName! > 1);

  const handleProvisionAccordionExpansion = (index: string) => {
    if (expandedProvisions.includes(index)) {
      setExpandedProvisions(expandedProvisions.filter((i) => i !== index));
    } else {
      setExpandedProvisions([...expandedProvisions, index]);
    }
  };

  const collapseAllProvisions = () => {
    setExpandedProvisions([]);
  };

  const expandAllProvisions = () => {
    const newState: String[] = [];
    notatorSections?.forEach((notatorSection, index) => {
      newState.push(NotatorTab[notatorSection.sectionName!]);
    });
    setExpandedProvisions(newState);
  };

  const careProvisionsComponentsMap: {
    [key: string]: React.ReactNode;
  } = {
    Activities: views.activities,
    Accommodations: views.accommodations,
    Modifications: views.modifications,
    "Goals/Objectives": views.goalsObjectives,
    Observations: views.observations,
  };

  const rationale =
    draftSession.studentJournalList?.[props.studentIndex]?.studentAttendanceRecord?.rationale ||
    studentJournal.studentAttendanceRecord?.rationale;

  function chartCallback(chart: am5percent.PieChart, root: am5.Root) {
          const centerContainer = chart.seriesContainer.children.push(
            am5.Container.new(root, {
              centerX: am5.percent(50), // Center horizontally
              centerY: am5.percent(90), // Adjust vertically for semi-circle
              layout: root.verticalLayout,
            })
          );
  
         centerContainer.children.push(
            am5.Label.new(root, {
              text: `${sessionMinutes}`, // Dynamic number
              fontSize: 30, // Larger font size for the number
              textAlign: "center",
              x: am5.p50, // Center horizontally
              fill: am5.color(0x000000),
            })
          );
          
          // Add a label for the smaller text
         centerContainer.children.push(
            am5.Label.new(root, {
              text: "Session Minutes",
              fontSize: 20, // Default font size for the text
              textAlign: "center",
              x: am5.p50, // Center horizontally
              fill: am5.color(0x000000),
            })
          );
    }

 
  const getSectionCount = useCallback((notatorSection:NotatorSectionName)=>{
       const isObservationsSection = notatorSection === NotatorTab.Observations
       const isGoalsObjectivesSection = notatorSection === NotatorTab["Goals/Objectives"]
       // check if section name is observations
       if(isObservationsSection){
         return draftSession?.studentJournalList![studentIndex].observationSection!.observations?.length
       }else if(isGoalsObjectivesSection){
         return draftSession?.studentJournalList![studentIndex].careProvisionLedger?.goalProgresssions?.length
       }
       //  @ts-ignore
       return draftSession?.studentJournalList?.[studentIndex]?.careProvisionLedger?.[
        NotatorTab[notatorSection].toLocaleLowerCase()
      ]?.length 
  },[draftSession?.studentJournalList![studentIndex].observationSection!.observations,draftSession?.studentJournalList?.[studentIndex]?.careProvisionLedger])
  
  return (
    <>
      <AddRationaleModal
        selectedStudentIndex={studentIndex}
        setModalOpen={setShowRationaleModal}
        modalOpen={showRationaleModal}
        rationale={rationale}
        studentName={studentJournal.student?.firstName + " " + studentJournal.student?.lastName}
        disableInputs={notatorTools.isSessionFinalized}
      />
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={() => handleStudentAccordionExpansion(studentIndex)}
        key={studentJournal.student?.id}
        sx={{ mb: 2, border: "unset", boxShadow: "none" }}
        id={`${studentJournal.student?.id}`}
      >
        <AccordionSummary
          expandIcon={
            <XNGIconRenderer left color="#4B4B4B" i={<XNGICONS.DownChevron />} size="md" />
          }
          sx={{
            bgcolor: "#D5E2E6",
          }}
        >
          <Typography sx={{ color: "#206A7E" }}>
            {studentJournal?.student?.firstName}&nbsp;{studentJournal?.student?.lastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 0, pr: 0, mb: 4 }}>
          <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
            <AttendanceTabView
              editSession={editDraftSession}
              editedSession={draftSession}
              selectedStudentIndex={studentIndex}
              isAllStudentView={true}
            />
          </Box>
          {draftSession.studentJournalList?.[studentIndex]?.studentAttendanceRecord?.present && (
            <>
              <Box
                sx={{
                  bgcolor: "#D5E2E6",
                  width: "100%",
                  padding: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>Today's Session Times</Typography>
                  <XNGHyperlink
                    onClick={() => setShowRationaleModal(!showRationaleModal)}
                    width="max-content"
                  >
                    {rationale ? "View Rationale" : "Add Rationale"}
                  </XNGHyperlink>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <XNGHyperlink onClick={expandAllProvisions} width="max-content">
                    Expand All
                  </XNGHyperlink>
                  <Typography>/</Typography>
                  <XNGHyperlink onClick={collapseAllProvisions} width="max-content">
                    Collapse All
                  </XNGHyperlink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: getSizing(2),
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <SessionTimesTabView
                    selectedStudentIndex={studentIndex}
                    isAllStudentView={true}
                  />
                  {notatorTools?.adaptedDraftSession.isRecurring() && (
                    <XNGToggle
                      disabled={notatorTools.isSessionFinalized}
                      value={
                        !Object.keys(
                          notatorTools.exemptedStudentsFromEditsForFutureSessions,
                        ).includes(studentIndex.toString())
                      }
                      onToggle={() =>
                        notatorTools.handleToggleApplyEditsToFutureSessions(
                          notatorTools.session?.studentJournalList!,
                          "allStudents",
                          studentIndex,
                        )
                      }
                      label="Apply all edits to future sessions"
                    />
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  {notatorSections?.map((notatorSection, index) => {
                    return (
                      <CareProvisionAccordion
                        index={index}
                        key={index}
                        expanded={expandedProvisions.includes(
                          NotatorTab[notatorSection.sectionName!],
                        )}
                        handleProvisionAccordionExpansion={handleProvisionAccordionExpansion}
                        header={NotatorTab[notatorSection.sectionName!]}
                        editDraftSession={editDraftSession}
                        draftSession={draftSession}
                        selectedProvisions={
                          // Not quite sure how to get around this TS error
                          // @ts-ignore
                          getSectionCount(notatorSection.sectionName)
                        }
                      >
                        {careProvisionsComponentsMap[NotatorTab[notatorSection.sectionName!]]}
                      </CareProvisionAccordion>
                    );
                  })}

              {isPersonalCareSession && 
                        <Box sx={{ width: "400px",overflow: "visible",display: "flex",flexDirection: "column", alignItems: "center",justifyContent:"flex-end" }}>
                          {/* height and width should be in 1:3 / 1:4 */}
                          <MSBSemiCirclePieChart 
                          height={"180px"}
                          width= {"700px"}
                          callback={chartCallback}
                          data={chartData} />

                          </Box>}
                </Box>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function useAccordionViews(
  props: AllStudentViewportProps,
  notatorTools: NotatorTools,
  studentIndex: number,
): {
  attendance: JSX.Element;
  sessionTimes: JSX.Element;
  activities: JSX.Element;
  accommodations: JSX.Element;
  modifications: JSX.Element;
  goalsObjectives: JSX.Element;
  observations: JSX.Element;
} {
  const fetchedStudentPrescribedCareProvisions = useFetchedStudentPrescribedCareProvisions({
    studentIndex,
  });

  const { fetchedDefaultCareProvisions } = useNotatorTools();

  const { defaultActivities, defaultAccommodations, defaultModifications } =
    useStudentDefaultCareProvisions({
      fetchedStudentPrescribedCareProvisions,
      fetchedDefaultCareProvisions,
    });

  const attendance = (
    <AttendanceTabView
      selectedStudentIndex={notatorTools.selectedStudentIndex}
      editedSession={notatorTools.draftSession}
      editSession={notatorTools.editDraft}
    />
  );
  const sessionTimes = <SessionTimesTabView selectedStudentIndex={props.studentIndex} />;
  const activities = (
    <ActivitiesTabView
      selectedStudentIndex={props.studentIndex}
      defaultIDs={defaultActivities}
      isAllStudentView={true}
    />
  );
  const accommodations = (
    <AccommodationsTabView
      selectedStudentIndex={props.studentIndex}
      defaultIDs={defaultAccommodations}
      isAllStudentView={true}
    />
  );
  const modifications = (
    <ModificationsTabView
      selectedStudentIndex={props.studentIndex}
      defaultIDs={defaultModifications}
      isAllStudentView={true}
    />
  );
  const goalsObjectives = <GoalsObjectivesTabView selectedStudentIndex={props.studentIndex} />;
  const observations = (
    <ObservationsTabView
      applyFuture={props.applyFuture}
      setApplyFuture={props.setApplyFuture}
      selectedStudentIndex={props.studentIndex}
      sessionFinalized={notatorTools.isSessionFinalized}
    />
  );

  return {
    attendance,
    sessionTimes,
    activities,
    accommodations,
    modifications,
    goalsObjectives,
    observations,
  };
}
