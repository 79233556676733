import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  DistrictOfLiability,
  DistrictProfile,
  Goal,
  SchoolCampusAssignment,
  StudentResponse,
} from "../../../profile-sdk";
import { EditStudentFunctionType } from "../types";
import { produce } from "immer";
import { placeholderForFutureLogErrorText } from "../../../temp/errorText";
import { API_DISTRICTS, API_STUDENTS } from "../../../api/api";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { selectClientID } from "../../../context/slices/loggedInClientSlice";
import { selectAuthorizedDistricts, selectUser } from "../../../context/slices/userProfileSlice";
import sessionStorageKeys from "../../../browser/sessionStorageKeys";
import localStorageKeys from "../../../constants/localStorageKeys";
import { useBypassUnsavedChanges } from "../../notator/hooks/use_on_before_bypass";
import GoalFromType from "../profile_tabs/types/goal_objective_tab_form_schema_type";
import dayjs from "dayjs";

const CURRENT_STUDENT_KEY = localStorageKeys.CURRENT_STUDENT_KEY;

/**
 * Note if trying to reference from an external module: Use `useStudentProfileContext` instead
 */
const EditedStudentContext = createContext<StudentProfileContextType | null>(null);
interface StudentProfileContextType {
  editedStudent: StudentResponse | null;
  editStudent: EditStudentFunctionType;
  editStudentImmer: EditStudentFunctionType;
  setEditedStudent: React.Dispatch<React.SetStateAction<StudentResponse | null>>;
  student: StudentResponse | null;
  sortedDistricts: string[];
  selectedStudentDistrict?: DistrictOfLiability;
  setSelectedStudentDistrictHandler: (district: DistrictOfLiability) => void;
  selectedStudentCampus?: SchoolCampusAssignment;
  setSelectedStudentCampusHandler: (campus: SchoolCampusAssignment) => void;
  handleSave: (v?: StudentResponse) => Promise<void>;
  isSaving: boolean;
  state: string;
  allDistricts: DistrictProfile[] | null;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirtyOverride: boolean;
  setIsDirtyOverride: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingGoal: boolean;
  setIsCreatingGoal: React.Dispatch<React.SetStateAction<boolean>>;
  bypass: boolean;
  backButtonClicked: boolean;
  setBackButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  inEditGoalView: boolean;
  setInEditGoalView: React.Dispatch<React.SetStateAction<boolean>>;
  savingGoal: boolean;
  setSavingGoal: React.Dispatch<React.SetStateAction<boolean>>;
  goalData: GoalFromType;
  setGoalData: React.Dispatch<React.SetStateAction<GoalFromType>>;
  selectedGoal: Goal;
  setSelectedGoal: React.Dispatch<React.SetStateAction<Goal>>;
  onSubmitGoal(data: GoalFromType): Promise<void>;
}

export const StudentProfileContextProvider = (props: { children: React.ReactNode }) => {
  const [editedStudent, setEditedStudent] = useState<StudentResponse | null>(null);

  const state = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);
  const districts = useXNGSelector(selectAuthorizedDistricts);
  const allDistricts: DistrictProfile[] = JSON.parse(
    sessionStorage.getItem(sessionStorageKeys.ALL_DISTRICTS)!,
  );
  const user = useXNGSelector(selectUser);

  const [student, setStudent] = useState<StudentResponse | null>(null);

  const [selectedStudentDistrict, setSelectedStudentDistrict] = useState<
    DistrictOfLiability | undefined
  >();
  const [selectedStudentCampus, setSelectedStudentCampus] = useState<
    SchoolCampusAssignment | undefined
  >();
  const [sortedDistricts, setSortedDistricts] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isDirtyOverride, setIsDirtyOverride] = useState<boolean>(false);
  const [isCreatingGoal, setIsCreatingGoal] = useState<boolean>(false);
  const [backButtonClicked, setBackButtonClicked] = useState<boolean>(false);
  const [inEditGoalView, setInEditGoalView] = useState<boolean>(false);
  const [savingGoal, setSavingGoal] = useState<boolean>(false);
  const [goalData, setGoalData] = useState<GoalFromType>({} as GoalFromType);
  const [selectedGoal, setSelectedGoal] = useState<Goal>({} as Goal);

  const { bypass, bypassUnsavedChanges } = useBypassUnsavedChanges({
    onBeforeBypass: () => {
      setIsDirty(false);
      setIsCreatingGoal(false);
    },
  });

  /**
   * NOTE: This function has been deprecated in favor of Immer's `produce()` function.
   *
   */
  function editStudent(prop: string, value: any): void {
    const pathArray = prop.split(".");
    const copy: StudentResponse = { ...editedStudent } as StudentResponse;
    let currentObject: any = copy;
    for (let i = 0; i < pathArray.length - 1; i++) {
      currentObject = currentObject[pathArray[i]];
    }
    currentObject[pathArray[pathArray.length - 1]] = value;
    setEditedStudent(copy);
  }

  /**
   * This function exists for debugging. By using this in place of existing dot notation patterns,
   * underlying issues can more easily be revealed.
   */
  function editStudentImmer(prop: string, value: any): void {
    // Use Immer's produce to create a new state
    const newState = produce(editedStudent, (draft) => {
      let current = draft as any;
      const pathArray = prop.split(".");

      // Traverse the path and update the value
      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;
    });

    // Update the state with the new immutable state
    setEditedStudent(newState);
  }

  async function handleSave(v?: StudentResponse) {
    setIsSaving(true);
    // Save student, refresh screen.
    if (!editedStudent) throw new Error(placeholderForFutureLogErrorText);
    const studentID = document.URL.split("/").at(-1);

    const newEditedStudent = produce(editedStudent, (draft) => {
      // Check if the selected district is the first item in the array(meaning, it was recently added), if so, do not add it again
      if (
        selectedStudentDistrict?.liableDistrict?.id !==
          student?.districtOfLiabilityRecords?.[0]?.liableDistrict?.id &&
        selectedStudentDistrict
      ) {
        // perform enddate backfill operation
        draft.districtOfLiabilityRecords =
          draft.districtOfLiabilityRecords?.map((district) =>
            district.endDate ? district : { ...district, endDate: dayjs().toDate() },
          ) ?? [];

        // add the selected district to the top of the list
        draft.districtOfLiabilityRecords = [
          selectedStudentDistrict,
          ...(draft.districtOfLiabilityRecords ?? []),
        ];
      }
      // Check if the selected campus is the first item in the array(meaning, it was recently added), if so, do not add it again
      if (selectedStudentCampus?.id !== student?.schoolCampuses?.[0]?.id && selectedStudentCampus) {
        //perform enddate backfill operation
        draft.schoolCampuses =
          draft.schoolCampuses?.map((campus) =>
            campus.attendanceEndDate ? campus : { ...campus, attendanceEndDate: dayjs().toDate() },
          ) ?? [];

        // add the selected campus to the top of the list
        draft.schoolCampuses = [selectedStudentCampus, ...(draft.schoolCampuses ?? [])];
      }
      if(draft.spedDossier?.prescribedCareProvisionsLedger?.activities) {
        draft.spedDossier.prescribedCareProvisionsLedger.activities = draft.spedDossier?.prescribedCareProvisionsLedger?.activities?.filter((act) => act.name !== "")
      }
      if(draft.spedDossier?.prescribedCareProvisionsLedger?.accommodations) {
        draft.spedDossier.prescribedCareProvisionsLedger.accommodations = draft.spedDossier?.prescribedCareProvisionsLedger?.accommodations?.filter((acc) => acc.name !== "")
      }
      if(draft.spedDossier?.prescribedCareProvisionsLedger?.modifications) {
        draft.spedDossier.prescribedCareProvisionsLedger.modifications = draft.spedDossier?.prescribedCareProvisionsLedger?.modifications?.filter((mod) => mod.name !== "")
      }

      if(draft.spedDossier?.prescribedServiceAreas) {
        draft.spedDossier.prescribedServiceAreas = draft.spedDossier?.prescribedServiceAreas.filter((area) => area.serviceArea !== null)
      }
    });

    await API_STUDENTS.v1StudentsIdPatch(studentID!, state, v ?? newEditedStudent);
    setIsDirtyOverride(false);
    fetchAndSetStudent();
    setIsSaving(false);
    if (!inEditGoalView) {
      bypassUnsavedChanges();
    }
  }

  async function fetchAndSetStudent() {
    const studentID = document.URL.split("/").at(-1);
    const s = await API_STUDENTS.v1StudentsIdGet(studentID!, loggedInClientId!, state);

    setSelectedStudentDistrict(s.districtOfLiabilityRecords?.[0]); //initialize selected district to the first district in record
    setSelectedStudentCampus(s.schoolCampuses?.[0]); //initialize selected campus to the first campus in record
    const districtsToSort = state === "NH" ? allDistricts! : districts;
    const sorted_districts: string[] = districtsToSort
      .map((district) => {
        return district.name!;
      })
      .sort((a, b) => a.localeCompare(b));

    setSortedDistricts(sorted_districts);

    setStudent(s);
    setEditedStudent(s);
    localStorage.setItem(CURRENT_STUDENT_KEY, JSON.stringify(s));
  }

  function setSelectedStudentDistrictHandler(district: DistrictOfLiability) {
    setSelectedStudentDistrict(district);
  }

  function setSelectedStudentCampusHandler(campus: SchoolCampusAssignment) {
    setSelectedStudentCampus(campus);
  }

  /**
   * Saves the goal and updates the student's information.
   *
   * @param updated_student - The updated student object.
   * @param current_student - The current student object.
   * @returns A boolean indicating whether the goal was successfully saved or not.
   */
  const handleSaveGoal = async (
    updated_student: StudentResponse,
    current_student: StudentResponse,
  ): Promise<boolean> => {
    setEditedStudent(updated_student);
    try {
      await handleSave(updated_student);
      return true;
    } catch (err) {
      setEditedStudent(current_student);
      return false;
    }
  };

  /**
   * Handles the submission of a goal.
   *
   * @param data - The goal data to be submitted.
   * @returns Promise<void>
   */
  async function onSubmitGoal(data: GoalFromType) {
    setSavingGoal(true); // This sets the state variable saving_goal to true, indicating that the goal progress is being saved.

    const new_goal = produce(selectedGoal, (draft) => {
      if(data.goal) {
        draft.number = data.goal.number;
        draft.description = data.goal.description;
        draft.status = data.goal.status;
        /**
         * 12/3/24
         * The Goal type expects Date types for start and end dates, but they are returned from the backend as timestamps in some cases
         * Sending timestamps or dates seems to be handled either way by the backend, and the yup validation was mutating timestamps
         * into dates (I don't know why an error wasn't thrown in the first place). the ts-ignore lines were added below because of this issue
         * Refer to this PR: https://dev.azure.com/MSBNextGen/XNG/_git/XNG/pullrequest/2151
         */
        //@ts-ignore
        draft.startDate = typeof data.goal.start_date === "object" ? data.goal.start_date.toISOString() : data.goal.start_date;
        //@ts-ignore
        draft.endDate = typeof data.goal.start_date === "object" ? data.goal.end_date.toISOString() : data.goal.end_date;
        draft.goalAreaOfFocus = data.goal.goal_area_of_focus;
        draft.objectives = data.objectives?.filter((obj) => !!obj.number && !!obj.description);
      }
    });
    const new_edited_student = produce(editedStudent!, (draft) => {
      if (
        draft &&
        draft.spedDossier &&
        draft.spedDossier?.prescribedCareProvisionsLedger &&
        draft.spedDossier?.prescribedCareProvisionsLedger?.goals
      ) {
        const goal_index = draft.spedDossier?.prescribedCareProvisionsLedger?.goals?.findIndex(
          (g) => g.internalId === new_goal.internalId,
        );
        if (goal_index !== -1) {
          draft.spedDossier.prescribedCareProvisionsLedger.goals[goal_index] = new_goal;
        } else if (data.goal && JSON.stringify(selectedGoal) === "{}") {
          draft.spedDossier?.prescribedCareProvisionsLedger?.goals.push(new_goal);
        }
      }
    });
    /* 
    This calls the onGoalSaved function from the props object, passing the new_edited_student and props.edited_student objects as parameters. 
    It awaits the result of the function call and assigns it to the saved_data variable.
    */
    const saved_data = await handleSaveGoal(new_edited_student, editedStudent!);
    setSavingGoal(false);
    if (saved_data) {
      localStorage.setItem(CURRENT_STUDENT_KEY, JSON.stringify(new_edited_student));
      setIsDirty(false);
      setIsCreatingGoal(false);
      setInEditGoalView(false);
      setGoalData({} as GoalFromType);
    } else {
      // handle error
    }
  }

  useEffect(() => {
    fetchAndSetStudent();
  }, []);

  const value: StudentProfileContextType = useMemo(() => ({
    editedStudent,
    editStudent,
    setEditedStudent,
    editStudentImmer,
    selectedStudentDistrict,
    setSelectedStudentDistrictHandler,
    selectedStudentCampus,
    setSelectedStudentCampusHandler,
    student,
    sortedDistricts,
    handleSave,
    isSaving,
    state,
    allDistricts,
    isDirty,
    setIsDirty,
    isCreatingGoal,
    setIsCreatingGoal,
    bypass,
    backButtonClicked,
    setBackButtonClicked,
    inEditGoalView,
    setInEditGoalView,
    savingGoal,
    setSavingGoal,
    goalData,
    setGoalData,
    selectedGoal,
    setSelectedGoal,
    onSubmitGoal,
    isDirtyOverride,
    setIsDirtyOverride,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    editedStudent,
    editStudent,
    setEditedStudent,
    editStudentImmer,
    selectedStudentDistrict,
    setSelectedStudentDistrictHandler,
    selectedStudentCampus,
    setSelectedStudentCampusHandler,
    student,
    sortedDistricts,
    isSaving,
    state,
    allDistricts,
    isDirty,
    setIsDirty,
    isCreatingGoal,
    setIsCreatingGoal,
    bypass,
    backButtonClicked,
    setBackButtonClicked,
    inEditGoalView,
    setInEditGoalView,
    savingGoal,
    setSavingGoal,
    goalData,
    setGoalData,
    selectedGoal,
    setSelectedGoal,
    onSubmitGoal,
    isDirtyOverride,
    setIsDirtyOverride,
  ]);

  return (
    <EditedStudentContext.Provider value={value}>{props.children}</EditedStudentContext.Provider>
  );
};

export function useStudentProfileContext(): StudentProfileContextType {
  const context = useContext(EditedStudentContext);
  if (!context) {
    throw new Error("useStudentProfileContext must be used within a EditedStudentProvider");
  }
  return context;
}
