import React, { useMemo } from "react";
import ReportsLayout from "../../reports_layout";
import UnpostedSessionsReportsForm from "./components/unposted_sessions_reports_form";
import { UnpostedSessionReportsFormSchema } from "./constants/unposted_sessions_reports_form_schema";
import { useXNGSelector } from "../../../../context/store";
import { selectClient } from "../../../../context/slices/loggedInClientSlice";
import QueryStatusModal from "../../../../design/modal_templates/query_status_modal";
import { UnpostedSessionsReportsTable } from "./components/table/unposted_sessions_report_table";
import { useApiMutateQueueUnpostedSessionsReport } from "./hooks/api/use_api_mutate_queue_unposted_sessions_report";
import { useApiMutatePollUnpostedSessionsReport } from "./hooks/api/use_api_mutate_poll_unposted_sessions_report";
import { useApiMutatePollUnpostedSessionsCsvDownload } from "./hooks/api/use_api_mutate_poll_unposted_sessions_download_csv";
import downloadFile from "../../../../utils/downloadFile";

export default function UnpostedSessionsReportView() {
  const clientId = useXNGSelector(selectClient)?.id;
  const [showGeneratingReportsModal, setShowGeneratingReportsModal] = React.useState(false);

  const reportQueueClient = useApiMutateQueueUnpostedSessionsReport();
  const reportPollClient = useApiMutatePollUnpostedSessionsReport({
    options: {
      onSuccess() {
        setShowGeneratingReportsModal(false);
      },
      onError() {
        setShowGeneratingReportsModal(true);
      },
    },
  });

  const [showDownloadCsvModal, setShowDownloadCsvModal] = React.useState(false);
  const reportDownloadCSVClient = useApiMutatePollUnpostedSessionsCsvDownload();

  /**
   * The function checks the status of `reportPollClient` and `reportQueueClient`
   * to return a consolidated status.
   * @returns {MutationStatus} The current status of report generation.
   */
  const reportGenerationStatus = useMemo(() => {
    const statuses = [reportQueueClient.status, reportPollClient.status];
    if (statuses.includes("error")) {
      return "error";
    } else if (statuses.includes("pending")) {
      return "pending";
    } else if (statuses.every((status) => status === "success")) {
      return "success";
    }
    return "idle";
  }, [reportPollClient.status, reportQueueClient.status]);

  function generateReport(formData: UnpostedSessionReportsFormSchema) {
    reportQueueClient.mutate(
      {
        queueClientSessionCountReportPostRequest: {
          filterParameters: {
            campusIds: new Set(formData.schoolCampuses?.map((campus) => campus.id!)),
            clientId: clientId,
            includeServiceProvidersWithoutCampus: formData.includeProvidersWithoutCampuses,
            startDate: formData.startDate!,
            endDate: formData.endDate!,
          },
        },
      },
      {
        onSuccess(data) {
          reportPollClient.mutate({
            getPaginatedReportFromRunPostRequest: {
              reportRunDate: data.reportRunDate,
              reportRunId: data.reportRunId,
              // pageParameters: {
              //   pageNumber: 1,
              //   pageSize: 10,
              // },
            },
          });
        },
        onError() {
          setShowGeneratingReportsModal(true);
        },
      },
    );
  }

  function downloadCsv() {
    reportDownloadCSVClient.mutate(
      {
        getReportCsvFromRunPostRequest: {
          reportRunDate: reportQueueClient.data?.reportRunDate,
          reportRunId: reportQueueClient.data?.reportRunId,
        },
      },
      {
        onSuccess(data) {
          downloadFile(data, "UnpostedSessionsReport.csv");
          setShowDownloadCsvModal(true);
        },
        onError() {
          setShowDownloadCsvModal(true);
        },
      },
    );
  }

  return (
    <ReportsLayout title="Unposted Sessions Report">
      <UnpostedSessionsReportsForm
        generateReport={generateReport}
        isLoading={reportGenerationStatus === "pending"}
      />
      <UnpostedSessionsReportsTable
        rows={reportPollClient.data?.pageRecords ?? []}
        api={{
          reports: {
            status: reportGenerationStatus,
          },
          csv: {
            status: reportDownloadCSVClient.status,
            mutate: downloadCsv,
          },
        }}
        // NOTE: Pagination is not implemented on the backend yet
        // paginationState={{
        //   paginationModel: {
        //     pageSize: reportPollClient.data?.pageSize ?? 10,
        //     page: reportPollClient.data?.pageNumber ? reportPollClient.data.pageNumber - 1 : 0,
        //   },
        //   setPaginationModel(paginationModel) {
        //     const updatedReportPayload = produce(reportPollClient.variables!, (draft) => {
        //       draft.getPaginatedReportFromRunPostRequest!.pageParameters = {
        //         pageNumber: paginationModel.page + 1,
        //         pageSize: paginationModel.pageSize,
        //       };
        //     });
        //     reportPollClient.mutate(updatedReportPayload);
        //   },
        // }}
        totalRecords={reportPollClient.data?.totalRecords ?? 0}
      />
      <QueryStatusModal
        isOpen={showGeneratingReportsModal}
        status={reportGenerationStatus}
        onSettledClose={() => {
          setShowGeneratingReportsModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "The report has been successfully generated.",
          errorTitle: "Error!",
          errorBody: "An error occurred while generating the report.",
          pendingTitle: "Please wait, we are generating the report for you...",
        }}
      />
      <QueryStatusModal
        isOpen={showDownloadCsvModal}
        status={reportDownloadCSVClient.status}
        onSettledClose={() => {
          setShowDownloadCsvModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody:
            "Successfully downloading CSV File, Please open your download menu/history to view the 'UnpostedSessionsReport.csv' File download Progress",
          errorTitle: "Error!",
          errorBody: "An error occurred while trying to export your report to CSV format.",
          pendingTitle: "Please wait, we are currently generating the CSV file for you...",
        }}
      />
    </ReportsLayout>
  );
}
