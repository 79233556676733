import { StudentProgressReportRecordUI, StudentProgressReportRecordUIPaginatedReportResponse } from "@xng/reporting";
import { ExtendedObjectiveWithAggregatedProgressField, ExtendedStudentProgressDetailsWithAggregatedProgress, ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute, ExtendedStudentProgressReportRecordUIPaginatedReportResponse, ExtendedStudentProgressReportRecordUIWithIsPosted, GroupedStudentReport, ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus, StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI } from "../types/extended_progress_repoting_types";
import {  GoalOrObjectiveStatus, ReportStatus } from "../context/reducer/progress_reporting_reducer"; 

interface GoalObjectiveProgressDetailsLookupMap{isFinalized:boolean,isPosted:boolean,generalComment:string,progressReportedBy:string,makeAdequateProgress:boolean}

export class ReportTransformationPipeline {
 
    public static getInstance(): ReportTransformationPipeline {
        return new ReportTransformationPipeline();
    }


    public groupProgressReportByStudentsFindByServiceProviderId(
        reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],
        selectedServiceProviderId: string
      ): GroupedStudentReport[] { 
        reports = reports.filter((report) => report.serviceProviderId === selectedServiceProviderId);
        let reportWithMissingAttributes:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[] = this.addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(reports);
        return reportWithMissingAttributes.reduce((acc: GroupedStudentReport[], curr: StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI) => {
          const studentName = curr.studentName;
      
          if (!studentName) return acc; // Skip if studentName is null or undefined
      
          const existing = acc.find(
            (report) => report.studentName === studentName
          );
      
          if (existing) {
            // If student already exists, add the current report to the existing group
            existing.allStudentProgressReportUnderCurrentStudent = [
              ...existing.allStudentProgressReportUnderCurrentStudent,
              curr,
            ];
          } else {
            // If student does not exist, create a new group with the current report
            acc.push({
              ...curr,
              allStudentProgressReportUnderCurrentStudent: [curr], // Initialize with the current report
            });
          }
      
          return acc;
        }, []);
      }
    private _groupProgressReportByStudentsForAggregatedReport(arg:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]): GroupedStudentReport[]{
      return arg.reduce((acc: GroupedStudentReport[], curr: StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI) => {
        const studentName = curr.studentName;
    
        if (!studentName) return acc; // Skip if studentName is null or undefined
    
        const existing = acc.find(
          (report) => report.studentName === studentName
        );
    
        if (existing) {
          // If student already exists, add the current report to the existing group
          existing.allStudentProgressReportUnderCurrentStudent = [
            ...existing.allStudentProgressReportUnderCurrentStudent,
            curr,
          ];
        } else {
          // If student does not exist, create a new group with the current report
          acc.push({
            ...curr,
            allStudentProgressReportUnderCurrentStudent: [curr], // Initialize with the current report
          });
        }
    
        return acc;
      }, []);
    }
    
    public getAggregatedProgressReportGrouped(reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],masteryReportCopy:StudentProgressReportRecordUI[]): GroupedStudentReport[]{
          let result = new Set<StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI>()
          reports.forEach((report)=>{
              let aggregatedProgress = this.getAggregatedProgressReportForEachStudent(reports,report?.goal?.goalId as string,report.studentName as string,masteryReportCopy)
              aggregatedProgress.forEach((progressReport) => {
                result.add(progressReport);
            });
          })
          let data = Array.from(result)

          const uniqueData = data.reduce((accumulator:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[], current) => {
            const found = accumulator.find(item => item?.goal.goalId === current?.goal.goalId);
            if (!found) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);

          return this._groupProgressReportByStudentsForAggregatedReport(uniqueData) 
    }

    public getAggregatedProgressReportForGoalAndObjectivesInEachStudent(reports:ExtendedStudentProgressReportRecordUIWithIsPosted[],progressReportMasteryCopy:StudentProgressReportRecordUI[]):GroupedStudentReport[]{
      const result:GroupedStudentReport[] = []
      return result    
    }
    
    public _generateId = (goalOrObjectiveId:number|string,period:number,periodStartDate:string,periodEndDate:string) => `${goalOrObjectiveId}-${period}-${periodStartDate}-${periodEndDate}`
    public getAggregatedProgressReportForEachStudent(reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],goalId:string,studentName:string,masteryReportCopy:StudentProgressReportRecordUI[]):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]{
        // a student can have duplicate goal and related objectives on different reports
        let reportsFilteredByGoalId = reports.filter((report)=> report?.goal?.goalId === goalId && report.studentName === studentName)
        const goalProgressDetailsLookup = new Map<string,Array<GoalObjectiveProgressDetailsLookupMap>>()
        const objectiveProgressDetailsLookup = new Map<string,Array<GoalObjectiveProgressDetailsLookupMap>>() 
        // store if goal is finalized for each goal as an array of boolean values and check if all goals are finalized
        const setGoalFinalizedLookup=(key:string,isFinalized:boolean,isPosted:boolean,generalComment:string, progressReportedBy:string, makeAdequateProgress:boolean)=>{
          if(goalProgressDetailsLookup.has(key)){
            goalProgressDetailsLookup.get(key)?.push({isFinalized,isPosted,generalComment, progressReportedBy, makeAdequateProgress})
          }else{
            goalProgressDetailsLookup.set(key,[{isFinalized,isPosted,generalComment, progressReportedBy, makeAdequateProgress}])
          }
        }
        // store if objective is finalized for each objective as an array of boolean values and check if all objectives are finalized
        const setObjectivesFinalizedLookup=(key:string,isFinalized:boolean,isPosted:boolean,generalComment:string,progressReportedBy:string, makeAdequateProgress:boolean)=>{
          if(objectiveProgressDetailsLookup.has(key)){
            objectiveProgressDetailsLookup.get(key)?.push({isFinalized,isPosted,generalComment,progressReportedBy,makeAdequateProgress})
          }else{
            objectiveProgressDetailsLookup.set(key,[{isFinalized,isPosted,generalComment,progressReportedBy,makeAdequateProgress}])
          }
        }
        // add missing attributes and check if all goals and objectives are finalized
        let reportsWithMissingAttributes = this.addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(reportsFilteredByGoalId,setGoalFinalizedLookup,setObjectivesFinalizedLookup)
        const goalProgressReportedLookUp = new Map<string,Array<number>>()
        const objectiveProgressReportedLookUp = new Map<string,Array<number>>() 


        reportsWithMissingAttributes.forEach((report,_reportIndexPosition)=>{
            // calculate aggregated progress for goals
            // let masteryReport = masteryFilteredByGoalId[reportIndexPosition] 
            // aggregate goal
            report?.goal?.goalProgressDetails?.forEach((goalProgressDetail,_goalProgressIndexPosition)=>{
                // const masteryReportCurrentGoalProgress = masteryReport?.goal?.goalProgressDetails?.[goalProgressIndexPosition]
                // find all duplicate goals 
                const uniqueIdentifier = this._generateId(
                  report?.goal?.goalId as string,
                  goalProgressDetail.period as number,
                  goalProgressDetail?.periodStartDate?.toISOString() as string,
                  goalProgressDetail?.periodEndDate?.toISOString() as string
                )
                // update progress of duplicate goal progress details against each reporting period
                const isGoalPosted = goalProgressDetail?.status?.toLocaleLowerCase() === ReportStatus.Posted
                if(!goalProgressReportedLookUp.has(uniqueIdentifier) && isGoalPosted){
                    goalProgressReportedLookUp.set(uniqueIdentifier,[goalProgressDetail?.progressMeasured ?? 0])
                    // check if goal is finalized
                   
                }else if(goalProgressReportedLookUp.has(uniqueIdentifier) && isGoalPosted){
                    const currentProgress:number[] = goalProgressReportedLookUp.get(uniqueIdentifier) as number[]
                    goalProgressReportedLookUp.set(uniqueIdentifier,[...currentProgress,goalProgressDetail?.progressMeasured ?? 0])
                    // check if goal is finalized
 
                }
            })
            // calculate aggregated progress for objectives
            report.objectives?.forEach((objective,_objectiveIndexPosition)=>{
              // const masteryObjective = masteryReport?.objectives?.[objectiveIndexPosition]

              objective.objectiveProgressDetails?.forEach((objectiveProgressDetail,_objectiveProgressIndexPosition)=>{
                // const masteryObjectiveProgress = masteryObjective?.objectiveProgressDetails?.[objectiveProgressIndexPosition]
                // find duplicate objectives 
                const uniqueIdentifier = this._generateId(
                  objective?.objectiveId as string,
                  objectiveProgressDetail?.period as number,
                  objectiveProgressDetail?.periodStartDate?.toISOString() as string,
                  objectiveProgressDetail?.periodEndDate?.toISOString() as string
                ) 
                // update progress of duplicate objective progress details against each reporting period
                const isObjectivePosted = objectiveProgressDetail?.status?.toLocaleLowerCase() === ReportStatus.Posted
                if(!objectiveProgressReportedLookUp.has(uniqueIdentifier) && isObjectivePosted){
                  objectiveProgressReportedLookUp.set(uniqueIdentifier,[objectiveProgressDetail?.progressMeasured ?? 0])
                  // check if objective is finalized 
                }else if(objectiveProgressReportedLookUp.has(uniqueIdentifier) && isObjectivePosted){
                  const currentProgress:number[] = objectiveProgressReportedLookUp.get(uniqueIdentifier) as number[]
                  objectiveProgressReportedLookUp.set(uniqueIdentifier,[...currentProgress,objectiveProgressDetail?.progressMeasured ?? 0])
                  // check if objective is finalized
 
              }
              }) 
                
            })
        })

      const aggregatedResult:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[] = reportsWithMissingAttributes.map((report)=>{
          
            
            return {...report,
                        goal:{
                              ...report.goal,
                              goalProgressDetails:report?.goal?.goalProgressDetails?.map((goalProgressDetail,_goalProgressIndex)=>{
                                // use the same key used to set the progress for goal
                                const uniqueIdentifier = this._generateId(
                                  report?.goal?.goalId as string,
                                  goalProgressDetail.period as number,
                                  goalProgressDetail?.periodStartDate?.toISOString() as string,
                                  goalProgressDetail?.periodEndDate?.toISOString() as string)  
                                  
                                let isNumberOfPostedAndFinalizedArrayCountEqual = false 
                                const finalizedGoalFinalizedAndPostedArray = goalProgressDetailsLookup?.get(uniqueIdentifier)
                                let longestGeneralComment = ""
                                let progressReportedBy = ""
                                let makingAdequateProgress:boolean = false
                                // check if all the posted goals under multiple service providers were finalized
                                if(finalizedGoalFinalizedAndPostedArray){
                                     progressReportedBy = this._getProgressReportedByAndProgressStatus(finalizedGoalFinalizedAndPostedArray)?.progressReportedBy
                                     makingAdequateProgress = this._getProgressReportedByAndProgressStatus(finalizedGoalFinalizedAndPostedArray)?.isMakingAdequateProgress
                                     longestGeneralComment = this._getLongestComment(finalizedGoalFinalizedAndPostedArray) ?? ""
                                     isNumberOfPostedAndFinalizedArrayCountEqual = this._checkIfisNumberOfPostedAndFinalizedArrayCountEqual(finalizedGoalFinalizedAndPostedArray)
                                }                                 
                                if(goalProgressReportedLookUp.has(uniqueIdentifier)){
                                    const lengthOfProgressArray = goalProgressReportedLookUp.get(uniqueIdentifier)?.length ?? 0
                                    const totalProgress = goalProgressReportedLookUp?.get(uniqueIdentifier)?.reduce((a,b)=>a+b,0) ?? 0

                                    return {
                                        ...goalProgressDetail,
                                          generalComments:longestGeneralComment,
                                          status: ReportStatus.Unposted,
                                          aggregatedProgress : totalProgress / lengthOfProgressArray,
                                          makingAdequateProgress,
                                          progressReportedBy,
                                          isFinalized: isNumberOfPostedAndFinalizedArrayCountEqual 
                                    }
                                }
                                else { 
                                  return {
                                    ...goalProgressDetail,
                                      generalComments:longestGeneralComment,
                                      status: ReportStatus.Unposted,
                                      aggregatedProgress : 0,
                                      isFinalized:isNumberOfPostedAndFinalizedArrayCountEqual,
                                      makingAdequateProgress,
                                      progressReportedBy
                                  }
                                } 
                            })
                        },
                        objectives:report.objectives?.map((objective)=>{
                          return{
                            ...objective,
                            objectiveProgressDetails:objective.objectiveProgressDetails.map((individualObjectiveProgress)=>{
                                  // use the same key used for setting objective progress against each reporting period
                                  const uniqueIdentifier = this._generateId(
                                    objective.objectiveId as string,
                                    individualObjectiveProgress.period as number,
                                    individualObjectiveProgress?.periodStartDate?.toISOString() as string,
                                    individualObjectiveProgress?.periodEndDate?.toISOString() as string)

                                  let isNumberOfPostedAndFinalizedArrayCountEqual = false 
                                  // check if all the posted goals under multiple service providers were finalized
                                  let objectivesFinalizedAndPostedLookupValue = objectiveProgressDetailsLookup?.get(uniqueIdentifier)
                                  let longestGeneralComment = ""
                                  let progressReportedBy = ""
                                  let makingAdequateProgress:boolean = false
                                  if(objectivesFinalizedAndPostedLookupValue){
                                         progressReportedBy = this._getProgressReportedByAndProgressStatus(objectivesFinalizedAndPostedLookupValue).progressReportedBy
                                         makingAdequateProgress = this._getProgressReportedByAndProgressStatus(objectivesFinalizedAndPostedLookupValue).isMakingAdequateProgress
                                         longestGeneralComment = this._getLongestComment(objectivesFinalizedAndPostedLookupValue) ?? ""
                                         isNumberOfPostedAndFinalizedArrayCountEqual = this._checkIfisNumberOfPostedAndFinalizedArrayCountEqual(objectivesFinalizedAndPostedLookupValue)
                                    }    
                                  if(objectiveProgressReportedLookUp.has(uniqueIdentifier)){
                                      const lengthOfProgressArray = objectiveProgressReportedLookUp.get(uniqueIdentifier)?.length ?? 0
                                      const totalProgress = objectiveProgressReportedLookUp.get(uniqueIdentifier)?.reduce((a,b)=>a+b,0) ?? 0 
                                      // check if objective is finalized
 
                                      return {
                                         ...individualObjectiveProgress,
                                         aggregatedProgress: totalProgress / lengthOfProgressArray,
                                         generalComments:longestGeneralComment,
                                         status: ReportStatus.Unposted,
                                         progressReportedBy,
                                         isFinalized:isNumberOfPostedAndFinalizedArrayCountEqual,
                                         makingAdequateProgress
                                      }
                                  }else {
                                    // check if objective is finalized
                                      return {
                                          ...individualObjectiveProgress,
                                          aggregatedProgress: 0,
                                          generalComments:longestGeneralComment,
                                          status: ReportStatus.Unposted,
                                          progressReportedBy,
                                          isFinalized:isNumberOfPostedAndFinalizedArrayCountEqual,
                                          makingAdequateProgress
                                      }
                                  } 
                          })}
                      })
                        
            }
        }) 
      return aggregatedResult
       
    }



  

    public preGlobalStateMountChanges(arg:StudentProgressReportRecordUIPaginatedReportResponse):ExtendedStudentProgressReportRecordUIPaginatedReportResponse{
 
      return  {
        ...arg,
        pageRecords: arg?.pageRecords?.map((pageRecord):ExtendedStudentProgressReportRecordUIWithIsPosted => ({
          ...pageRecord,
          goal:{
            ...pageRecord.goal,
            goalStatus: pageRecord.goal?.goalStatus as GoalOrObjectiveStatus,
            goalProgressDetails:  pageRecord.goal?.goalProgressDetails?.map((individualGoalProgress):ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute => ({
              ...individualGoalProgress,
              progressReportedBy: "", 
              isPosted: ReportStatus.Unposted 
            } as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute) ) as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute[]
          },
          objectives: pageRecord.objectives?.map((objective):ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus =>{
               return {
                 ...objective,  
                 goalStatus: objective?.goalStatus as GoalOrObjectiveStatus,
                 objectiveProgressDetails: objective?.objectiveProgressDetails?.map((objectiveProgress):ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute=>({
                  ...objectiveProgress,
                  progressReportedBy: "", 
                  isPosted: ReportStatus.Unposted
                   
                })) as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute[]
               }}) as ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus[] 
        })) as ExtendedStudentProgressReportRecordUIWithIsPosted[]
      };
    }


    public addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(
      arg:ExtendedStudentProgressReportRecordUIWithIsPosted[],
      cbG?:(arg:string,isFinalized:boolean,isPosted:boolean,generalComment:string,progressReportedBy:string,makeAdequateProgress:boolean)=>void,
      cbO?:(arg:string,isFinalized:boolean,isPosted:boolean,generalcomment:string,progressReportedBy:string,makeAdequateProgress:boolean)=>void):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]{
      return arg.map((record:ExtendedStudentProgressReportRecordUIWithIsPosted):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI => {
             
          return {
                  ...record, 
                  goal:{
                    ...record.goal,
                    goalProgressDetails:  record.goal?.goalProgressDetails?.map((individualGoalProgress,_goalProgressIndex):ExtendedStudentProgressDetailsWithAggregatedProgress =>{ 
                      if(cbG){ 
                        const _id = this._generateId( record?.goal?.goalId as string, individualGoalProgress?.period as number, individualGoalProgress?.periodStartDate?.toISOString() as string, individualGoalProgress?.periodEndDate?.toISOString() as string)
                        /**
                         *  IF GOAL IS POSTED OR IS FINALIZED THAT PARTICULAR GOAL PROGRESS WILL BE MARKED AS POSTED
                         *  NOTE: this will contribute to aggregation
                         */
                        const isGoalPosted = (individualGoalProgress?.status?.toLocaleLowerCase() === ReportStatus.Posted)
                        cbG(
                          _id,
                          !!individualGoalProgress?.isFinalized,
                          !!isGoalPosted,
                          individualGoalProgress?.generalComments ?? "",
                          individualGoalProgress?.progressReportedBy??"",
                          !!individualGoalProgress?.makingAdequateProgress
                        )
                      }
                      return {
                      ...individualGoalProgress,
                      aggregatedProgress: 0 
                    }
                  }) 
                  },
                  objectives: record.objectives?.map((objective):ExtendedObjectiveWithAggregatedProgressField =>{
                      return {
                         ...objective,
                         objectiveProgressDetails:  objective.objectiveProgressDetails?.map((individualObjectiveProgress)
                         :ExtendedStudentProgressDetailsWithAggregatedProgress => {
                          if(cbO){
                            
                            const _id = this._generateId(
                              objective.objectiveId as string,
                              individualObjectiveProgress.period as number,
                              individualObjectiveProgress?.periodStartDate?.toISOString() as string,
                              individualObjectiveProgress?.periodEndDate?.toISOString() as string
                            ) 

                            /**
                             * if objective is posted or if its finalized that particular objective progress will be marked as posted
                             *  NOTE: this will contribute to aggregation
                             */ 
                            const isObjectivePosted = (individualObjectiveProgress?.status?.toLocaleLowerCase() === ReportStatus.Posted )
                            cbO(
                              _id,
                              !!individualObjectiveProgress?.isFinalized,
                              !!isObjectivePosted,
                              individualObjectiveProgress?.generalComments ?? "",
                              individualObjectiveProgress?.progressReportedBy??"",
                              !!individualObjectiveProgress?.makingAdequateProgress
                            )
                          }
                          return {
                          ...individualObjectiveProgress,
                          aggregatedProgress: 0
                         }} 
                        )
                      }
                  }) 
                }
      })
 }


  private _checkIfisNumberOfPostedAndFinalizedArrayCountEqual(arg:GoalObjectiveProgressDetailsLookupMap[]):boolean{
      if(!arg || arg.length === 0) return false

      const isFinalizedArrLen = arg?.filter(item=>item.isFinalized)?.length
      const isPostedArrLen = arg?.filter(item=>item.isPosted)?.length
      if (isFinalizedArrLen === 0 && isPostedArrLen ===0){
          return false
      }

      return isFinalizedArrLen === isPostedArrLen
  }

  private _getLongestComment(arg: GoalObjectiveProgressDetailsLookupMap[]): string | null {
    if(!arg || arg.length === 0) return ""

    const commentsArray: string[] = arg
        .filter(item => item.isFinalized && item.isPosted) // Filter for finalized and posted items
        .map(item => item.generalComment); // Extract general comments

    // Find and return the longest comment
    return commentsArray.reduce((longest, current) => 
        current.length > longest.length ? current : longest, 
        ""
    ) || ""; // Return null if no valid comments
}
 
private _getProgressReportedByAndProgressStatus(
  arg: GoalObjectiveProgressDetailsLookupMap[]
): { progressReportedBy: string; isMakingAdequateProgress: boolean } {
  // Early return for empty or invalid input
  if (!arg || arg.length === 0) {
      return { progressReportedBy: "", isMakingAdequateProgress: false };
  }

  // Filter finalized and posted items
  const filteredItems = arg.filter(item => item?.isFinalized && item?.isPosted);

  // Extract unique progressReportedBy values
  const progressReportedByArray = Array.from(
      new Set(filteredItems.map(item => item?.progressReportedBy?.trim()))
  );

  // Check if most values are true for makeAdequateProgress
  const trueCount = filteredItems.filter(item => item?.makeAdequateProgress).length;
  const isMakingAdequateProgress = trueCount > filteredItems.length / 2;

  // Build progressReportedBy string
  const progressReportedBy =
      progressReportedByArray.length > 1
          ? progressReportedByArray.join(", ")
          : progressReportedByArray[0] || "";

  return {
      progressReportedBy,
      isMakingAdequateProgress
  };
}

      
}


export const progressReportTransformationPipeline = ReportTransformationPipeline.getInstance();