/**
 * Configures the API based on the environment.
 */


interface Config {
  readonly SESSION_API_URL: string;
  readonly PROFILE_API_URL: string;
  readonly REPORTING_API_URL: string;
}

// local
const LOCAL_SESSION = "https://localhost:7113";
const LOCAL_PROFILE = "https://localhost:7158";
const LOCAL_REPORTING = "https://localhost:7151";

//development
const LOCAL_DEV_SESSION = "https://xng-sessionapi-dev.azurewebsites.net";
const LOCAL_DEV_PROFILE = "https://xng-profileapi-dev.azurewebsites.net";
const LOCAL_DEV_REPORTING = "https://xng-api-ms.azure-api.net/api/dev-reporting";

//  dev api path
const DEV_SESSION = "/api/dev-session";
const DEV_PROFILE = "/api/dev-profile";
const DEV_REPORTING = "/api/dev-reporting";

// uat api path
const UAT_SESSION = "/api/uat-session";
const UAT_PROFILE = "/api/uat-profile";
const UAT_REPORTING = "/api/staging-reporting";

// staging api path
const STAGING_SESSION = "/api/staging-session";
const STAGING_PROFILE = "/api/staging-profile";
const STAGING_REPORTING = "/api/staging-reporting"

// production api path
const PROD_SESSION = "/api/session";
const PROD_PROFILE = "/api/profile";
const PROD_REPORTING = "/api/reporting";

export enum MODE {
  LOCAL = "local",
  LOCAL_FRONTEND_ONLY = "local-frontend-only",
  LOCAL_FRONTEND_AND_SESSION_API = "local-frontend-and-session-api",
  LOCAL_FRONTEND_AND_PROFILE_API = "local-frontend-and-profile-api",
  LOCAL_FRONTEND_AND_REPORTING_API = "local-frontend-and-reporting-api",
  LOCAL_FULL_STACK = "local-full-stack",
  DEVELOPMENT = "development",
  UAT = "uat",
  STAGING = "staging",
  PRODUCTION = "production",
}

export class GlobalLogger {
  private static instance: GlobalLogger;
  devStyles: string = "color: green; font-weight: bold; font-size: 20px;";
  prodStyles: string = "color: red; font-weight: bold; font-size: 20px;";
  infoStyles: string = "color: yellow; font-weight: bold; font-size: 20px;";
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}
  static getInstance(): GlobalLogger {
    if (!this.instance) {
      this.instance = new GlobalLogger();
    }
    return this.instance;
  }
  developmentModelogs(message: string) {
    console.log("%c=========================", "color: blue");
    console.log("%c=========================", "color: blue");
    console.log(`%c${message.toUpperCase()}`, this.devStyles);
    console.log("%c=========================", "color: blue");
    console.log("%c=========================", "color: blue");
  }

  productionModelogs(message: string) {
    console.log("%c=========================", "color: red");
    console.log("%c=========================", "color: red");
    console.log(`%c${message.toUpperCase()}`, this.prodStyles);
    console.log("%c=========================", "color: red");
    console.log("%c=========================", "color: red");
  }

  info(message: string) {
    console.log("%c=========================", "color: yellow");
    console.log("%c=========================", "color: yellow");
    console.log(`%c${message.toUpperCase()}`, this.infoStyles);
    console.log("%c=========================", "color: yellow");
    console.log("%c=========================", "color: yellow");
  }
}

/**
 * Represents the API configuration manager.
 */
export class APIConfigManager {
  /**
   * The configuration object.
   */
  readonly config: Config;

  /**
   * The mode of the API configuration.
   */
  readonly MODE: MODE;

  /**
   * The singleton instance of the APIConfigManager.
   */
  private static instance: APIConfigManager;

  /**
   * Constructs a new instance of the APIConfigManager.
   * Private to enforce singleton pattern.
   */
  private constructor() {
    this.MODE = process.env.REACT_APP_BRANCH as MODE;

    switch (this.MODE) {
      case MODE.LOCAL:
      case "local-frontend-only":
        this.config = {
          PROFILE_API_URL: LOCAL_DEV_PROFILE,
          SESSION_API_URL: LOCAL_DEV_SESSION,
          REPORTING_API_URL: LOCAL_DEV_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      case "local-frontend-and-session-api":
        this.config = {
          PROFILE_API_URL: LOCAL_DEV_PROFILE,
          SESSION_API_URL: LOCAL_SESSION,
          REPORTING_API_URL: LOCAL_DEV_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      case "local-frontend-and-profile-api":
        this.config = {
          PROFILE_API_URL: LOCAL_PROFILE,
          SESSION_API_URL: LOCAL_DEV_SESSION,
          REPORTING_API_URL: LOCAL_DEV_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      case "local-frontend-and-reporting-api":
        this.config = {
          PROFILE_API_URL: LOCAL_DEV_PROFILE,
          SESSION_API_URL: LOCAL_DEV_SESSION,
          REPORTING_API_URL: LOCAL_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      case "local-full-stack":
        this.config = {
          PROFILE_API_URL: LOCAL_PROFILE,
          SESSION_API_URL: LOCAL_SESSION,
          REPORTING_API_URL: LOCAL_DEV_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      /*
       DEV API BASE URL
    */
      case "development":
        this.config = {
          PROFILE_API_URL: DEV_PROFILE,
          SESSION_API_URL: DEV_SESSION,
          REPORTING_API_URL: DEV_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
              /*
       UAT API BASE URL
    */
      case "uat":
        this.config = {
          PROFILE_API_URL: UAT_PROFILE,
          SESSION_API_URL: UAT_SESSION,
          REPORTING_API_URL: STAGING_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      /*
       STAGING API BASE URL
    */
      case "staging":
        this.config = {
          PROFILE_API_URL: STAGING_PROFILE,
          SESSION_API_URL: STAGING_SESSION,
          REPORTING_API_URL: STAGING_REPORTING,
        };
        GlobalLogger.getInstance().developmentModelogs(`running on ${this.MODE} env`);
        break;
      /*
       PRODUCTION API BASE URL
    */
      case "production":
        this.config = {
          PROFILE_API_URL: PROD_PROFILE,
          SESSION_API_URL: PROD_SESSION,
          REPORTING_API_URL: PROD_REPORTING,
        };
        break;
      /*
       DEFAULT API BASE URL
    */
      default:
        this.config = {
          PROFILE_API_URL: PROD_PROFILE,
          SESSION_API_URL: PROD_SESSION,
          REPORTING_API_URL: PROD_REPORTING,
        };
        break;
    }
  }

  /**
   * Returns the singleton instance of the APIConfigManager.
   * @returns The APIConfigManager instance.
   */
  public static getInstance(): APIConfigManager {
    if (!APIConfigManager.instance) {
      APIConfigManager.instance = new APIConfigManager();
    }
    return APIConfigManager.instance;
  }
}
