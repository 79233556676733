import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  FC,
  useRef,
} from "react";
import { useXNGSelector } from "../../../../context/store";
import { selectLoggedInClientAssignment } from "../../../../context/slices/userProfileSlice";
import dayjs, { type Dayjs } from "dayjs";
import type { DistrictRef, ServiceProviderRef } from "../../../../profile-sdk";
import { API_SESSIONS } from "../../../../api/api";
import { ActualSession, Student } from "../../../../session-sdk";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import removeArrayDuplicates from "../../../../utils/remove_array_duplicates";
import useBatchPostingMatchPath from "../hooks/use_batch_posting_match_path";
import { useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../../constants/URLs";

interface IUnpostedSessionsBatchPostContext {
  //stateInUS
  stateInUs: string;

  // sessions
  sessions: ActualSession[];
  selectedSessionIds: string[];

  onSelectSession: (selectedId: string, checked: boolean) => void;
  onSelectAllSessions: (checked: boolean) => void;

  // filters
  startDate: Dayjs;
  endDate: Dayjs;

  providers: ServiceProviderRef[];
  selectedProviderIds: string[];

  campuses: DistrictRef[];
  selectedCampusIds: string[];

  students: Student[];
  selectedStudentIds: string[];

  onChangeDateRange: (dateRange: { start: Dayjs; end: Dayjs }) => void;
  onChangeSelectedProviderIds: (selectedProviderIds: string[]) => void;
  onChangeSelectedCampusIds: (selectedCampusIds: string[]) => void;
  onChangeSelectedStudentIds: (selectedStudentIds: string[]) => void;

  refresh: () => void;
  isLoading: boolean;
  isPosting: boolean;
  setIsPosting: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnpostedSessionsBatchPostContext = createContext<IUnpostedSessionsBatchPostContext>(null!);

const UnpostedSessionsBatchPostProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    serviceProviderProfile,
    appointingServiceProviders,
    supervisedServiceProviders,
    authorizedDistricts,
    isAutonomous,
    isProxyDataEntry,
  } = useXNGSelector(selectLoggedInClientAssignment);

  const stateInUs = useXNGSelector(selectStateInUS);
  const navigate = useNavigate();
  const batchPostingMatchPath = useBatchPostingMatchPath();
  const providers = useMemo(() => {
    switch (batchPostingMatchPath.customId) {
      case "my-sessions":
        return serviceProviderProfile ? [serviceProviderProfile] : [];
      case "dec-sessions":
        return removeArrayDuplicates(
          (serviceProviderProfile ? [serviceProviderProfile] : []).concat(
            appointingServiceProviders ?? [],
          ),
          () => "id",
        );
      case "assistant-sessions":
        return removeArrayDuplicates(supervisedServiceProviders ?? [], () => "id");
      default:
        return serviceProviderProfile ? [serviceProviderProfile] : [];
    }
  }, [
    batchPostingMatchPath.customId,
    serviceProviderProfile,
    appointingServiceProviders,
    supervisedServiceProviders,
  ]);

  const [sessions, setSessions] = useState<ActualSession[]>([]);
  const [filteredSessions, setFilteredSessions] = useState<ActualSession[]>([]);
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(2, "w"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPosting, setIsPosting] = useState<boolean>(false);
  // default providers should be all providers except the current user if we are currently in the dec-sessions batch posting page
  const defaultProviders = useMemo(() => {
    if (batchPostingMatchPath.customId === "dec-sessions") {
      return providers.filter((p) => p.id !== serviceProviderProfile?.id);
    }
    return providers;
  }, [batchPostingMatchPath.customId, providers, serviceProviderProfile?.id]);
  const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>(
    defaultProviders.map(({ id }) => id!),
  );
  const [selectedCampusIds, setSelectedCampusIds] = useState<string[]>([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([]);

  const campuses = useMemo(() => authorizedDistricts ?? [], [authorizedDistricts]);


  const handleSelectSession = useCallback<IUnpostedSessionsBatchPostContext["onSelectSession"]>(
    (selectedId, checked) => {  
      if (!checked) {
        setSelectedSessionIds(selectedSessionIds.filter((id) => id !== selectedId));
      } else if (!selectedSessionIds.includes(selectedId)) {  
        setSelectedSessionIds([...selectedSessionIds, selectedId]);
      }
    },
    [selectedSessionIds],
  );

  const handleSelectAllSessions = useCallback<
    IUnpostedSessionsBatchPostContext["onSelectAllSessions"]
  >(
    (checked) => {
      setSelectedSessionIds(checked ? filteredSessions.map(({ id }) => id!) : []);
    },
    [sessions, filteredSessions],
  );

  const handleChangeDateRange = useCallback<IUnpostedSessionsBatchPostContext["onChangeDateRange"]>(
    ({ start, end }) => {
      setStartDate(start);
      setEndDate(end);
    },
    [],
  );

  const handleChangeSelectedProviderIds = useCallback<
    IUnpostedSessionsBatchPostContext["onChangeSelectedProviderIds"]
  >((selectedProviderIds) => setSelectedProviderIds(selectedProviderIds), []);

  const handleChangeSelectedCampusIds = useCallback<
    IUnpostedSessionsBatchPostContext["onChangeSelectedCampusIds"]
  >((selectedCampusIds) => setSelectedCampusIds(selectedCampusIds), []);

  const handleChangeSelectedStudentIds = useCallback<
    IUnpostedSessionsBatchPostContext["onChangeSelectedStudentIds"]
  >((selectedStudentIds) => setSelectedStudentIds(selectedStudentIds), []);

  const refresh = () => {
    setSelectedProviderIds(providers.map(({ id }) => id!));
    setSelectedSessionIds([]);
  };

  const getPostableSessionsByDateRangeAndProviderRangeGet = () => {
    setIsLoading(true);
    /**
     * assistantServiceProviderIds in v1SessionsPostableSessionsByDateRangeAndProviderRangeGet api call will
     * sometimes have the same id as the currentUserServiceProviderId inside of it, causing sessions to be retrieved
     * that shouldn't be, so we filter assistantServiceProviderIds here. Passing undefined  if the resulting filtered array is empty
     * removes assistantServiceProviderIds from the query string parameters in the network request entirely and is still an acceptable api call
     */
    function filterSelectedProviderIdList() {
      let filteredSelectedProviderIdList = undefined;

      if (selectedProviderIds.includes(serviceProviderProfile!.id!)) {
        if (selectedProviderIds.length === 1) {
          filteredSelectedProviderIdList = selectedProviderIds.join(",");
        } else {
          filteredSelectedProviderIdList = selectedProviderIds
            .filter((id) => id !== serviceProviderProfile!.id!)
            .join(",");
        }
      } else {
        filteredSelectedProviderIdList = selectedProviderIds.join(",");
      }
      return filteredSelectedProviderIdList;
    }

    API_SESSIONS.v1SessionsPostableSessionsByDateRangeAndProviderRangeGet(
      stateInUs,
      startDate.toDate(),
      endDate.toDate(),
      isProxyDataEntry,
      isAutonomous,
      batchPostingMatchPath.customId !== "assistant-sessions" ? serviceProviderProfile!.id : "",
      batchPostingMatchPath.customId === "assistant-sessions" ? supervisedServiceProviders?.map(({ id }) => id!).join(",") : "",
      batchPostingMatchPath.customId === "dec-sessions" ? appointingServiceProviders?.map(({ id }) => id!)?.join(","): "",
    )
      .then((res) => {
        if (res.postableSessionsLists) {
          setSessions(res.postableSessionsLists);
          setIsLoading(false);
        }
      })
      .catch(console.log);
  };

  //update student optinos upon selected provider ids update
  useEffect(() => {
    if (selectedProviderIds.length === 0) {
      setStudents([]);
      return;
    }

    const filteredSessions = sessions.filter((session) =>
      selectedProviderIds.some((id) => session.serviceProvider?.id === id),
    );
    const filteredStudentOptions =
      filteredSessions
        .map(
          (session) =>
            session.studentJournalList?.map(({ student }) => {
              const studentObj = student ?? {};
              return studentObj;
            }) ?? [],
        )
        .flat() ?? [];
    const newStudents = removeArrayDuplicates(filteredStudentOptions, () => "id");
    setStudents(newStudents);
  }, [selectedProviderIds, startDate, endDate, sessions]);

  //update selected students upon selected provider ids update
  const selectedProvidersIdSnapshotRef = useRef(selectedProviderIds);
  useEffect(() => {
    if (selectedProviderIds.length === 0) {
      setSelectedStudentIds([]);
    } else if (selectedProviderIds.length === providers.length) {
      setSelectedStudentIds(students.map(({ id }) => id!));
    } else if (selectedProvidersIdSnapshotRef.current < selectedProviderIds) {
      // we are adding a provider
      const sessionsWithSelectedServiceProviders = selectedProviderIds.map((id) =>
        sessions.find((session) => session.serviceProvider?.id === id),
      );
      const studentsToAddToSelected = selectedStudentIds;
      sessionsWithSelectedServiceProviders.forEach((session) => {
        const studentIds = session?.studentJournalList?.map(({ student }) => student?.id!) ?? [];
        studentIds.forEach((id) => {
          if (!studentsToAddToSelected.includes(id)) studentsToAddToSelected.push(id);
        });
      });

      setSelectedStudentIds(Array.from(new Set(studentsToAddToSelected)));
    } else if (selectedProvidersIdSnapshotRef.current > selectedProviderIds) {
      // we are removing a provider
      const studentsToRemoveFromSelected = selectedStudentIds.filter(
        (id) => !students.find((student) => student.id === id),
      );

      const updatedSelectedStudentIds = selectedStudentIds.filter(
        (id) => !studentsToRemoveFromSelected.includes(id),
      );

      setSelectedStudentIds(updatedSelectedStudentIds);
    }
    selectedProvidersIdSnapshotRef.current = selectedProviderIds;
  }, [selectedProviderIds]);

  //update selected students upon student options update
  const studentsSnapshotRef = useRef(students);
  useEffect(() => {
    if (students.length === 0) {
      setSelectedStudentIds([]);
      return;
    } else if (studentsSnapshotRef.current.length < students.length) {
      // we are adding a student

      const alreadySelectedStudents = selectedStudentIds;
      const alreadyDeselectedStudents = studentsSnapshotRef.current.filter(
        (student) => !students.some((s) => s.id === student.id),
      );
      const newlyAddedStudents = students.filter(
        (student) =>
          !studentsSnapshotRef.current.some((s) => s.id === student.id) &&
          !alreadyDeselectedStudents.some((s) => s.id === student.id),
      );

      const updatedSelectedStudentIds = alreadySelectedStudents.concat(
        newlyAddedStudents.map(({ id }) => id!),
      );
      setSelectedStudentIds(Array.from(new Set(updatedSelectedStudentIds)));
    } else if (studentsSnapshotRef.current.length > students.length) {
      // we are removing a student
      const studentsToRemoveFromSelected = selectedStudentIds.filter(
        (id) => !students.find((student) => student.id === id),
      );

      const updatedSelectedStudentIds = selectedStudentIds.filter(
        (id) => !studentsToRemoveFromSelected.includes(id),
      );

      setSelectedStudentIds(updatedSelectedStudentIds);
    }
    else if (studentsSnapshotRef.current.length === students.length && (batchPostingMatchPath.customId === "my-sessions" || batchPostingMatchPath.customId === "dec-sessions")) {
      setSelectedStudentIds(students.map(({ id }) => id!));
    }

    studentsSnapshotRef.current = students;

    /*   const alreadySelectedStudents = selectedStudentIds;
    const alreadyDeselectedStudents = studentsSnapshotRef.current.filter(
      (student) => !students.some((s) => s.id === student.id),
    );
    const newlyAddedStudents = students.filter(
      (student) => !studentsSnapshotRef.current.some((s) => s.id === student.id) && !alreadyDeselectedStudents.some((s) => s.id === student.id),
    );

    const updatedSelectedStudentIds = alreadySelectedStudents.concat(
      newlyAddedStudents.map(({ id }) => id!),
    );
    console.log("student Optoins", students);
    console.log("updatedSelectedStudentIds", updatedSelectedStudentIds);
    setSelectedStudentIds(Array.from(new Set(updatedSelectedStudentIds))); */
  }, [students]);

  useEffect(() => {
    /**
     * Changing the year value in the date range picker with your keyboard turns the
     * startDate or endDate into an invalid date object, which was breaking the app and loading the error screen
     * This page helped me with a solution: https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
     */
    if (isNaN(Date.parse(`${startDate.toDate()}`)) || isNaN(Date.parse(`${endDate.toDate()}`))) {
      return;
    }
    if (Date.parse(`${startDate.toDate()}`) > Date.parse(`${endDate.toDate()}`)) {
      return;
    }

    getPostableSessionsByDateRangeAndProviderRangeGet();
  }, [startDate, endDate, selectedProviderIds]);

  // filter selected sessions by selected students, to avoid posting sessions that have been filtered out by the date range
  useEffect(()=>{
    setSelectedSessionIds(prev => {
      const newSelected = prev.filter(id => sessions.find(session => session.id === id))

      return newSelected;
    })
  }, [sessions])

  //filter sessions by selected students
  useEffect(() => {
    // TODO: client side filtering by campus
    setFilteredSessions(
      sessions.filter(
        (session) =>
          session.studentJournalList?.some(
            ({ student }) => student?.id && selectedStudentIds.includes(student.id),
          ),
      ),
    );
  }, [selectedStudentIds, startDate, endDate]);

  useEffect(() => {
    setSelectedSessionIds((selectedSessionIds) =>
      selectedSessionIds.filter((id) => sessions.find((session) => session.id === id)),
    );
  }, [sessions]);

  useEffect(() => {
    if (batchPostingMatchPath.customId === "none") {
      navigate(ROUTES_XLOGS.unposted_sessions.mySessions);
    }
  }, [batchPostingMatchPath.customId, navigate]);

  const contextValue: IUnpostedSessionsBatchPostContext = useMemo(() => {
    return {
      stateInUs,
      sessions: filteredSessions,
      selectedSessionIds,
      onSelectSession: handleSelectSession,
      onSelectAllSessions: handleSelectAllSessions,
      startDate,
      endDate,
      providers,
      selectedProviderIds,
      campuses,
      selectedCampusIds,
      students,
      selectedStudentIds,
      onChangeDateRange: handleChangeDateRange,
      onChangeSelectedProviderIds: handleChangeSelectedProviderIds,
      onChangeSelectedCampusIds: handleChangeSelectedCampusIds,
      onChangeSelectedStudentIds: handleChangeSelectedStudentIds,
      refresh: refresh,
      isLoading,
      isPosting,
      setIsPosting,
    };
  }, [
    campuses,
    endDate,
    filteredSessions,
    handleChangeDateRange,
    handleChangeSelectedCampusIds,
    handleChangeSelectedProviderIds,
    handleChangeSelectedStudentIds,
    handleSelectAllSessions,
    handleSelectSession,
    isLoading,
    isPosting,
    providers,
    refresh,
    selectedCampusIds,
    selectedProviderIds,
    selectedSessionIds,
    selectedStudentIds,
    startDate,
    stateInUs,
    students,
  ]);

  return (
    <UnpostedSessionsBatchPostContext.Provider value={contextValue}>
      {children}
    </UnpostedSessionsBatchPostContext.Provider>
  );
};

export const useUnpostedSessionsBatchPostContext = () =>
  useContext(UnpostedSessionsBatchPostContext);

export default UnpostedSessionsBatchPostProvider;
