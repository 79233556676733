import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import useApiQueryCampusDropDownDisplays from "../../../../../api/hooks/districts/use_api_query_campus_drop_down_displays";
import { useXNGSelector } from "../../../../../context/store";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../../../../context/slices/userProfileSlice";
import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../../utils/msb_mui_auto_complete_filter_options";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  unpostedSessionsReportsFormSchema,
  UnpostedSessionReportsFormSchema,
} from "../constants/unposted_sessions_reports_form_schema";

type Props = {
  generateReport: (formData: UnpostedSessionReportsFormSchema) => void;
  isLoading: boolean;
};

export default function UnpostedSessionsReportsForm(props: Readonly<Props>) {
  const stateInUs = useXNGSelector(selectStateInUS);
  const districtId = useXNGSelector(selectLoggedInClientAssignment).authorizedDistricts?.[0].id;

  // Api request to fetch list of campuses
  const {
    data: campusOptions,
    isLoading: campusOptionsIsLoading,
    isError: campusOptionsIsError,
    refetch: refetchCampusOptions,
  } = useApiQueryCampusDropDownDisplays({
    queryParams: {
      districtId: districtId,
      state: stateInUs,
    },
  });

  const { control, handleSubmit } = useForm<UnpostedSessionReportsFormSchema>({
    resolver: yupResolver(unpostedSessionsReportsFormSchema),
    defaultValues: {
      startDate: null,
      endDate: null,
      includeProvidersWithoutCampuses: false,
      schoolCampuses: [],
    },
  });

  function handleFormSubmit(data: UnpostedSessionReportsFormSchema) {
    props.generateReport(data);
  }

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(handleFormSubmit)}
      sx={{
        py: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "stretch",
            lg: "start",
          },
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Controller
          control={control}
          name="schoolCampuses"
          render={({ field }) => {
            return (
              <MSBInputErrorWrapper
                errorText={"Error fetching campuses"}
                isError={campusOptionsIsError}
                refetch={refetchCampusOptions}
                displayRefreshButton={!!campusOptionsIsError}
                rootProps={{
                  width: "100%",
                }}
              >
                <MSBSearchMultiselect
                  selectedOptions={field.value ?? []}
                  options={campusOptions?.schoolCampuses ?? []}
                  getOptionLabel={(option) => option?.name ?? ""}
                  onChange={(so) => {
                    field.onChange(so);
                  }}
                  disabled={campusOptionsIsError}
                  renderOptionVariant="checkbox"
                  variant="no overflow after 1"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label={campusOptionsIsLoading ? "Loading Campuses..." : "Campus(es)"}
                  sx={{ width: "100%", backgroundColor: "white" }}
                  autocompleteProps={{
                    disableCloseOnSelect: true,
                    filterOptions: msbMUIAutoCompleteFilterOptions(),
                  }}
                />
              </MSBInputErrorWrapper>
            );
          }}
        />
        <Controller
          control={control}
          name="startDate"
          render={({ field, fieldState: { error } }) => {
            return (
              <DatePicker
                defaultValue={null}
                disableFuture
                slotProps={{
                  textField: {
                    size: "small",
                    onBlur: field.onBlur,
                    ref: field.ref,
                    fullWidth: true,
                    helperText: error?.message,
                    FormHelperTextProps: {
                      sx: {
                        color: "error.main",
                        ml: 0,
                      },
                    },
                  },
                }}
                label={"Start Date"}
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="endDate"
          render={({ field, fieldState: { error } }) => {
            return (
              <DatePicker
                disableFuture
                slotProps={{
                  textField: {
                    size: "small",
                    onBlur: field.onBlur,
                    ref: field.ref,
                    fullWidth: true,
                    helperText: error?.message,
                    FormHelperTextProps: {
                      sx: {
                        color: "error.main",
                        ml: 0,
                      },
                    },
                  },
                }}
                label={"End Date"}
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            );
          }}
        />

        <Button type="submit" sx={{ minWidth: "200px" }} size="large" disabled={props.isLoading}>
          Generate Report
        </Button>
      </Box>
      <Controller
        control={control}
        name="includeProvidersWithoutCampuses"
        render={({ field }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v.target.checked);
                  }}
                />
              }
              label={"Include Providers Without Campuses"}
              sx={{
                alignSelf: {
                  xs: "stretch",
                  md: "flex-start",
                },
                minWidth: "200px",
              }}
            />
          );
        }}
      />
    </Box>
  );
}
