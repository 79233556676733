import { Box } from "@mui/material";
import React, { useState } from "react";
import { getSessionReportStatusEnumFromString } from "../utils/session_report_status_mapper";
import produce from "immer";
import {
  useApiMutateQueueSessionLogsReport,
  useQueryAndFilterServiceProviderOptionsByUserRoles,
  useSessionLogsContext,
} from "../hooks";
import { SessionLogsForm, SessionLogsSummary } from "../components";
import { SessionLogsFormType } from "../constants";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import {
  GetSessionLogsSummaryReportPostRequest,
  QueueSessionLogsReportResponse,
  SessionLogsSortableColumn,
  SessionLogsSortableColumnSortColumn,
} from "@xng/reporting";
import useApiQueryStateSnapshotsByDateByServiceProviderType from "../../../../../api/hooks/state_snapshots/use_api_query_state_snapshots_bydate_by_service_provider_type";
import useApiQuerySchoolCampusesDropdownDisplaysGet from "../../../../../api/hooks/districts/use_api_query_school_campuses_drop_down_displays_get";
import { DistrictRef } from "../../../../../profile-sdk";

export const SessionLogsView = () => {
  const [openSessionLogsSummaryQueryStatusModal, setOpenSessionLogsSummaryQueryStatusModal] =
    useState(false);

  const [
    openQueueSessionLogsReportQueryStatusModal,
    setOpenQueueSessionLogsReportQueryStatusModal,
  ] = useState(false);

  //#region CONTEXT
  const {
    appState: {
      clientId,
      stateInUs,
      authorizedDistricts,
      serviceProviderProfile,
      userRoles,
      loggedInClientAssignment,
    },
    sessionLogsSummaryState: {
      selectedStudentsIds,
      setSelectedStudentsIdsHandler,
      sessionLogsSummaryApiClientManager,
    },
    sessionLogsReportState: { pollSessionLogsReportApiClientManager },
  } = useSessionLogsContext();

  //#endregion

  //#region REF HOOKS
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  //#endregion

  const { mutate: queSessionLogsReportApi, status: queSessionLogsReportStatus } =
    useApiMutateQueueSessionLogsReport({
      options: {
        onMutate: () => {
          setOpenQueueSessionLogsReportQueryStatusModal(true);
        },
      },
    });

  const {
    data: serviceProviderOptions,
    status: serviceProviderOptionsStatus,
    refetch: refetchServiceProviderOptions,
  } = useQueryAndFilterServiceProviderOptionsByUserRoles({
    clientId: clientId || "",
    stateInUs: stateInUs,
    serviceProviderProfileInfo: serviceProviderProfile,
    userRoles: userRoles,
    loggedInClientAssignmentProviders: {
      supervisedServiceProviders: loggedInClientAssignment?.supervisedServiceProviders || [],
      appointingServiceProviders: loggedInClientAssignment?.appointingServiceProviders || [],
    },
  });

  const {
    data: serviceTypesResponse,
    status: serviceTypesResponseStatus,
    refetch: refetchServiceTypes,
  } = useApiQueryStateSnapshotsByDateByServiceProviderType({
    queryParams: {
      stateInUs: stateInUs,
    },
  });

  const {
    data: campusesResponse,
    status: campusesResponseStatus,
    refetch: refetchCampuses,
  } = useApiQuerySchoolCampusesDropdownDisplaysGet({
    queryParams: {
      districtIds: authorizedDistricts?.map((d: DistrictRef) => d.id ?? "").join(",") || "",
      state: stateInUs,
    },
    options: {
      enabled: !!(authorizedDistricts && authorizedDistricts.length > 0),
      staleTime: 60 * 60 * 1000,
    },
  });
  //#endregion

  /**
   * Generates a report summary based on the provided form filters.
   * @param formData The filters to apply to the report.
   */
  function generateReportSummaryHandler(formData: SessionLogsFormType) {
    if (sessionLogsSummaryApiClientManager.status === "pending") return;
    let dateFilters;
    if (formData.sessionDateFilter === "Date Range") {
      dateFilters = {
        startDate: formData.dateFilterOptions.startDate,
        endDate: formData.dateFilterOptions.endDate,
      };
    } else if (formData.sessionDateFilter === "Week of School Year") {
      dateFilters = {
        startDate: formData.dateFilterOptions.week.start,
        endDate: formData.dateFilterOptions.week.end,
      };
    } else {
      dateFilters = {
        startDate: undefined,
        endDate: undefined,
      };
    }

    const requestBody: GetSessionLogsSummaryReportPostRequest = {
      filterParameters: {
        clientId: clientId || "",
        districtOfLiabilityIds: new Set(formData.dols?.map((d) => d.id ?? "") || []),
        schoolCampusIds: new Set(formData.schools?.map((s) => s.id ?? "") || []),
        serviceProviderIds: new Set(formData.serviceProviders?.map((s) => s.id ?? "") || []),
        serviceIds: new Set(formData.serviceTypes?.map((s) => s.id ?? "") || []),
        sessionStatuses: new Set(
          formData.sessionFilter?.map((status) =>
            getSessionReportStatusEnumFromString(status ?? ""),
          ) || [],
        ),
        makeupSessions:
          formData.makeUpSession === "Both" ? undefined : formData.makeUpSession === "Yes",
        providerAbsent:
          formData.providerAbsent === "Both" ? undefined : formData.providerAbsent === "Yes",
        startDate: dateFilters.startDate,
        endDate: dateFilters.endDate,
      },
    };
    sessionLogsSummaryApiClientManager.mutate(
      {
        getSessionLogsSummaryReportPostRequest: requestBody,
      },
      {
        onSuccess() {
          setTimeout(() => {
            tableContainerRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 500);
        },
      },
    );
    setOpenSessionLogsSummaryQueryStatusModal(true);
  }

  /**
   * Handles the queuing of a session logs report.
  
   */
  function queSessionLogsReportHandler() {
    //add student Ids to the filter parameters
    const updatedFilterParameters = produce(
      sessionLogsSummaryApiClientManager.variables?.getSessionLogsSummaryReportPostRequest
        ?.filterParameters,
      (draft) => {
        const studentIds =
          selectedStudentsIds.length === sessionLogsSummaryApiClientManager?.data?.records?.length
            ? []
            : selectedStudentsIds;

        if (draft) {
          draft.studentIds = new Set(studentIds);
        }
      },
    );

    queSessionLogsReportApi(
      {
        queueSessionLogsReportPostRequest: {
          filterParameters: updatedFilterParameters,
        },
      },
      {
        onSuccess: (data) => {
          setOpenQueueSessionLogsReportQueryStatusModal(false);
          generateReportHandler(data);
        },
      },
    );
  }

  /**
   * Generates a report based on the provided queue session logs response.
   * @param data The queue session logs response.
   */
  function generateReportHandler(data: QueueSessionLogsReportResponse) {
    pollSessionLogsReportApiClientManager.mutate({
      getSessionLogsReportPostRequest: {
        dateRun: data?.reportRunDate,
        reportRunId: data?.reportRunId,
        pageParameters: {
          pageNumber: 1,
          pageSize: 10,
        },
        sortColumns: new Set([
          {
            column: SessionLogsSortableColumn.NUMBER_0,
            sortDirection: 0,
          },
        ]) as Set<SessionLogsSortableColumnSortColumn>,
      },
    });
  }
  //#endregion

  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
        }}
      >
        <SessionLogsForm
          isAdmin={userRoles.includes("Executive Admin") || userRoles.includes("Delegated Admin")}
          inputComponentProps={{
            serviceProviders: {
              options: serviceProviderOptions,
              optionsApiStatus: serviceProviderOptionsStatus,
              refetchOptions: refetchServiceProviderOptions,
            },
            districtsOfLiability: {
              options: authorizedDistricts ?? [],
            },
            campuses: {
              options: campusesResponse?.schoolCampuses || [],
              optionsApiStatus: campusesResponseStatus,
              refetchOptions: refetchCampuses,
            },
            serviceTypes: {
              options: serviceTypesResponse?.services ?? [],
              optionsApiStatus: serviceTypesResponseStatus,
              refetchOptions: refetchServiceTypes,
            },
          }}
          onSubmitFormFilters={generateReportSummaryHandler}
          // update_selected_authorized_district_ids={setSelectedDistrictIdsHandler}
        />

        {/* <SessionLogsFullScreenLoadingScreen /> */}
        {sessionLogsSummaryApiClientManager.status !== "idle" && (
          <SessionLogsSummary
            ref={tableContainerRef}
            layout={{
              onGenerateReportBtnClick: queSessionLogsReportHandler,
              generateReportBtnDisabled: selectedStudentsIds.length <= 0,
            }}
            table={{
              rows: sessionLogsSummaryApiClientManager.data?.records || [],
              selectedRowIds: selectedStudentsIds,
              onRowSelected: setSelectedStudentsIdsHandler,
              dataLoading: sessionLogsSummaryApiClientManager.isPending,
            }}
          />
        )}
      </Box>

      <QueryStatusModal
        isOpen={openSessionLogsSummaryQueryStatusModal}
        status={sessionLogsSummaryApiClientManager.status}
        onSettledClose={() => {
          setOpenSessionLogsSummaryQueryStatusModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Session logs summary loaded successfully. View table below",
          errorTitle: "Error!",
          errorBody:
            "Unable to load the report. This could be due to a timeout error. If this is your first time loading a report in a while," +
            " please wait a minute or so and try again. If the problem continues, contact customer support.",
          pendingTitle: "Loading Session Logs Summary",
        }}
      />
      <QueryStatusModal
        isOpen={openQueueSessionLogsReportQueryStatusModal}
        status={queSessionLogsReportStatus}
        onSettledClose={() => {
          setOpenQueueSessionLogsReportQueryStatusModal(false);
        }}
        content={{
          successTitle: "Success!",
          successBody: "Session logs report queued successfully.",
          errorTitle: "Error!",
          errorBody: "Problem queuing session logs report, please try again.",
          pendingTitle: "Queuing Session Logs Report",
        }}
      />
    </>
  );
};
