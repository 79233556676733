import { Radio, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface MSBRadioTableProps<T> {
  rows: T[];
  columns: { key: keyof T; title: string }[];
  onChange: (v: T | null) => void;
  defaultSelectedIndex?: number;
  disableUnselectedRow?: boolean;
}

/**
 * Assume Open/Closed Principle: Do not modify integral functionality, but extending styling is okay.
 */
function MSBRadioTable<T>(props: Readonly<MSBRadioTableProps<T>>) {
  const { rows, columns, onChange } = props;
  const { palette } = useTheme();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(props.defaultSelectedIndex ?? null);

  useEffect(() => {
    onChange(selectedIndex !== null ? rows[selectedIndex] : null);
  }, [selectedIndex]);

  return (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow className="noselect">
          <TableCell></TableCell>
          {props.columns.map((c) => (
            <TableCell key={c.key.toString()}>{c.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          const isSelected = selectedIndex === i;
          return (
            <TableRow
              className="noselect"
              key={i}
              sx={{
                opacity: !isSelected && props.disableUnselectedRow ? 0.5 : 1,
                ":hover": {
                  cursor: "pointer",
                  bgcolor: palette.grey[100],
                },
              }}
              onClick={() => {
                if (props.disableUnselectedRow && !isSelected) return;
                setSelectedIndex(i);
              }}
            >
              <TableCell>
                <Radio
                  size="small"
                  checked={isSelected}
                  disabled={!isSelected && props.disableUnselectedRow}
                />
              </TableCell>
              {columns.map((col) => (
                <TableCell key={col.key.toString()}>{row[col.key] as React.ReactNode}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default MSBRadioTable;
