import { Button, Grow, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { MSBICONS } from "../../../../../../fortitude";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

const { CSV: Csv, LoadingAnimation } = MSBICONS;

/**
 * LoadingOverLay component responsible for displaying loading message with an animation.
 *
 * @param {Object} props - The component props.
 * @param {string} props.mainText - The main text to display.
 * @param {string} props.loadingText - The loading text to display with a dot animation.
 * @param {boolean} props.iconOnly - The flag to display only an icon.
 * @param {boolean} props.isLoading - The flag to display the loading overlay.
 * @returns {Function} The rendered component.
 */
export function LoadingOverLay(
  props: Readonly<{ mainText: string; loadingText: string; iconOnly: boolean; isLoading: boolean }>,
): () => JSX.Element | null {
  if (!props.isLoading) return () => null;

  if (props.iconOnly) return () => <LoadingIconOverlay />;

  return () => LoadingMessageOverlay({ mainText: props.mainText, loadingText: props.loadingText });
}

/**
 * LoadingMessageOverlay component displays a loading message with an animation.
 *
 * @param {Object} props - The component props.
 * @param {string} props.mainText - The main text to display.
 * @param {string} props.loadingText - The loading text to display with a dot animation.
 * @returns {JSX.Element} The rendered component.
 */
function LoadingMessageOverlay(
  props: Readonly<{ mainText: string; loadingText: string }>,
): JSX.Element {
  return (
    <Box
      aria-label="emtpy table"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grow in={true} timeout={1000}>
        <Alert
          severity="info"
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            py: 2,
          }}
          iconMapping={{
            info: <LoadingAnimation width={30} height={30} />,
          }}
          elevation={3}
          square
        >
          <Typography
            variant="h6"
            sx={{
              "@keyframes fade": {
                "0%": {
                  opacity: 0.5,
                },
                "100%": {
                  opacity: 1,
                },
              },
              animation: "fade 2s steps(3, end) infinite",
            }}
          >
            {props.mainText}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              "@keyframes loadingDots": {
                "0%": {
                  content: '""',
                },
                "33%": {
                  content: '"."',
                },
                "66%": {
                  content: '".."',
                },
                "100%": {
                  content: '"..."',
                },
              },
              "&::after": {
                content: '""',
                animation: "loadingDots 1s steps(3, end) infinite",
              },
            }}
          >
            {props.loadingText}
          </Typography>
        </Alert>
      </Grow>
    </Box>
  );
}

/**
 * LoadingIconOverlay component responsible for loading only an icon, used in server side pagination.
 *
 * @returns {JSX.Element} The rendered component.
 */
function LoadingIconOverlay(): JSX.Element {
  return (
    <Box
      aria-label="emtpy table"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <LoadingAnimation width={60} height={60} />
    </Box>
  );
}

/**
 * CustomToolBar component responsible for customizing the toolbar in the data grid.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.csvBtn - The CSV button props.
 * @param {boolean} props.csvBtn.loading - The flag to display the loading animation.
 * @param {Function} props.csvBtn.onClick - The function to handle the click event.
 * @param {boolean} props.csvBtn.disabled - The flag to disable the button.
 * @returns {Function} The rendered component
 *
 * @returns {Function} The rendered component.
 * **/
export function CustomToolBar(
  props: Readonly<{ csvBtn: { loading: boolean; onClick: () => void; disabled: boolean } }>,
): () => JSX.Element {
  return () => (
    <GridToolbarContainer sx={{ py: 1 }}>
      <GridToolbarColumnsButton variant="text" size="large" />
      <GridToolbarDensitySelector variant="text" size="large" />
      <Button
        disabled={props.csvBtn.disabled}
        startIcon={
          props.csvBtn.loading ? (
            <LoadingAnimation width={16} height={16} />
          ) : (
            <Box
              sx={{
                svg: {
                  "*": {
                    fill: props.csvBtn.disabled ? "rgba(65, 64, 64, 0.57)" : "primary.main",
                  },
                },
              }}
            >
              <Csv width={14} height={16} />
            </Box>
          )
        }
        variant="text"
        size="large"
        onClick={props.csvBtn.onClick}
      >
        Export to CSV
      </Button>
    </GridToolbarContainer>
  );
}
