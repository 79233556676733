import { StudentResponse } from "../../../../profile-sdk";
import CareProvisionGridLayout from "../../../notator/layouts/care_provision_grid";
import { UnlockedCareProvisionsContainer } from "./custom_care_provisions_container";
import produce from "immer";
import dayjs from "dayjs";
import { OtherField } from "../../../notator/types/care_provision";

export default function CustomCareProvisionsList(
  props: Readonly<{
    setEditedStudent: React.Dispatch<React.SetStateAction<StudentResponse | null>>;
    editedStudent: StudentResponse | null;
    title: "activities" | "accommodations" | "modifications";
    defaultIDs: string[];
  }>,
) {
  const { editedStudent, setEditedStudent, title, defaultIDs } = props;

  const savedCustomCareProvisionLedger =
    editedStudent?.spedDossier?.prescribedCareProvisionsLedger?.[title]!.map(
      (provision) => provision.name || "",
    )!;

  const handleStudentUpdate = (provisions: OtherField[]) => {
    const updatedStudent = produce(editedStudent, (draft) => {
      //@ts-ignore
      draft!.spedDossier!.prescribedCareProvisionsLedger![title] = provisions.map(
        (careProvision, i) => {
          if (!draft!.spedDossier!.prescribedCareProvisionsLedger![title]?.[i]) {
            return {
              name: careProvision.name,
              startDate: dayjs().toDate(),
              endDate: null,
              updatedAt: dayjs().toDate(),
              createdAt: dayjs().toDate(),
              id: crypto.randomUUID(),
            };
          } else {
            const startDate =
              draft!.spedDossier!.prescribedCareProvisionsLedger![title]?.[i].startDate;
            const createdAt =
              //@ts-ignore
              draft!.spedDossier!.prescribedCareProvisionsLedger![title]?.[i].createdAt;
            //@ts-ignore
            const id = draft!.spedDossier!.prescribedCareProvisionsLedger![title]?.[i].id;

            return {
              name: careProvision.name,
              startDate,
              endDate: null,
              updatedAt: dayjs().toDate(),
              createdAt,
              id,
            };
          }
        },
      );
    });

    setEditedStudent(updatedStudent);
  };

  return (
    <CareProvisionGridLayout>
      <UnlockedCareProvisionsContainer
        onBlur={(provisions: OtherField[]) => {
          handleStudentUpdate(provisions);
        }}
        validationDependencies={{
          defaultIDs,
          savedCustomCareProvisionLedger,
          mode: title,
        }}
        onDelete={(index: number) => {
          const updatedStudent = produce(editedStudent, (draft) => {
            draft!.spedDossier!.prescribedCareProvisionsLedger![title]?.splice(index, 1);
          });
          setEditedStudent(updatedStudent);
        }}
      />
    </CareProvisionGridLayout>
  );
}
