import { LogLevel } from "@azure/msal-browser";
import { 
  AUTH_CLIENT_ID,
  AUTH_SIGN_UP_SIGN_IN_AUTHORITY,
  AUTH_DOMAIN_AUTHORITY,
  AUTH_REDIRECT_URI,AUTH_POST_LOGOUT_REDIRECT_URI,
  AUTH_LOGIN_REQUEST_SCOPES,
  AUTH_API_CONFIG_SCOPES,
  AUTH_API_CONFIG_URI 
} from "../constants/deployment";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: AUTH_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: AUTH_SIGN_UP_SIGN_IN_AUTHORITY, // Choose SUSI as your default authority.
    knownAuthorities: [AUTH_DOMAIN_AUTHORITY, AUTH_SIGN_UP_SIGN_IN_AUTHORITY], // Mark your B2C tenant's domain as trusted.
    redirectUri: AUTH_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: AUTH_POST_LOGOUT_REDIRECT_URI, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If true, will navigate back to the original request location before processing the authorization code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCoockies: "true",
  },
  system: {
    loggerOptions: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: AUTH_LOGIN_REQUEST_SCOPES,
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const apiConfig = {
  scopes: AUTH_API_CONFIG_SCOPES,
  uri: AUTH_API_CONFIG_URI,
};
