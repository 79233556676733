import { useMemo } from "react";
import { useNotatorTools } from "../tools";

 

const useTotalBillableTimesForAllStudentsInCurrentSession = ():number => {
    const { draftSession: editedSession } = useNotatorTools();

    const totalBillableMintesForAllStudentsInCurrentSession = useMemo(() => {
        return editedSession?.
        studentJournalList?.reduce((acc, studentJournal) =>{
            return acc + Number(studentJournal.careProvisionLedger?.activities?.reduce((acc, activity) => acc + Number(activity?.totalMinutesSpent), 0))
        },0)
 
    },[editedSession])

   return totalBillableMintesForAllStudentsInCurrentSession!
}

export default useTotalBillableTimesForAllStudentsInCurrentSession