import { Box, ButtonBase, Typography } from "@mui/material";
import { FadeIn, XNGAvatar, XNGIconRenderer, XNGICONS } from "../../../../../design";
import usePalette from "../../../../../hooks/usePalette";
import { CaseloadStudentDisplay } from "../../../../../profile-sdk";
import XNGCard from "../../../../../design/low-level/card";
import { getSizing } from "../../../../../design/sizing";
import { studentProfileGradeToString } from "../../../../../utils/add_student_grade_suffix";
import XNGProgress from "../../../../../design/low-level/progress";
import dayjs from "dayjs";
import getProgressPercentage from "../../../utils/get_progress_percentage";
import { useEffect, useMemo, useState } from "react";

const DATE_FORMAT = "MM/DD/YYYY";

function SearchCard(
  props: Readonly<{
    onClick: (studentID: string) => void;
    onClickSideEffect?: () => void;
    s: CaseloadStudentDisplay;
    animIndex: number;
    filteredStudents: CaseloadStudentDisplay[];
  }>,
) {
  const s = props.s;
  const palette = usePalette();
  
  const [addedState, setAddedState] = useState({
    isAdded:props?.filteredStudents.findIndex((student) => student.id === s.id) !== -1,
    byClick: false
  });

  // addable is true if the student is not in the caseload or if the student is not added
  const addable = useMemo(() => {
    const notInCaseload = props?.filteredStudents.findIndex((student) => student.id === s.id) === -1;

    return notInCaseload || !addedState.isAdded;
  }, [addedState, props?.filteredStudents, s.id]);

  const fInitial = s.firstName ? s.firstName[0] : "S";
  const lInitial = s.lastName ? s.lastName[0] : "N";

  // reset added state if the student is removed from the caseload/added to caseload
  useEffect(() => {
    setAddedState({
      isAdded: props?.filteredStudents.findIndex((student) => student.id === s.id) !== -1,
      byClick: false
    });
  }, [props?.filteredStudents, s.id]);

  useEffect(() => {
    if(addedState.isAdded && addedState.byClick){
      props.onClickSideEffect && props.onClickSideEffect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedState, addable]);

  return (
    <FadeIn i={props.animIndex}>
      <ButtonBase>
        {/* TODO: Replace with MUI card */}
        <XNGCard
          sx={{
            display: "flex",
            maxWidth: getSizing(34),
            paddingX: getSizing(2),
            paddingTop: getSizing(3),
            paddingBottom: getSizing(4),
            width: "269px",
            height: "345px",
            bgcolor: palette.contrasts[5],
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: getSizing(1.5), alignItems: "center" }}>
              <XNGAvatar size="lg" text={fInitial + lInitial} />
              <Typography variant="h6" color={palette.primary[2]}>
                {s.firstName} {s.lastName}
              </Typography>
            </Box>
            <Box
              sx={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => {
                if(!addedState.isAdded){
                  if (s.id) props.onClick(s.id);
                  setAddedState({
                    isAdded: true,
                    byClick: true
                  });
                }
              }}
            >
              {/* TODO: Replace with MSB renderer and react-icons */}
              {addable ? <XNGIconRenderer i={<XNGICONS.PlusSign />} size="lg" /> : null}
            </Box>
            <Box sx={{ display: "flex", gap: getSizing(4), paddingTop: getSizing(2) }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography variant="body2" color={palette.primary[2]}>
                  DOB
                </Typography>
                <Typography variant="body2">{dayjs(s.dateOfBirth).format(DATE_FORMAT)}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography variant="body2" color={palette.primary[2]}>
                  Grade
                </Typography>
                <Typography variant="body2">{studentProfileGradeToString(s.grade!.valueOf())}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography variant="body2" color={palette.primary[2]}>
                  Student ID
                </Typography>
                <Typography variant="body2">{s.studentIdGivenBySchoolDistrict}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingTop: getSizing(2),
                alignItems: "start",
              }}
            >
              <Typography variant="body2" color={palette.primary[2]}>
                Campus
              </Typography>
              <Typography variant="body2">{s.activeSchoolCampuses?.[0]?.name}</Typography>
            </Box>
            <Box sx={{ display: "flex", paddingY: getSizing(2), width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: palette.contrasts[3],
                  height: "1px",
                  marginY: getSizing(2),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant="body2" color={palette.primary[2]}>
                Duration
              </Typography>
              <Typography variant="body2">
                {!s.activePlanOfCare?.startDate
                  ? "No Start Date"
                  : dayjs(s.activePlanOfCare?.startDate).format(DATE_FORMAT)}
                {" - "}
                {!s.activePlanOfCare?.endDate
                  ? "No End Date"
                  : dayjs(s.activePlanOfCare?.endDate).format(DATE_FORMAT)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", paddingTop: getSizing(2), flexDirection: "column" }}>
              <XNGProgress progress={getProgressPercentage(s)} />
            </Box>
          </Box>
        </XNGCard>
      </ButtonBase>
    </FadeIn>
  );
}

export default SearchCard;
