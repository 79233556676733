import React, { useLayoutEffect, useRef } from 'react';
import { percent,Root,color } from "@amcharts/amcharts5";
import { PieChart, PieSeries } from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useMediaQuery } from '@mui/material';

// Define the data type with optional color
interface ChartDataItem {
  value: number;
  category: string;
  color?: string;
}

interface SemiCirclePieChartProps {
  data: ChartDataItem[];
  defaultColors?: string[]; // Optional default color palette
  height?: React.CSSProperties["height"];
  width?: React.CSSProperties["width"];
  callback?: (chart: PieChart,root:Root) => void;
}

const MSBSemiCirclePieChart: React.FC<SemiCirclePieChartProps> = ({
  data,
  defaultColors = [
    "#ff6384", "#36a2eb", "#cc65fe", "#ffce56", 
    "#4bc0c0", "#ff9f40", "#9966ff"
  ],
  height = "400px",
  width = "100%",
  callback
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  useLayoutEffect(() => {
    if (!chartRef.current) return;

    // Create root element
    const root = Root.new(chartRef.current);

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    const chart = root.container.children.push(
      PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        layout: root.verticalLayout,
        innerRadius: percent(50)
      })
    );

    
    // Create series
    const series = chart.series.push(
      PieSeries.new(root, {
        startAngle: 180,
        endAngle: 360,
        valueField: "value",
        categoryField: "category",
        alignLabels: true,
        innerRadius: percent(65)
      })
    );

 
    series.slices.template.setAll({
      cornerRadius: 5,
      stroke: color(0x000000), // Transparent border
      strokeWidth: 0.1, 
    });

    series.slices.template.states.create("hover", {
      stroke: color(0x000000), // Transparent hover border
      strokeWidth: 0.1,
    });

     // Configure ticks
     series.ticks.template.setAll({
      location: 1,
      stroke: color(0x000000),
      strokeWidth: 2,
      length: 10,
      maxWidth: 10,
      visible: !isSmallScreen
    });

    // Configure labels
    series.labels.template.setAll({
      text: "{category}",
      textType: "radial",
      inside: false,
      radius: 15,
      fontSize: 12,
      hoverOnFocus: false,
      visible: !isSmallScreen
    });



    // Customize color for each slice
    series.slices.template.adapters.add("fill", (fill, target) => {
      const dataItem = target.dataItem;
      if (dataItem) {
        const dataContext = dataItem.dataContext as ChartDataItem;
        // Use custom color if provided, otherwise use from default palette
        if (dataContext?.color) {
          return color(dataContext.color);
        }
        
        // Use a default color based on index
        const index =  data.indexOf(dataContext);
        return color(defaultColors[index]);
      }
      return fill;
    });


    // Set data
    series.data.setAll(data);
    
    if(callback)callback(chart,root)
 
    // Animate series appearance
    series.appear(1000, 100);
    root._licenseApplied()
    // Cleanup
    return () => {
      root.dispose();
    };
  }, [data, defaultColors]);

  return <div ref={chartRef} style={{ height, width,position:"relative" }} /> 
};

export default MSBSemiCirclePieChart;