import React from 'react';
import { Box, Typography, styled } from '@mui/material';

// Styled LinearProgress with a gradient for billable and exceeded portions
const GradientProgressBar = styled('div')<{
  sessionPercentage: number;
}>(({ sessionPercentage, theme }) => ({
  height: 5,
  width: '100%',
  borderRadius: 10,
  background: `linear-gradient(
    to right,
    ${theme.palette.success.main} ${sessionPercentage}%,
    ${theme.palette.error.main} ${sessionPercentage}%,
    ${theme.palette.error.main} 100%
  )`,
}));

// Styled LinearProgress with a gradient for consumed and remaining portions
const GradientProgressBar2 = styled('div')<{
  consumedPercentage: number;
}>(({ consumedPercentage, theme }) => ({
  height: 5,
  width: '100%',
  borderRadius: 10,
  background: `linear-gradient(
    to right,
    ${theme.palette.success.main} ${consumedPercentage}%,
    ${theme.palette.grey[400]} ${consumedPercentage}%,
    ${theme.palette.grey[400]} 100%
  )`,
}));

interface ProgressBarProps {
  totalSessionTime: number; // Total session time
  totalBilledTime: number; // Total billed time
}

const ProgressBar: React.FC<ProgressBarProps> = ({ totalSessionTime, totalBilledTime }) => {
  // Ensure no division by zero and handle percentages
  const isTotalBilledTimeMoreThanSessionTime = totalBilledTime > totalSessionTime;
  const consumedPercentage = Math.min((totalBilledTime / totalSessionTime) * 100, 100);
  const sessionPercentage = (totalSessionTime / totalBilledTime) * 100;
  const remainingTime = Math.max(totalSessionTime - totalBilledTime, 0);

  return (
    <Box sx={{ width: '400px', margin: '20px 0' }}>
      {isTotalBilledTimeMoreThanSessionTime ? (
        <GradientProgressBar sessionPercentage={sessionPercentage} />
      ) : (
        <GradientProgressBar2 consumedPercentage={consumedPercentage} />
      )}
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography fontSize={11} variant="body2" sx={{ padding: '0px 5px', width: '60%' }}>
          Total Billable Minutes for all students in the session: {totalBilledTime} mins
        </Typography>
        {isTotalBilledTimeMoreThanSessionTime && (
          <Typography fontSize={11} variant="body2" color="error">
            Overage Minutes: {totalBilledTime - totalSessionTime} mins
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProgressBar;
