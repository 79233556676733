import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { CaseloadStudentDisplay, SchoolCampusRef } from "../../../../profile-sdk";
import usePalette from "../../../../hooks/usePalette";
import React, { useEffect, useState } from "react";
import useSortStudentsByName from "../../hooks/use_sort_students_by_name";
import { XNGIconRenderer, XNGICONS } from "../../../../design";
import XNGButton from "../../../../design/low-level/button";
import RemoveStudentModal from "./components/remove_student_modal";
import { MSBICONS, MSBSearch, MSBSearchMultiselect } from "../../../../fortitude";
import CreateStudentForm from "./components/create_student_form";
import { MSBSearchMultiselectProps } from "../../../../fortitude/components/search_multiselect/search_multiselect"; 
import useValidateUserPermission from "./hooks/use_permission_checker_for_student_operation";

function StudentManagerHeader(
  props: Readonly<{
    layoutMode: "grid" | "list";
    onLayoutButtonClick: () => void;
    filteredStudents: CaseloadStudentDisplay[];
    setFilteredStudents: React.Dispatch<React.SetStateAction<CaseloadStudentDisplay[]>>;
    studentCaseLoad: CaseloadStudentDisplay[];
    createAndAddStudent: Function;
    deleteStudent: Function;
    campusList: SchoolCampusRef[] | undefined;
    usesCampusRestrictions: boolean;
    searchBarState: {
      value: string;
      onChange: (v: string) => void;
    };
  }>,
) {
  const palette = usePalette();
  const [medicaidBool, setMedicaidBool] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [removeStudentModalOpen, setRemoveStudentModalOpen] = useState<boolean>(false);
  const [selectedCampusOptions, setSelectedCampusOptions] = useState<SchoolCampusRef[]>([]);
  const [selectedGradeOptions, setSelectedGradeOptions] = useState<string[]>([]);
  const {Filter} = MSBICONS;
  const sortStudentsByName = useSortStudentsByName();
  const isOperationPermitted = useValidateUserPermission("create")

  function filterStudents() {
    let test = props.studentCaseLoad;

    if (medicaidBool) {
      test = test.filter((s) => s.medicaidId);
    }
    if (selectedGradeOptions.length > 0) {
      if (!selectedGradeOptions.includes("All")) {
        test = test.filter((s) =>
          selectedGradeOptions.includes(gradeOptionValues[s.grade?.valueOf()!]),
        );
      }
    }

    // Filter `test` by checking campus names in `selectedCampusOptions` against `s.activeSchoolCampuses`
    if (selectedCampusOptions.length > 0) {
      test = test.filter(
        (student) =>
          // Include student if they have no active campuses or if there's a matching campus in the selected options
          (student.activeSchoolCampuses ?? []).length === 0 ||
          (student.activeSchoolCampuses ?? []).some((campusAssignment) =>
            selectedCampusOptions.some(
              (campusOption) => campusOption.name === campusAssignment.name,
            ),
          ),
      );
    }

    props.setFilteredStudents(test.sort(sortStudentsByName));
  }

  useEffect(() => {
    filterStudents();
  }, [selectedCampusOptions, selectedGradeOptions, medicaidBool]);

  const searchFilterGroup = (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        mb: "5px",
        justifyContent: {
          justifyContent: "space-between",
          lg: "flex-end",
        },
        width: "100%",
      }}
    >
      <MSBSearch
        value={props.searchBarState.value}
        placeholder="Search for a student"
        sx={{ backgroundColor: palette.contrasts[1] }}
        onChange={(e) => props.searchBarState.onChange(e.target.value)}
        useClear={{ onClear: () => props.searchBarState.onChange("") }}
        fullWidth
        rootBoxProps={{
          width: "100%",
          maxWidth: {
            xs: "600px",
            lg: "400px",
          },
        }}
      />

      <IconButton onClick={() => props.onLayoutButtonClick()}>
        {props.layoutMode === "grid" && (
          <XNGIconRenderer i={<XNGICONS.Grid4X4 />} size="sm" color={palette.contrasts[5]} />
        )}
        {props.layoutMode === "list" && (
          <XNGIconRenderer
            i={<XNGICONS.ThreeHorizontalLines />}
            size="sm"
            color={palette.contrasts[5]}
          />
        )}
      </IconButton>
    </Box>
  );

  const selectFilterGroup = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        flexDirection: {
          flexDirection: "column",
          sm: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          mb: "5px",
          alignItems: "center",
          width: {
            xs: "100%",
            sm: "fit-content",
          },
        }}
      >
        <Box sx={{ maxWidth: "min-content" }}>
          <XNGIconRenderer i={<Filter />} size="sm" color={palette.contrasts[5]} />
        </Box>
        <FilterDropdown
          options={props.campusList ?? []}
          selectedOptions={selectedCampusOptions}
          getOptionLabel={(v) => v.name ?? ""}
          onChange={setSelectedCampusOptions}
          label="Campuses"
          formControlProps={{
            // fullWidth: true,
            sx: {
              width: {
                xs: "100%",
                sm: "200px",
              },
            },
          }}
        />
      </Box>
      <FilterDropdown
        options={gradeOptionValues ?? []}
        selectedOptions={selectedGradeOptions}
        getOptionLabel={(v) => v ?? ""}
        onChange={setSelectedGradeOptions}
        label="Grades"
        formControlProps={{
          fullWidth: true,
          sx: {
            maxWidth: {
              xs: "100%",
              sm: "200px",
            },
          },
        }}
      />
      {/* TODO: Replace with MSBToggle once we develop one. */}
      <StudentManagerToggle
        value={medicaidBool}
        label={"Medicaid only?"}
        onChange={() => setMedicaidBool(!medicaidBool)}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "primary.main",
        padding: "10px",
        paddingBottom: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: {
            flexDirection: "column",
            sm: "row",
          },
          justifyContent: "space-between",
          paddingY: "20px",
          alignItems: "center",
        }}
      >
        <Typography color={palette.contrasts[5]} variant="h5">
          Student Manager
        </Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <XNGButton
            disabled={!isOperationPermitted}
            sx={{
              backgroundColor: "primary.2",
              ":hover": {
                backgroundColor: "primary.1",
              },
            }}
            onClick={() => {
              setCreateNew(!createNew);
            }}
          >
            Create{" "}
          </XNGButton>
          <XNGButton
            sx={{
              backgroundColor: "contrasts.main",
              color: "error.main",
              border: "2px solid",
              ":hover": {
                backgroundColor: "contrasts.1",
                color: "error.2",
              },
            }}
            onClick={() => {
              setRemoveStudentModalOpen(true);
            }}
          >
            Remove
          </XNGButton>
          <XNGButton
            sx={{
              backgroundColor: "contrasts.main",
              color: "primary.4",
              borderColor: "primary.4",
              border: "2px solid",
              ":hover": {
                backgroundColor: "contrasts.main",
                color: "primary.3",
                borderColor: "primary.3",
              },
            }}
            onClick={() => {}}
          >
            Upload
          </XNGButton>
        </Box>
        {createNew ? (
          <CreateStudentForm
            open={createNew}
            handleClose={() => setCreateNew(false)}
            createStudent={props.createAndAddStudent}
          />
        ) : null}
        <RemoveStudentModal
          open={removeStudentModalOpen}
          onClose={() => setRemoveStudentModalOpen(false)}
          studentCaseLoad={props.studentCaseLoad}
          deleteStudent={props.deleteStudent}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          flexDirection: {
            flexDirection: "column",
            lg: "row-reverse",
          },
        }}
      >
        {searchFilterGroup}
        {selectFilterGroup}
      </Box>
    </Box>
  );
}

const gradeOptionValues = [
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "Other",
];

const StudentManagerToggle = (props: {
  value: boolean;
  label: string;
  onChange: FormControlLabelProps["onChange"];
}) => {
  const palette = useTheme().palette;
  return (
    <FormControlLabel
      sx={{ m: 0, maxWidth: "min-content", whiteSpace: "nowrap", flex:1 }}
      onChange={props.onChange}
      control={<Switch size={"small"} value={props.value} />}
      label={
        <Typography
          variant="body2"
          color={palette?.contrasts?.[1]}
          className="noselect" // Prevents text highlighting when toggle is clicked twice in quick succession
        >
          {props.label}
        </Typography>
      }
    />
  );
};

/**
 * Maintains consistent visuals for local MSBSearchMultiselects.
 */
function FilterDropdown<T>(props: MSBSearchMultiselectProps<T> & {formControlProps?: FormControlProps}) {
  const { sx:formControlSx, ...otherFormControlProps } = props.formControlProps ?? {};
  return (
    <FormControl sx={{ minWidth: "220px", ...formControlSx }} {...otherFormControlProps}>
      <MSBSearchMultiselect<T>
        {...props}
        renderOptionVariant="checkbox"
        variant="no overflow"
        colorVariant="white-on-blue"
      />
    </FormControl>
  );
}



export default StudentManagerHeader;
