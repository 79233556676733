import { Activity, Modification, Accommodation, StudentResponse } from "../../../../profile-sdk";
import { useMemo } from "react";
import TabViewPortSubSection from "../../components/studentProfile/tabViewPortSubSection";
import dayjs from "dayjs";
import CustomCareProvisionsList from "./custom_care_provision_list";

export default function CustomCareProvisionsTab(
  props: Readonly<{
    setEditedStudent: React.Dispatch<React.SetStateAction<StudentResponse | null>>;
    editedStudent: StudentResponse | null;
  }>,
) {
  const { setEditedStudent, editedStudent } = props;

  const activities = editedStudent?.spedDossier?.prescribedCareProvisionsLedger?.activities;

  const accommodations = editedStudent?.spedDossier?.prescribedCareProvisionsLedger?.accommodations;

  const modifications = editedStudent?.spedDossier?.prescribedCareProvisionsLedger?.modifications;

  const defaultActivities = useMemo(
    () => activities?.map((activity) => activity.name || "") || [""],
    [activities],
  );

  const defaultAccommodations = useMemo(
    () => accommodations?.map((accommodation) => accommodation.name || "") || [""],
    [accommodations],
  );

  const defaultModifications = useMemo(
    () => modifications?.map((modification) => modification.name || "") || [""],
    [modifications],
  );

  const lastUpdatedDate = (provisions: Activity[] | Accommodation[] | Modification[]) => {
    if (provisions.length > 0) {
      // @ts-ignore
      const sortedArray = provisions.toSorted((provisionA, provisionB) => {
        // @ts-ignore
        return provisionA.startDate > provisionB.startDate ? -1 : 1;
      });
      return dayjs(sortedArray[0]?.startDate)
        .toISOString()
        .slice(0, 10);
    } else {
      return "n/a";
    }
  };

  return (
    <>
      <TabViewPortSubSection
        title={"Activities"}
        additionalText={
          "\u00A0\u00A0\u00A0\u00A0" + `Last Updated on: ${lastUpdatedDate(activities ?? [])}`
        }
      >
        <CustomCareProvisionsList
          editedStudent={editedStudent}
          setEditedStudent={setEditedStudent}
          title={"activities"}
          defaultIDs={defaultActivities}
        />
      </TabViewPortSubSection>
      <TabViewPortSubSection
        title="Accommodations"
        additionalText={
          "\u00A0\u00A0\u00A0\u00A0" + `Last Updated on: ${lastUpdatedDate(accommodations ?? [])}`
        }
      >
        <CustomCareProvisionsList
          editedStudent={editedStudent}
          setEditedStudent={setEditedStudent}
          title={"accommodations"}
          defaultIDs={defaultAccommodations}
        />
      </TabViewPortSubSection>
      <TabViewPortSubSection
        title="Modifications"
        additionalText={
          "\u00A0\u00A0\u00A0\u00A0" + `Last Updated on: ${lastUpdatedDate(modifications ?? [])}`
        }
      >
        <CustomCareProvisionsList
          editedStudent={editedStudent}
          setEditedStudent={setEditedStudent}
          title={"modifications"}
          defaultIDs={defaultModifications}
        />
      </TabViewPortSubSection>
    </>
  );
}
