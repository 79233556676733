import { useEffect, useState } from "react";
import { selectClientID } from "../../../context/slices/loggedInClientSlice";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { useXNGSelector } from "../../../context/store";
import { PrescribedCareProvisionsResponse } from "../../../profile-sdk";
import { API_STUDENTS } from "../../../api/api";
import { useNotatorTools } from "../tools";
import dayjs from "dayjs";

/**
 * Retrieves the prescribed care provisions for a student by their ID.
 */
export function useFetchedStudentPrescribedCareProvisions(
  props: Readonly<{ studentIndex: number }>,
) {
  const { draftSession } = useNotatorTools();

  const { studentIndex } = props;
  const state = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);

  const [fetchedStudentPrescribedCareProvisions, setFetchedStudentPrescribedCareProvisions] =
    useState<PrescribedCareProvisionsResponse | null>(null);

  useEffect(() => {
    (async function fetchAndSet() {
      try {
        if (!Boolean(draftSession.studentJournalList?.[studentIndex].student?.id)) return;
        /**
         * 12/21/2024
         * v1StudentsIdPrescribedCareProvisionsByDateGet was replaced with v1StudentsIdGet because it was not grabbing care provisions
         * from previous years if provided a date in a different year than the session. This can prevent custom care provisions from showing up
         * in all of the user's sessions as was requested. This is not efficient but we were short on developer resources at the time.
         */
        const student = await API_STUDENTS.v1StudentsIdGet(
          draftSession.studentJournalList![studentIndex].student?.id!,
          loggedInClientId!,
          state,
        );
        const prescribeCareProvisions = {
          activities: student.spedDossier?.prescribedCareProvisionsLedger?.activities,
          modifications: student.spedDossier?.prescribedCareProvisionsLedger?.modifications,
          accommodations: student.spedDossier?.prescribedCareProvisionsLedger?.accommodations,
        };
        setFetchedStudentPrescribedCareProvisions(prescribeCareProvisions);
      } catch (e) {
        console.error("Error while fetching student prescribed care provisions", e);
      }
    })();
  }, [studentIndex, draftSession.studentJournalList?.length]);

  return fetchedStudentPrescribedCareProvisions;
}
