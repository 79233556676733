import { useMemo } from "react";
import { MAXIMUM_ALLOWED_BILLABLE_MINUTES } from "../constants/maximum_allowed_billable_minutes";
import { PREVENT_POSTING_MODAL } from "../constants/modal_wordings";
import useSessionTime from "./useSessionTime";
import useTotalBillableTimesForAllStudentsInCurrentSession from "./useTotalBillableTimesForAllStudentsInCurrentSession";

class UseValidateTotalBillableMinutesReturnType {
    isValid: boolean;
    modalProps?: {
        title: string;
        description: string;
        confirmText: string;
    };

    constructor(isValid: boolean, modalProps?: { title: string; description: string; confirmText: string }) {
        this.isValid = isValid;
        this.modalProps = modalProps;
    }

    static of(
        isValid: boolean,
        modalProps?: { title: string; description: string; confirmText: string }
    ): UseValidateTotalBillableMinutesReturnType {
        return new UseValidateTotalBillableMinutesReturnType(isValid, modalProps);
    }
}

export const useValidateTotalBillableMinutes = (): UseValidateTotalBillableMinutesReturnType => {
    const totalBillableTimeForAllStudents = useTotalBillableTimesForAllStudentsInCurrentSession();
    const sessionTime = useSessionTime();

    return useMemo(() => {
        if (totalBillableTimeForAllStudents > MAXIMUM_ALLOWED_BILLABLE_MINUTES) {
            return UseValidateTotalBillableMinutesReturnType.of(false, {
                title: PREVENT_POSTING_MODAL.TITLES.TOTAL_BILLABLE_EXCEEDS_LIMIT,
                description: PREVENT_POSTING_MODAL.DESCRIPTIONS.TOTAL_BILLABLE_EXCEEDS_LIMIT_DESCRIPTION,
                confirmText: "Return to edit",
            });
        } else if (totalBillableTimeForAllStudents > sessionTime) {
            return UseValidateTotalBillableMinutesReturnType.of(false, {
                title: PREVENT_POSTING_MODAL.TITLES.TOTAL_BILLABLE_EXCEEDS_SESSION_TIME,
                description: PREVENT_POSTING_MODAL.DESCRIPTIONS.TOTAL_BILLABLE_EXCEEDS_SESSION_TIME_DESCRIPTION,
                confirmText: "Return to edit",
            });
        }

        return UseValidateTotalBillableMinutesReturnType.of(true);
    }, [totalBillableTimeForAllStudents, sessionTime])
}