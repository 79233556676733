import { useEffect, useMemo, useState } from "react";
import { FutureTabs } from "../../types/types";
import XNGInput from "../../../../design/low-level/input";
import { Stack } from "@mui/material";
import { useNotatorTools } from "../../tools";
import AutoBreakpointNotatorLayoutTwoColumns from "../../layouts/tab_inner_viewport_layout_two_sections";
import { produce } from "immer";
import { StagingInput } from "./components/staging_input";
import { useStagingInputDetails } from "./hooks/use_staging_details";
import CheckboxOption from "./components/checkbox_option";
import { useNotatorObservations } from "./hooks/use_notator_observations";
import useObservationTabDataOperationHandlers from "./hooks/use_observation_tab_data_operation_handlers";
import { DualActionModal } from "../../../../design";
import { MSBICONS } from "../../../../fortitude";

// This is purely a presentational, or "dumb" component. This is not to house any of its own state. It should only ever provide callbacks.
// See more:
// https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
// https://medium.com/@thejasonfile/dumb-components-and-smart-components-e7b33a698d43

export default function ObservationsTabView(
  props: Readonly<{
    applyFuture: Array<FutureTabs[]>;
    setApplyFuture: React.Dispatch<React.SetStateAction<Array<FutureTabs[]>>>;
    selectedStudentIndex?: number;
    sessionFinalized: boolean;
  }>,
) {
  const { setDraftSession, selectedStudentIndex, readOnly, draftSession } = useNotatorTools();

  const actualStudentIndex = props.selectedStudentIndex
    ? props.selectedStudentIndex
    : selectedStudentIndex;

  const [indexer, setIndexer] = useState<number>(0);

  const [studentNotes, setStudentNotes] = useState<string>(
    draftSession.studentJournalList![actualStudentIndex].observationSection?.narrative ?? " ",
  );

  // --- Modal States ---
  const [deleteCustomCareProvisionsModalState, setDeleteCustomCareProvisionsModalState] = useState({
    show: false,
    id: "",
  });

  // --- Icon Imports ---
  const { Alert } = MSBICONS;

  const areObservationOptionsReadonly = useMemo<boolean>(() => {
    return draftSession.status === 4 || draftSession.status === 5;
  }, [draftSession.status]);

  useEffect(() => {
    if (
      props.applyFuture[actualStudentIndex]?.findIndex((section) => section.section === 6) != -1 &&
      props.applyFuture[actualStudentIndex]?.[indexer]
    ) {
      setIndexer(
        props.applyFuture[actualStudentIndex].findIndex((section) => section.section === 6),
      );
    }

    // Reset student notes
    if (studentNotes !== "") {
      setStudentNotes(
        draftSession.studentJournalList![actualStudentIndex].observationSection?.narrative ?? " ",
      );
    }
  }, [actualStudentIndex]);

  const stagingInput = useStagingInputDetails({ selectedStudentIndex: actualStudentIndex });
  const observations = useNotatorObservations({ selectedStudentIndex: actualStudentIndex });
  const handlers = useObservationTabDataOperationHandlers({
    selectedStudentIndex: actualStudentIndex,
  });

  return (
    <>
      <AutoBreakpointNotatorLayoutTwoColumns
        sx={{ ...(areObservationOptionsReadonly && { pointerEvents: "none" }) }}
        left={
          <Stack sx={{ ml: ".25rem" }}>
            {observations.default.map((v, i) => (
              <CheckboxOption
                key={i}
                label={v.text}
                checked={v.checked}
                onClick={() => handlers.toggleCheckedObservation(v.text)}
                disabled={props.sessionFinalized}
              />
            ))}

            {observations.custom.map((v, i) => (
              <CheckboxOption
                key={i}
                label={v.text}
                checked={v.checked}
                onClick={() => handlers.toggleCheckedObservation(v.text)}
                useClose={{
                  onClose: () => {
                    setDeleteCustomCareProvisionsModalState({
                      show: true,
                      id: v.text,
                    });
                  },
                  tooltip: "Remove custom observation",
                }}
                disabled={props.sessionFinalized}
              />
            ))}

            <StagingInput
              value={stagingInput.value}
              onChange={stagingInput.setValue}
              onValidate={stagingInput.handleValidate}
              onConfirm={stagingInput.handleConfirm}
              disabled={props.sessionFinalized}
            />
          </Stack>
        }
        leftConfig={{ title: "Student Observations" }}
        right={
          <XNGInput
            multiline
            row={12}
            defaultValue={studentNotes}
            value={studentNotes}
            onChange={(e) => {
              setStudentNotes(e.target.value);
            }}
            onBlur={() =>
              setDraftSession(
                produce((draft) => {
                  draft.studentJournalList![actualStudentIndex].observationSection!.narrative =
                    studentNotes;
                }),
              )
            }
            disabled={readOnly || props.sessionFinalized}
          />
        }
        rightConfig={{ title: "Student Notes" }}
      />
      <DualActionModal
        injectContent={{
          icon: <Alert />,
          header: `Delete Observation?`,
          body: `This Observation will be deleted from all students in this session, would you like to proceed?`,
        }}
        open={deleteCustomCareProvisionsModalState.show}
        onClose={() =>
          setDeleteCustomCareProvisionsModalState({
            show: false,
            id: "",
          })
        }
        onConfirm={() => {
          handlers.deleteCustomObservation(deleteCustomCareProvisionsModalState.id);
          setDeleteCustomCareProvisionsModalState({
            show: false,
            id: "",
          });
        }}
        onReject={() => {
          setDeleteCustomCareProvisionsModalState({
            show: false,
            id: "",
          });
        }}
      />
    </>
  );
}

// TODO: Reinstate this later. We need to make sure the accurate but slower code works properly before adding this optimization in.
// How to add: Just replace `<CheckboxOption` with `<OptimisticCheckbox` on lines 66, 75. Easy!
// function OptimisticCheckbox(props: Readonly<CheckboxOptionProps>) {
//   const { selectedStudentIndex } = useNotatorTools();
//   const [checked, setChecked] = useState<boolean>(props.checked);

//   useEffect(() => setChecked(props.checked), [selectedStudentIndex]);

//   function handleToggleOptimistically() {
//     setChecked(!checked);
//     setTimeout(() => {
//       props.onClick();
//     }, 0);
//   }

//   return <CheckboxOption {...props} checked={checked} onClick={handleToggleOptimistically} />;
// }
