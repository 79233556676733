import { API_CLIENT_SESSION_COUNTS } from "../../../../../../api/api";
import { V1SessionReportsClientSessionCountsGetReportPostRequest } from "@xng/reporting";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";
import useApiMutatePollData from "../../../../../../api/hooks/use_api_mutate_poll_data";
import { MSBInferPollingApiResponse } from "../../../../../../types/msb_infer_polling_api_response";

type Data = MSBInferPollingApiResponse<
  typeof API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsGetReportPostRaw
>;
type Body = V1SessionReportsClientSessionCountsGetReportPostRequest; // generic parameter B is used to define body the type of the useApiMutateData hook

export const useApiMutatePollUnpostedSessionsReport = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  return useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsGetReportPostRaw(body),
    mutationKey: ["v1SessionReportsClientSessionCountsGetReportPostRaw"],
    ...(paramObject?.options || {}),
  });
};

export type { Data as UnpostedSessionsReportData, Body as UnpostedSessionsReportBody };
