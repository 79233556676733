import { useEffect, useRef } from 'react'

export default function useStudentAddedSideEffectHandler(props: {
    onStudentAddedSideEffect?: () => void
    totalStudentsInCaseload: number
}) {
    const filteredStudentCountRef = useRef(props.totalStudentsInCaseload);

    return useEffect(() => {
        if (filteredStudentCountRef.current < props.totalStudentsInCaseload) {
            props.onStudentAddedSideEffect && props.onStudentAddedSideEffect();
        }
        filteredStudentCountRef.current = props.totalStudentsInCaseload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.totalStudentsInCaseload]);

}
