import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { UnpostedSessionsReportData } from "./api/use_api_mutate_poll_unposted_sessions_report";
import GetDocTypesArrayOptions from "../../../../../data/get_doc_types_array_options";

type GridRowsColumnDefType = GridColDef<
  NonNullable<UnpostedSessionsReportData["pageRecords"]>[number]
>;

export default function useSessionLogsReportsTableColumnDefinition() {
  const columnDefDefaultProps: Partial<GridRowsColumnDefType> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    sortable: false,
    valueGetter(params) {
      return params.value || emptyCellValue;
    },
  };

  const emptyCellValue = "N/A";

  const sessionLogsReportTablecolumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "serviceProviderFirstName",
      headerName: "SP First Name",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderLastName",
      headerName: "SP Last Name",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderEmail",
      headerName: "Email",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderType",
      headerName: "Service Provider Type",
      minWidth: 180,
    },
    {
      ...columnDefDefaultProps,
      field: "campusName",
      headerName: "Campus Name",
    },
    {
      ...columnDefDefaultProps,
      field: "unpostedUnsubmittedSessionCount",
      headerName: "Unposted",
      valueGetter(params) {
        return params.value || 0;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "submittedSessionCount",
      headerName: "Submitted",
      valueGetter(params) {
        return params.value || 0;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "postedSessionCount",
      headerName: "Posted",
      valueGetter(params) {
        return params.value || 0;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "oldestSessionDate",
      headerName: "Oldest Session",
      // width: 300,
      valueGetter(params) {
        return params.value ? dayjs(params.value).format("MM/DD/YYYY") : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "documentationType",
      headerName: "Doc Type",
      renderCell(params) {
        const index = params.value;
        return index ? GetDocTypesArrayOptions()[index] : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "teamLeaders",
      headerName: "Team Leaders",
    },
  ];

  return sessionLogsReportTablecolumns;
}
