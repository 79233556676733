/**
 * Custom hook to get the service provider options based on user roles.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.clientId - The client ID.
 * @param {string} props.stateInUs - The state in the US.
 * @param {ServiceProviderRef} props.serviceProviderProfileInfo - The service provider profile information.
 * @param {ReturnType<typeof useUserRoles>} props.userRoles - The user roles.
 * @param {Object} props.loggedInClientAssignmentProviders - The logged-in client assignment providers.
 * @param {ServiceProviderRef[]} props.loggedInClientAssignmentProviders.supervisedServiceProviders - The supervised service providers.
 * @param {ServiceProviderRef[]} props.loggedInClientAssignmentProviders.appointingServiceProviders - The appointing service providers.
 *
 * @returns {Object} The service provider options filtered by user roles and other API query client properties.
 */
import { useMemo } from "react";
import useApiQueryServiceProviders from "../../../../../api/hooks/service_provider/use_api_query_service_providers";
import useUserRoles from "../../../../../hooks/use_user_roles";
import { ServiceProviderRef } from "../../../../../profile-sdk";


type Props = {
  clientId: string;
  stateInUs: string;
  serviceProviderProfileInfo: ServiceProviderRef;
  userRoles: ReturnType<typeof useUserRoles>;
  loggedInClientAssignmentProviders: {
    supervisedServiceProviders: ServiceProviderRef[];
    appointingServiceProviders: ServiceProviderRef[];
  };
};

export default function useQueryAndFilterServiceProviderOptionsByUserRoles(props: Props) {
  const isAdmin = props.userRoles.includes("Executive Admin") || props.userRoles.includes("Delegated Admin");
  // Query service providers via API
  const { data: serviceProvidersResponse, ...serviceProviderApiQueryClientProps } =
    useApiQueryServiceProviders({
      queryParams: {
        clientId: props.clientId,
        state: props.stateInUs,
      },
      options: {
        enabled: isAdmin,
      }
    });


  // filter service providers response based on user roles
  const serviceProviderOptionsByUserRole: ServiceProviderRef[] = useMemo(() => {
    

    // Admin: sees all service providers
    if (isAdmin) {
      return [...(serviceProvidersResponse?.serviceProviders ?? [])];
    }

    // Approver: Your sessions + People in your approver's caseload
    if (props.userRoles.includes("Approver")) {
      return [
        props.serviceProviderProfileInfo,
        ...(props.loggedInClientAssignmentProviders?.supervisedServiceProviders ?? [])]
    }

    // Proxy Data Entry: Your sessions + People in your appointing SP's caseload
    if (props.userRoles.includes("Proxy Data Entry")) {
      return [
        props.serviceProviderProfileInfo,
        ...(props.loggedInClientAssignmentProviders?.appointingServiceProviders ?? [])]
    }

    // Autonomous SP: Only see your sessions
    // Assistant SP: Only see your sessions
    if (
      props.userRoles.includes("Service Provider - Autonomous") ||
      props.userRoles.includes("Service Provider - Assistant")
    ) {
      return [props.serviceProviderProfileInfo];
    }

    return [];
  }, [
    props.loggedInClientAssignmentProviders?.appointingServiceProviders,
    props.loggedInClientAssignmentProviders?.supervisedServiceProviders,
    props.serviceProviderProfileInfo,
    props.userRoles,
    serviceProvidersResponse?.serviceProviders,
    isAdmin
  ]);

  // since we don't make a request to the API, we need to override the client props as if the request was successful, we use these in the form to implement error handling for the service provider options
  const clientPropsOverride: Partial<typeof serviceProviderApiQueryClientProps >= {
    status: "success",
    isError: false,
    isLoading: false,
    isPending: false,
    isSuccess: true,
  }

  return { data: serviceProviderOptionsByUserRole, ...serviceProviderApiQueryClientProps, ...(!isAdmin ? clientPropsOverride : {}) };
}
