import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import GridSectionLayout from "../../../../../../design/high-level/common/grid_section_layout";
import { useEffect } from "react";
import {
  DistrictRef,
  SchoolCampusRef,
  Service,
  ServiceProviderRef,
} from "../../../../../../profile-sdk";
import { Stack } from "@mui/system";
import produce from "immer";
import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../../../utils/msb_mui_auto_complete_filter_options";
import { MSBQueryResultType } from "../../../../../../api/hooks/use_api_query_data";
import { DatePicker } from "@mui/x-date-pickers";
import {
  generateWeeksFromYearRange,
  sessionDateFilterOptions,
  sessionReportStatusOptions,
} from "../../utils";
import { sessionLogsFormSchema, SessionLogsFormType } from "../../constants";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export type SessionLogsFormProps = {
  isAdmin: boolean;
  inputComponentProps: {
    serviceProviders: InputComponentOptionsProps<ServiceProviderRef>;
    districtsOfLiability: {
      options: DistrictRef[];
    };
    campuses: InputComponentOptionsProps<SchoolCampusRef>;
    serviceTypes: InputComponentOptionsProps<Service>;
  };
  onSubmitFormFilters: (data: SessionLogsFormType) => void;
};

export const SessionLogsForm = (props: SessionLogsFormProps) => {
  //#region HOOKFORMS
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getFieldState,
    formState: { isValid },
  } = useForm<SessionLogsFormType>({
    resolver: yupResolver(sessionLogsFormSchema),
    defaultValues: {
      sessionDateFilter: "Date Range",
      sessionFilter: [],
      showIepServiceData: false,
      studentWithSession: false,
      medicalEligibleOnly: false,
      serviceProviders: props.inputComponentProps.serviceProviders.options,
      dols: props.inputComponentProps.districtsOfLiability.options,
      // serviceTypes: [],
      // schools: [],
    },
  });

  const selectedSessionDateFilter = watch("sessionDateFilter");
  const startYear = watch("dateFilterOptions.startYear");
  const endYear = watch("dateFilterOptions.endYear");
  //#endregion

  //#region METHODS
  const onSubmitFilters = (data: SessionLogsFormType) => {
    const {
      inputComponentProps: { campuses, districtsOfLiability, serviceProviders, serviceTypes },
    } = props;

    // modify form data to convert properties with all options selected to undefined
    const processedFormData = produce(data, (draft) => {
      draft.schools = draft.schools?.length === campuses.options.length ? undefined : draft.schools;
      draft.dols =
        draft.dols?.length === districtsOfLiability.options.length ? undefined : draft.dols;
      draft.serviceProviders =
        draft.serviceProviders?.length === serviceProviders.options.length && (props.isAdmin)
          ? undefined
          : draft.serviceProviders;
      draft.serviceTypes =
        draft.serviceTypes?.length === serviceTypes.options.length ? undefined : draft.serviceTypes;
    });
    props.onSubmitFormFilters(processedFormData);
  };
  //#endregion

  const yesNoBothOptions = ["Both", "Yes", "No"];
  //#region SECTIONS
  const sessionFiltersSection = (
    <GridSectionLayout
      headerConfig={{
        title: "Session Filter(s)",
        title_sx: {
          fontWeight: 700,
        },
      }}
      divider
      bottomMargin={"2rem"}
      rows={[
        {
          rowSx: {
            pb: 3,
          },
          cellSizes: {
            xs: 12,
            lg: 6,
            sm: 6,
          },
          cells: [
            <Typography key={"1"}>
              Please refine results starting with the "Session Date Filter."
            </Typography>,
          ],
        },
        {
          rowSx: {
            alignItems: "stretch",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              py: "18px",
              px: "20px",
              display: "flex",
              alignItems: "center",
            },
          },
          cells: [
            {
              content: (
                <Controller
                  key={"sessionDateFilter"}
                  control={control}
                  name="sessionDateFilter"
                  render={({ field, fieldState: { error } }) => {
                    const inputId = "sessionDateFilter";
                    return (
                      <FormControl fullWidth>
                        <InputLabel id={inputId} size="small">
                          Session Date Filter
                        </InputLabel>
                        <Select
                          labelId={inputId}
                          size="small"
                          fullWidth
                          id="session-date-filter-select"
                          value={field.value ?? ""}
                          label="Session Date Filter"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        >
                          {sessionDateFilterOptions.map((option, i) => (
                            <MenuItem key={i + i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              ),
            },
            {
              sx: {
                bgcolor: "contrasts.1",
              },
              content: (
                <Controller
                  key={"sessionFilter"}
                  control={control}
                  name="sessionFilter"
                  render={({ field, fieldState: { error } }) => (
                    <Box width={"100%"}>
                      <MSBSearchMultiselect
                        // data-testid="session-filter"
                        selectedOptions={field.value ?? []}
                        options={sessionReportStatusOptions}
                        getOptionLabel={(option) => option ?? ""}
                        onChange={(so) => field.onChange(so)}
                        renderOptionVariant="checkbox"
                        variant="no overflow after 1"
                        isOptionEqualToValue={(option, value) => option === value}
                        label="Session Filter"
                        sx={{ width: "100%", backgroundColor: "white" }}
                        autocompleteProps={{
                          disableCloseOnSelect: true,
                        }}
                        textFieldProps={{
                          helperText: error?.message,
                          color: "error"
                        }}
                      />
                    </Box>
                  )}
                />
              ),
            },
          ],
        },
        {
          useCellStyling: {
            sx: {
              py: "18px",
              px: "20px",
              // display: "flex",
              // alignItems: "center",
            },
          },
          cellSizes: {
            xs: 12,
            sm: 6,
            lg: 4,
          },
          cells: [
            <Box
              key={0}
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
              }}
            >
              {selectedSessionDateFilter === sessionDateFilterOptions[0] && (
                <>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Controller
                      control={control}
                      name="dateFilterOptions.startYear"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <DatePicker
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                              },
                            }}
                            disableFuture
                            label="Start Year"
                            views={["year"]}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date?.format("YYYY"));
                            }}
                          />
                        );
                      }}
                    />
                    -
                    <Controller
                      control={control}
                      name="dateFilterOptions.endYear"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <DatePicker
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                              },
                            }}
                            disableFuture
                            label="End Year"
                            views={["year"]}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date?.format("YYYY"));
                            }}
                          />
                        );
                      }}
                    />
                  </Stack>
                  {startYear && endYear && (
                    <Controller
                      control={control}
                      name="dateFilterOptions.week"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormControl fullWidth>
                            <InputLabel id="week" size="small">
                              Week
                            </InputLabel>
                            <Select
                              labelId="week"
                              size="small"
                              fullWidth
                              id="week-select"
                              value={JSON.stringify(field.value) || ""}
                              label="Week"
                              onChange={(e) => {
                                field.onChange(JSON.parse(e.target.value));
                              }}
                            >
                              {startYear && endYear
                                ? generateWeeksFromYearRange(
                                    Number(startYear),
                                    Number(endYear),
                                  ).map((week, i) => (
                                    <MenuItem key={i + i} value={JSON.stringify(week)}>
                                      <Typography fontSize={"12.5px"}>
                                        <Box component={"span"} fontSize={"13px"} fontWeight={700}>
                                          Week {week.weekNumber}:
                                        </Box>{" "}
                                        {dayjs(week.start).format("MM-DD-YYYY")} to{" "}
                                        {dayjs(week.end)?.format("MM-DD-YYYY")}{" "}
                                      </Typography>
                                    </MenuItem>
                                  ))
                                : []}
                            </Select>
                            <FormHelperText error={!!error?.message}>
                              {error?.message}
                            </FormHelperText>
                          </FormControl>
                        );
                      }}
                    />
                  )}
                </>
              )}

              {selectedSessionDateFilter === sessionDateFilterOptions[1] && (
                <Controller
                  key={"startDate"}
                  control={control}
                  name="dateFilterOptions.startDate"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        disableFuture
                        label="Start Date"
                        value={field.value ? dayjs(field.value).utc() : null}
                        onChange={(date) => {
                          field.onChange(date?.format("MM/DD/YYYY"));
                        }}
                      />
                    );
                  }}
                />
              )}
              {selectedSessionDateFilter === sessionDateFilterOptions[1] && (
                <Controller
                  control={control}
                  name="dateFilterOptions.endDate"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        disableFuture
                        label="End Date"
                        value={field.value ? dayjs(field.value).utc() : null}
                        onChange={(date) => {
                          field.onChange(date?.format("MM/DD/YYYY"));
                        }}
                      />
                    );
                  }}
                />
              )}
            </Box>,
          ],
        },
      ]}
    />
  );

  const additionalFiltersSection = (
    <GridSectionLayout
      headerConfig={{
        title: "Additional Filter(s)",
        title_sx: {
          fontWeight: 700,
        },
      }}
      rows={[
        {
          rowSx: {
            alignItems: "stretch",
            mb: "52px",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "20px",
              display: "flex",
              alignItems: "center",
            },
          },
          cells: [
            <Controller
              key={0}
              control={control}
              name={"serviceProviders"}
              render={({ field, fieldState: { error } }) => {
                // render service providers input field and display component states based on form state or API status
                const { options, optionsApiStatus, refetchOptions } =
                  props.inputComponentProps.serviceProviders;
                const isApiError = optionsApiStatus === "error";
                const errorText =
                  optionsApiStatus === "error"
                    ? "Error loading service providers, please click refresh button to try again"
                    : error?.message ?? "";
                const label = optionsApiStatus === "pending" ? "Loading..." : "Service Provider(s)";
                return (
                  <MSBInputErrorWrapper
                    errorText={errorText}
                    isError={isApiError || !!error}
                    refetch={refetchOptions}
                    displayRefreshButton={isApiError}
                    rootProps={{
                      width: "100%",
                    }}
                  >
                    <MSBSearchMultiselect
                      selectedOptions={field.value ?? []}
                      options={options}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      onChange={(so) => field.onChange(so)}
                      renderOptionVariant="checkbox"
                      variant="no overflow after 1"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      label={label}
                      sx={{ width: "100%", backgroundColor: "white" }}
                      autocompleteProps={{
                        disabled: options.length <= 1,
                        disableCloseOnSelect: true,
                        filterOptions: msbMUIAutoCompleteFilterOptions(),
                      }}
                    />
                  </MSBInputErrorWrapper>
                );
              }}
            />,
            <Controller
              key={1}
              control={control}
              name={"dols"}
              render={({ field, fieldState:{error} }) => {
                const { options } = props.inputComponentProps.districtsOfLiability;
                return (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={options}
                    getOptionLabel={(option) => option?.name ?? ""}
                    onChange={(so) => field.onChange(so)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="District Of Liability"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disabled: options?.length <= 1,
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                    textFieldProps={{
                      helperText: error?.message,
                      color: "error"
                    }}
                  />
                );
              }}
            />,
            <Controller
              key={2}
              control={control}
              name={"schools"}
              render={({ field, fieldState: { error } }) => {
                // render campuses input field and display component states based on form state or API status
                const { options, optionsApiStatus, refetchOptions } =
                  props.inputComponentProps.campuses;
                const isApiError = optionsApiStatus === "error";
                const errorText =
                  optionsApiStatus === "error"
                    ? "Error loading campuses, please click refresh button to try again"
                    : error?.message ?? "";
                const label = optionsApiStatus === "pending" ? "Loading..." : "School";
                return (
                  <MSBInputErrorWrapper
                    errorText={errorText}
                    isError={isApiError || !!error}
                    refetch={refetchOptions}
                    displayRefreshButton={isApiError}
                    rootProps={{
                      width: "100%",
                    }}
                  >
                    <MSBSearchMultiselect
                      selectedOptions={field.value ?? []}
                      options={options}
                      getOptionLabel={(option) => option?.name ?? ""}
                      onChange={(so) => field.onChange(so)}
                      renderOptionVariant="checkbox"
                      variant="no overflow after 1"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      label={label}
                      sx={{ width: "100%", backgroundColor: "white" }}
                      autocompleteProps={{
                        disableCloseOnSelect: true,
                        filterOptions: msbMUIAutoCompleteFilterOptions(),
                      }}
                    />
                  </MSBInputErrorWrapper>
                );
              }}
            />,
          ],
        },
        {
          rowSx: {
            alignItems: "stretch",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              display: "flex",
              alignItems: "center",
              px: "20px",
            },
          },
          cells: [
            <Controller
              key={0}
              control={control}
              name={"serviceTypes"}
              render={({ field, fieldState: { error } }) => {
                // render service types input field and display component states based on form state or API status
                const { options, optionsApiStatus, refetchOptions } =
                  props.inputComponentProps.serviceTypes;
                const isApiError = optionsApiStatus === "error";
                const errorText =
                  optionsApiStatus === "error"
                    ? "Error loading service types, please click refresh button to try again"
                    : error?.message ?? "";
                const label = optionsApiStatus === "pending" ? "Loading..." : "Service Type(s)";
                return (
                  <MSBInputErrorWrapper
                    errorText={errorText}
                    isError={isApiError || !!error}
                    refetch={refetchOptions}
                    displayRefreshButton={isApiError}
                    rootProps={{
                      width: "100%",
                    }}
                  >
                    <MSBSearchMultiselect
                      selectedOptions={field.value ?? []}
                      options={options}
                      getOptionLabel={(option) => option?.name ?? ""}
                      onChange={(so) => field.onChange(so)}
                      renderOptionVariant="checkbox"
                      variant="no overflow after 1"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      label={label}
                      sx={{ width: "100%", backgroundColor: "white" }}
                      autocompleteProps={{
                        disableCloseOnSelect: true,
                        filterOptions: msbMUIAutoCompleteFilterOptions(),
                      }}
                    />
                  </MSBInputErrorWrapper>
                );
              }}
            />,
            <Controller
              key={1}
              control={control}
              name="providerAbsent"
              render={({ field, fieldState: { error } }) => {
                const inputId = "provider-absent";
                return (
                  <FormControl fullWidth>
                    <InputLabel id={inputId} size="small">
                      Provider Absent
                    </InputLabel>
                    <Select
                      labelId={inputId}
                      size="small"
                      fullWidth
                      id="provider-absent-select"
                      value={field.value ?? ""}
                      label="Provider Absent"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      renderValue={(selected) => (
                        <Chip
                          key={selected}
                          label={selected}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            field.onChange("");
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      )}
                      sx={{ backgroundColor: "white" }}
                    >
                      {yesNoBothOptions.map((option, i) => (
                        <MenuItem
                          key={i + i}
                          value={option}
                          aria-label={option + " provider absent"}
                        >
                          <Radio checked={field.value === option} size="small" />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />,
            <Controller
              key={2}
              control={control}
              name="makeUpSession"
              render={({ field, fieldState: { error } }) => {
                const inputId = "make-up-session";
                return (
                  <FormControl fullWidth>
                    <InputLabel id={inputId} size="small">
                      Make Up Session
                    </InputLabel>
                    <Select
                      labelId={inputId}
                      size="small"
                      fullWidth
                      value={field.value ?? ""}
                      label="Make Up Session"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      renderValue={(selected) => (
                        <Chip
                          key={selected}
                          label={selected}
                          size="small"
                          onDelete={(e) => {
                            e.stopPropagation();
                            field.onChange("");
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      )}
                      sx={{ backgroundColor: "white" }}
                    >
                      {yesNoBothOptions.map((option, i) => (
                        <MenuItem
                          key={i + i}
                          value={option}
                          aria-label={option + " make up session"}
                        >
                          <Radio checked={field.value === option} size="small" />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />,
          ],
        },
        {
          rowSx: {
            mt: "3rem",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          cells: [
            <></>,
            <></>,
            <Box key={0} display={"flex"} justifyContent={"flex-end"}>
              <Button
                disabled={!isValid}
                sx={{
                  width: "178px",
                  px: "2rem",
                  py: "1.5rem",
                }}
                type="submit"
              >
                Apply Filter(s)
              </Button>
            </Box>,
          ],
        },
      ]}
    />
  );

  //#endregion
  //#endregion

  // #region SIDE EFFECTS
  // populate service providers input field with options from API, form was loaded before options were available
  useEffect(() => {
    if (
      props.inputComponentProps.serviceProviders.optionsApiStatus === "success" &&
      !getFieldState("serviceProviders").isDirty
    ) {
      setValue("serviceProviders", props.inputComponentProps.serviceProviders.options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputComponentProps.serviceProviders.optionsApiStatus]);

  useEffect(() => {
    if (props.inputComponentProps.districtsOfLiability.options && !getFieldState("dols").isDirty) {
      setValue("dols", props.inputComponentProps.districtsOfLiability.options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputComponentProps.districtsOfLiability.options]);
  // #endregion

  return (
    <Box
      sx={{
        overflowY: "auto",
      }}
    >
      <form onSubmit={handleSubmit(onSubmitFilters)}>
        <Box mb={"3rem"}>
          {sessionFiltersSection}
          {additionalFiltersSection}
        </Box>
      </form>
    </Box>
  );
};

type InputComponentOptionsProps<T> = {
  options: T[];
  optionsApiStatus: MSBQueryResultType["status"];
  refetchOptions: MSBQueryResultType["refetch"];
};
