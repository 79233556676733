import { useEffect, useMemo, useState } from 'react';
import { useXNGSelector } from '../../../../../context/store';
import { selectStateInUS } from '../../../../../context/slices/stateInUsSlice';
import useUserRoles from '../../../../../hooks/use_user_roles';
import { XLogsRoleStrings } from '../../../../../context/types/xlogsrole';

type OperationOnStudent = "create" | "update" | "upload";

const useValidateUserPermission = (operationOnStudent: OperationOnStudent): boolean => {
  const [isPermitted, setIsPermitted] = useState(true);
  const userRoles = useUserRoles();

  // Hide this button for the following roles:
  const restrictedRoles:XLogsRoleStrings[] = useMemo(
    () => ["Approver", "Proxy Data Entry", "Service Provider - Autonomous", "Service Provider - Assistant"],
    []
  );

  const state = useXNGSelector(selectStateInUS);
  const isNH = state === "NH";

  // Check if the user has any restricted role
  const hasRestrictedRole = useMemo(
    () => userRoles.some((role) => restrictedRoles.includes(role)) && operationOnStudent === "create",
    [userRoles, restrictedRoles,operationOnStudent]
  );

  useEffect(() => {
    // Permission logic: If in NH and user has a restricted role, they are not permitted
    setIsPermitted(!(isNH && hasRestrictedRole));
  }, [isNH, hasRestrictedRole]);

  return isPermitted;
};

export default useValidateUserPermission;
