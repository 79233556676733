import { Box, ListItem, Typography } from "@mui/material";

type Props = {
  title: string;
  children: React.ReactNode;
  additionalText?: string;
};

/**
 * TODO: Move into a new `views/profile/components` directory.
 */
const TabViewPortSubSection = (props: Props) => {
  return (
    <ListItem
      divider
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",

        py: "2rem",
      }}
    >
      <Box width={"100%"} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" mb={2}>
          {props.title}
        </Typography>
        {props.additionalText ? (
          <Typography variant="body1" mb={2}>
            {props.additionalText}
          </Typography>
        ) : null}
      </Box>
      <Box width={"100%"}>{props.children}</Box>
    </ListItem>
  );
};

export default TabViewPortSubSection;
