import { Box, Stack, Typography } from "@mui/material";
import MSBPieChart from "../../amcharts/pie_chart";

function PieChartDemo() {
  return (
    <Stack gap="2rem">
      <Typography>Our standard component for creating pie charts.</Typography>

      <Stack>
        <Typography variant="h6">Required Prop</Typography>
        <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <li>
            <code>data: PieChartValue[]</code>{" "}
            <Typography display="inline">Required to render the slices of our pie chart</Typography>
          </li>
        </Box>
        <Typography variant="h6">Optional Props</Typography>
        <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <li>
            <code>innerRadius</code>{" "}
            <Typography display="inline">
              Specifies an optional cutout, allowing pie charts to become donut charts
            </Typography>
          </li>
        </Box>
      </Stack>

      <Stack gap="1rem">
        <Typography variant="h6">Classroom's Favorite Ice Cream Flavors</Typography>
        <MSBPieChart
          data={[
            { category: "Vanilla", value: 0.4 },
            { category: "Chocolate", value: 0.3 },
            { category: "Strawberry", value: 0.2 },
            { category: "Mint", value: 0.1 },
          ]}
          innerRadius={90}
        />
      </Stack>
    </Stack>
  );
}

export default PieChartDemo;
