import { object, array, string, date, boolean, InferType } from "yup";

export const unpostedSessionsReportsFormSchema = object().shape({
  schoolCampuses: array().of(
    object().shape({
      id: string(),
      name: string(),
    }),
  ),
  startDate: date()
    .nullable()
    .test({
      test: function (value) {
        if (value && this.parent.endDate) {
          return value <= this.parent.endDate;
        }
        return true;
      },
      message: "Start date must be before or equal to end date",
    })
    .test({
      test: function (value) {
        return !!value;
      },
      message: "Start date is required",
    }),
  endDate: date()
    .nullable()
    .test({
      test: function (value) {
        if (value && this.parent.startDate) {
          return value >= this.parent.startDate;
        }
        return true;
      },
      message: "End date must be after or equal to start date",
    })
    .test({
      test: function (value) {
        return !!value;
      },
      message: "End date is required",
    }),
  includeProvidersWithoutCampuses: boolean(),
});

export type UnpostedSessionReportsFormSchema = InferType<
  typeof unpostedSessionsReportsFormSchema
>;
