import { Box, Divider, Typography, useTheme } from "@mui/material";
import { StudentRef } from "../../../../../profile-sdk";
import SearchCard from "./search_card";
import { StudentManagerSearchResultsProps } from "../types/student_manager_search_results_props";
import { StudentManagerGreySectionWrapper } from "./student_manager_grey_section_wrapper";

function SearchCardResults(props: Readonly<StudentManagerSearchResultsProps>) {
  const { searchedStudents, onCardClick, filteredStudents, onCardClickSideEffect } = props;

  const { palette } = useTheme();

  return (
    <>
      <StudentManagerGreySectionWrapper>
        <Box sx={{ display: "flex", alignItems: "center", gap: ".25rem" }}>
          <Typography variant="h6">Search Results</Typography>
          <Typography variant="body1" color={palette.grey[700]}>
            ({searchedStudents.length})
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "1rem", overflowX: "auto", p: "1rem" }}>
          {searchedStudents.map((s: StudentRef, i) => {
            return (
              <SearchCard
                onClick={onCardClick}
                onClickSideEffect={onCardClickSideEffect}
                s={s}
                animIndex={i}
                key={i}
                filteredStudents={filteredStudents}
              />
            );
          })}
        </Box>
      </StudentManagerGreySectionWrapper>

      <Divider />  
    </>
  );
}

export default SearchCardResults;
