export const TOTAL_BILLABLE_EXCEEDS_SESSION_TIME = "Total billable exceeds session limit";
export const TOTAL_BILLABLE_EXCEEDS_LIMIT = "Total billable exceeds series limit";
export const TOTAL_BILLABLE_EXCEEDS_SESSION_TIME_DESCRIPTION = `You are about to post a session with more 
billable minutes than there are minutes in the session. Please return to your session to evaluate
 and edit your total billable minutes.`
export const TOTAL_BILLABLE_EXCEEDS_LIMIT_DESCRIPTION = `You cannot post this session as the total billable
 minutes exceeds the limit of the session series.(500 minutes)`
export const PREVENT_POSTING_MODAL = {
    TITLES : {
        TOTAL_BILLABLE_EXCEEDS_SESSION_TIME,
        TOTAL_BILLABLE_EXCEEDS_LIMIT
    },
    DESCRIPTIONS : {
        TOTAL_BILLABLE_EXCEEDS_LIMIT_DESCRIPTION,
        TOTAL_BILLABLE_EXCEEDS_SESSION_TIME_DESCRIPTION
    }
}