import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material' 
import { MSBIconRenderer } from '../../../fortitude';

interface Props {
    open: boolean;
    onClose: () => void;
    content:string;
    buttonText:string;
    modalTitle:string;
    color:"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    icon?:JSX.Element

}

const ReusableModal:React.FC<Props> = (props) => {
   
  return (
    <Dialog open={props.open} closeAfterTransition onClose={() => props.onClose()} maxWidth="xs" fullWidth>  
     {!!props?.icon && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center",padding: "10px 16px 10px 16px"}}>
       <MSBIconRenderer i={props?.icon} size={2} />
    </Box>}
    <DialogTitle color={props.color}>{props.modalTitle}</DialogTitle> 
    <DialogContent sx={{ overflow: "visible!important" }}>
            {props.content}
    </DialogContent>
    <DialogActions sx={{ justifyContent: "center" }}>
      <Button onClick={() => props.onClose()} color={props.color}>
          {props.buttonText}
      </Button>
    </DialogActions>
  </Dialog>
   
  )
}

export default ReusableModal