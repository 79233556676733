import useApiMutateData from "../../../../../../api/hooks/use_api_mutate_data";
import { API_CLIENT_SESSION_COUNTS } from "../../../../../../api/api";
import { V1SessionReportsClientSessionCountsQueueReportPostRequest } from "@xng/reporting";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";

type Data = Awaited<
  ReturnType<typeof API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsQueueReportPost>
>; // generic parameter T is used to define data the type of the useApiMutateData hook
type Body = V1SessionReportsClientSessionCountsQueueReportPostRequest | undefined; // generic parameter B is used to define body the type of the useApiMutateData hook

export const useApiMutateQueueUnpostedSessionsReport = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  return useApiMutateData({
    mutationFn: async (body: Body) =>
      await API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsQueueReportPost(body),
    mutationKey: ["v1SessionReportsClientSessionCountsQueueReportPost"],
    ...(paramObject?.options ?? {}),
  });
};
