import { BoxProps, Checkbox, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

export function SelectableRow(props: {
  readonly checked: boolean;
  readonly title: string;
  readonly boxProps?: BoxProps;
  readonly toolTip?: ReactNode;
}) {
  const { palette } = useTheme();
  return (
    <Box
      component="li"
      {...props.boxProps}
      sx={{
        bgcolor: palette.common.white,
        cursor: "pointer",
        p: "0rem .5rem !important",
      }}
    >
      <Checkbox
        data-testid={props.title}
        checked={props.checked}
        inputProps={{
          "aria-label": props.title,
        }}
      />
      {props?.toolTip ? (
        <Tooltip title={props.toolTip} placement="right" arrow>
          <li>{props.title}</li>
        </Tooltip>
      ) : (
        props?.title
      )}
    </Box>
  );
}
