import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GREY_COLOR } from '../../../../../design/high-level/xngbigtable/constants/grey_color';
import ProgressReportingStandardBtn from './progress_reporting_standard_btn';
import { StudentFitlerTableTitle } from '../utils/progress_report_fitler_edit_data_grid_helper';
import { XNGRadio } from '../../../../../design';
import { FormControlLabel, Input, RadioGroup, Stack, TextareaAutosize, Typography } from '@mui/material';
import useProgressReporting from '../context/hooks/useProgressReporting';
import { ProgressReportingActionType } from '../context/progress_reporting_actions';
import { ReportStatus, ReportTypes } from '../context/reducer/progress_reporting_reducer';
import { getFormattedDateTime } from '../../../../admin/views/district_profile/utils/date_utils';
import { GeneralCommentsPayloadManager, GoalObjectiveStatusPayloadManager, IsMakingAdequateProgressPayloadManager, ProgressMeasuredPayloadManager } from '../context/payload.manager';
import IfElseBox from '../../../../../design/components-dev/if_else_box';
import { ExtendedStudentProgressDetailsWithAggregatedProgress } from '../types/extended_progress_repoting_types';
import { getProgress } from '../utils/progress_helper';
import { useXNGDispatch } from '../../../../../context/store';
import useApiMutateData from '../../../../../api/hooks/use_api_mutate_data';
import { StudentProgressReportRecordUIPaginatedReportResponse, StudentProgressReportUpdateParameters, V1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRequest } from '@xng/reporting';
import { API_STUDENT_PROGRESS_REPORT } from '../../../../../api/api';
import { pushError } from '../../../../../context/slices/errorEntries';
import { ProgressReportApiParametersGenerator } from '../utils/progressReportApiParametersGenerator';
import { ServiceProviderResponse } from '../../../../../profile-sdk';
import FullPageLoadingScreen from '../../../../../design/high-level/common/full_page_loading_screen';

interface goalAreaProgressData {
  period: number;
  reportingPeriodStart: string;
  reportingPeriodEnd: string;
  makingAdequateProgress: "yes" | "no";
  progressMeasured: number;
  generalComments?: string;
  reportedProgress?: string;
  posted: boolean;
  isFinalized: boolean
}

enum StateKeys {
  period = "period",
  objectiveStartDate = "reportingPeriodStart",
  objectivesEndDate = "reportingPeriodEnd",
  makingAdequateProgress = "makingAdequateProgress",
  progressMeasured = "progressMeasured",
  generalComments = "generalComments",
  reportedProgress = "reportedProgress",
}

function createData(
  period: number,
  reportingPeriodStart: string,
  reportingPeriodEnd: string,
  makingAdequateProgress: "yes" | "no",
  progressMeasured: number,
  generalComments?: string,
  reportedProgress?: string,
  posted: boolean = true,
  isFinalized: boolean = false
): goalAreaProgressData {
  return {isFinalized, period, reportingPeriodStart, reportingPeriodEnd, makingAdequateProgress, progressMeasured, generalComments, reportedProgress, posted };
}



interface ObjectivesEditFilterTableProps {
  objectiveProgressDetails: ExtendedStudentProgressDetailsWithAggregatedProgress[];
  goalId?: string;
  sx?: React.CSSProperties;
  reportRunId:string;
  progressReportId:string;
  objectiveId:string;
  objectiveDetailsArrayPosition:number;
  studentName:string
}

const ObjectivesEditFilterTable: React.FC<ObjectivesEditFilterTableProps> = ({studentName, objectiveProgressDetails, goalId, sx, reportRunId, progressReportId,objectiveId,objectiveDetailsArrayPosition }) => {
  const dispatch = useProgressReporting().dispatch;
  const isPrintingSignal = useProgressReporting()?.state?.printingSignal;
  const showTeamAggregatedData = useProgressReporting()?.state?.settingsPopup?.showTeamAggregatedData;
  const showLastSessionData = useProgressReporting()?.state?.settingsPopup?.showLastSession;
  const currentlyLoggedInServiceProvider = useProgressReporting()?.state?.currentlyLoggedInServiceProvider
  const studentProgressReportFetched = useProgressReporting()?.state?.studentProgressReportFetched;
  const currentlySelectedServiceProvider = useProgressReporting()?.state?.settingsPopup?.serviceProviderSelected;
  const errorDispatcher = useXNGDispatch(); 
  const postProgressReportedMutation = useApiMutateData({
    mutationFn:async(data:V1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRequest)=> await API_STUDENT_PROGRESS_REPORT.v1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRaw(data),
    mutationKey:["v1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRaw"],
    onError:(err) => {
      displayError(err)
    }
  })
  // Memoized rows creation
  const rows = React.useMemo(() => {
    return objectiveProgressDetails.map((row) =>
      createData(
        row?.period as number,
        getFormattedDateTime(row?.periodStartDate?.toISOString() as string).date ?? '',
        getFormattedDateTime(row?.periodEndDate?.toISOString() as string).date ?? '',
        row?.makingAdequateProgress ? 'yes' : 'no',
        getProgress(
          showTeamAggregatedData,
          showLastSessionData,
          row
        ),
        row?.generalComments ?? '',
        row?.isFinalized ? row?.reportFinalizedBy as string : row?.progressReportedBy as string,
        row?.isFinalized,
        row?.isFinalized
      )
    );
  }, [objectiveProgressDetails,showTeamAggregatedData,showLastSessionData]);

  // Create unique rows based on reportingPeriodStart and reportingPeriodEnd
  const uniqueRows = React.useMemo(() => {
    const seen = new Set();
    return rows.filter(row => {
      const identifier = `${row.reportingPeriodStart}-${row.reportingPeriodEnd}`;
      if (!seen.has(identifier)) {
        seen.add(identifier);
        return true; // keep this row
      }
      return false; // skip this row
    });
  }, [rows]);

  const handlePostProgress =(goalId: string, period: number, rowPosition: number,generalComments:string,progressMeasured:number,makingAdequateProgress:boolean)=>{
    let  studentProgressReportUpdateParameters:StudentProgressReportUpdateParameters[]=[]
    if(showTeamAggregatedData){
         studentProgressReportUpdateParameters = ProgressReportApiParametersGenerator._objectiveGenerateParamsForTeamAggregatedData( 
           goalId,
           period, 
           currentlyLoggedInServiceProvider as ServiceProviderResponse,
           studentProgressReportFetched?.pageRecords ?? [],
           objectiveId,
           {
            generalComments,
            progressMeasured,
            makingAdequateProgress:makingAdequateProgress,
            studentName
          }
         )
    }else{
        studentProgressReportUpdateParameters = ProgressReportApiParametersGenerator._objectiveGenerateParamsForAggregatedData(
          goalId,
          period, 
          currentlyLoggedInServiceProvider as ServiceProviderResponse,
          studentProgressReportFetched?.pageRecords ?? [],
          currentlySelectedServiceProvider?.id as string,
          objectiveId,
          {
           generalComments,
           progressMeasured,
           makingAdequateProgress:makingAdequateProgress,
           studentName
          }
        )
    }
    postProgressReportedMutation.mutate({updateStudentProgressReportPostRequest:{
       studentProgressReportUpdateParameters
    }})
    dispatch({
      type:ProgressReportingActionType.SET_FINALIZING_PROGRESS_LOADER
    })
// make all goals with this id finalized
    if(showTeamAggregatedData){
      dispatch({
        type: ProgressReportingActionType.MAP_TEAM_AGGREGATED_FINALIZED_DATA_TO_ROOT_PROGRESS_REPORT_UNDER_EACH_SERVICE_PROVIDERS,
        payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Objective, ReportStatus.Posted, rowPosition,objectiveId,objectiveDetailsArrayPosition)
      })
    }

    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Objective, ReportStatus.Posted, rowPosition,objectiveId,objectiveDetailsArrayPosition)
    })
  }


  const handleUnpost=(goalId: string, period: number, rowPosition: number)=>{ 
    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Objective, ReportStatus.Unposted, rowPosition,objectiveId,objectiveDetailsArrayPosition)
    })
  }

  async function displayError(arg: Error) {
    errorDispatcher(
           pushError({ 
              titleID: "Failed to fetch student progress report!", 
              message: arg?.message 
            }))
  }


  if (isPrintingSignal && uniqueRows.length === 0) {
    return null;
  }
  if(isPrintingSignal && rows?.length === 0){
    return null
  } 
  return (
    <TableContainer sx={{ minWidth: 800, flexShrink: 0, ...sx }} component={Paper}>
      {/* Uncomment if you want to show buttons when not printing
      {!isPrintingSignal && (
        <Grid container>
          <Grid item xs={2} lg={2}>
            <ProgressReportingStandardBtn title="Add Reporting period" onSubmit={() => null} sxOverRide={{ marginBottom: "5px", width: "80%" }} />
          </Grid>
          <Grid item lg={2} />
          <Grid item xs={2} lg={2} ml={{ xs: 17 }}>
            <ProgressReportingStandardBtn title="Edit Progress Data" onSubmit={() => null} sxOverRide={{ marginBottom: "5px", width: "98%" }} />
          </Grid>
        </Grid>
      )} */}
       {postProgressReportedMutation.isPending && <FullPageLoadingScreen text='updating progress' pulse />}
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: GREY_COLOR }}>
            {Object.entries(StudentFitlerTableTitle).map(([key, value]) => (
              <TableCell sx={{ border: '1px solid rgba(0, 0, 0, 0.16)' }} align="center" key={key}>
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
       <IfElseBox if={uniqueRows?.filter(row => row.posted)?.length === 0 && isPrintingSignal} then={< EmptyTableBody />} else={
        <React.Fragment>
           {
              uniqueRows?.map((row, rowPosition) => isPrintingSignal ? row?.posted ? (
                <TableRow key={`${row.reportingPeriodStart}-${rowPosition}-${row.reportingPeriodEnd}-${row.posted}-${row.generalComments}-objective`}>
                  {
                    Object.entries(row).map(([key, value]) => (
                      <ConditionalProgressReportDataGridCellRenderer isFinalized = {row.isFinalized} makingAdequateProgressString={row.makingAdequateProgress} objectiveId={objectiveId} objectiveDetailsArrayPosition={objectiveDetailsArrayPosition} isPosted={row.posted} rowPosition={rowPosition} goalId={goalId as string} period={row?.period as number} key={key} identifier={key as StateKeys} value={value} />
                    ))
                  }  
                </TableRow>
              ) : null : (
                <TableRow key={`${row.reportingPeriodStart}-${rowPosition}-${row.reportingPeriodEnd}-${row.posted}-${row.generalComments}-objective`}>
                  {
                    Object.entries(row).map(([key, value]) => (
                      <ConditionalProgressReportDataGridCellRenderer isFinalized = {row.isFinalized}  makingAdequateProgressString={row.makingAdequateProgress}  objectiveId={objectiveId} objectiveDetailsArrayPosition={objectiveDetailsArrayPosition} isPosted={row.posted}  rowPosition={rowPosition} goalId={goalId as string} period={row?.period as number} key={key} identifier={key as StateKeys} value={value} />
                    ))
                  }
                  <IfElseBox if={row?.isFinalized}  then={
                    <TableCell sx={{ display: "grid", placeContent: "center", marginTop: "10px" }}>
                                <ProgressReportingStandardBtn title='Edit' 
                                  disabled={row?.isFinalized}
                                  onSubmit={() =>handleUnpost(goalId as string, row.period, rowPosition)}
                                  sxOverRide={{ marginLeft: "10px", background: "red" }} />
                    </TableCell>
                  }
                  else={
                       <TableCell sx={{ display: "grid", placeContent: "center", marginTop: "10px" }}> 
                                <ProgressReportingStandardBtn 
                                  title='Finalize'
                                  disabled={row?.isFinalized}
                                  onSubmit={() =>handlePostProgress(goalId as string, row.period, rowPosition,row?.generalComments as string,row?.progressMeasured,row?.makingAdequateProgress==="yes")} sxOverRide={{ marginLeft: "10px" }} />
                      </TableCell>
                  } />
                     
                </TableRow>
              ))
           }
        </React.Fragment>
        }/> 
   
      </Table>
    </TableContainer>
  );
};

 


const EmptyTableBody = () => {
  return (
    <TableBody>
      <TableRow> 
          <TableCell colSpan={Object.keys(StudentFitlerTableTitle).length} sx={{ border: '1px solid rgba(0, 0, 0, 0.16)' }} align="center"  >{  "No records posted"}</TableCell>
             
      </TableRow>
    </TableBody>
  )
}

interface ConditionalProgressReportDataGridCellRendererProps { 
  goalId?: string, 
  period?: number, 
  identifier: StateKeys, 
  value: string, 
  isPrintingOrDownloading?: boolean, 
  rowPosition?: number, 
  isPosted: boolean,
  objectiveId:string;
  objectiveDetailsArrayPosition:number;
  makingAdequateProgressString?:"yes" | "no",
  isFinalized:boolean
 }
 

const ConditionalProgressReportDataGridCellRenderer: React.FC<ConditionalProgressReportDataGridCellRendererProps> = ({ 
  isFinalized, goalId, period, identifier, value, isPrintingOrDownloading = false, rowPosition,isPosted,objectiveId,objectiveDetailsArrayPosition,makingAdequateProgressString="no" }) => {
  let children = null
  const [toggleStatus, setToggleStatus] = React.useState(makingAdequateProgressString === "yes")
  const [comment, setComment] = React.useState("")
  const dispatch = useProgressReporting()?.dispatch 
  const [progress,setProgress] = React.useState(0)
  const isPrinting = useProgressReporting()?.state?.printingSignal
  const handleUnpost= React.useCallback((goalId: string, period: number, rowPosition: number)=>{
    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Objective, ReportStatus.Unposted, rowPosition)
    })
  },[])

  const commonStyles:React.CSSProperties = React.useMemo(()=>({
    border:"1px solid rgba(0, 0, 0, 0.16)",
    borderTop: rowPosition !==0  ? "2px solid solid rgba(0, 0, 0, 0.16)" :""
  }),[])
  
 
  React.useEffect(()=>{
    if(identifier === StateKeys.generalComments){
      setComment(value)
    }
    else if(identifier === StateKeys.progressMeasured  ){
      setProgress(value as unknown as number)
      value = value ? `${value}%` : value
    }
 },[identifier,value])

 const handlePercentageChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      let val = Number(event.target.value) 
      if(isPosted){
        handleUnpost(goalId as string,period as number, rowPosition as number)
      }
      if(val >100){
        setProgress(0)
      }else if(val < 0){
        setProgress(100)
      }else {
        setProgress(val)
      }
},[isPosted])

const handleProgressMeasuredOnBlur = React.useCallback(()=>{ 
  dispatch({
    type: ProgressReportingActionType.UPDATE_PROGRESS_MEASURED_IN_REPORTING_DATA,
    payload: ProgressMeasuredPayloadManager.of(goalId as string, period as number,ReportTypes.Objective, rowPosition as number, progress, objectiveId, objectiveDetailsArrayPosition)
  })
},[goalId,period,progress,rowPosition])

const handleGeneralCommentsChange = React.useCallback((e:React.ChangeEvent<HTMLTextAreaElement>)=>{
  setComment(e.target.value)
  if(isPosted){ 
        handleUnpost(goalId as string,period as number,rowPosition as number)
  }
 },[isPosted])

const handleGeneralCommentsOnBlur = React.useCallback(()=>{
  dispatch({
    type:ProgressReportingActionType.UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA,
    payload:GeneralCommentsPayloadManager.of(
      goalId as string,
      period as number,
      comment,ReportTypes.Objective,
      rowPosition as number,
      objectiveId, 
      objectiveDetailsArrayPosition
    ) 
  }) 
 },[goalId,period,comment,rowPosition])

 const handleMakingAdequateProgress = React.useCallback(()=>{
  setToggleStatus(!toggleStatus)
  dispatch({
    type:ProgressReportingActionType.HANDLE_MAKING_ADEQUATE_PROGRESS,
    payload:IsMakingAdequateProgressPayloadManager.of(
      goalId as string,
      period as number,
      ReportTypes.Objective,
      rowPosition as number,
      !toggleStatus ,
      objectiveId, 
      objectiveDetailsArrayPosition 
    ) 
  }) 
 },[ goalId,period,comment,ReportTypes.Goal,rowPosition,objectiveId,objectiveDetailsArrayPosition,toggleStatus])

 
  if(!isFinalized && isPrinting)return null
 
  children = {
    [StateKeys.period]: <TableCell align="center" sx={{ ...commonStyles, width: "5%" }} >{(rowPosition as number) +1}</TableCell>,
    [StateKeys.objectiveStartDate]: <TableCell align="center" sx={{ ...commonStyles, minWidth: "120px" , width: "15%" }}>{value}</TableCell>,
    [StateKeys.objectivesEndDate]: <TableCell align="center" sx={{ ...commonStyles, minWidth: "120px" , width: "15%" }} >{value}</TableCell>,
    [StateKeys.makingAdequateProgress]: (<TableCell sx={{...commonStyles , width: "10%" }}>
      <RadioGroup name='makeAdequateProgress' sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
        {
          isFinalized ?
          makingAdequateProgressString
            :
            (
              <>
                <FormControlLabel value={"Yes"} control={<XNGRadio disabled={isFinalized} checked={toggleStatus} />} label="Yes" onChange={handleMakingAdequateProgress} />
                <FormControlLabel value={"No"} control={<XNGRadio disabled={isFinalized} checked={!toggleStatus} />} label="No" onChange={handleMakingAdequateProgress} />
              </>
            )
        }
      </RadioGroup>
    </TableCell>),
    [StateKeys.progressMeasured]: <TableCell align="center" onBlur={handleProgressMeasuredOnBlur} sx={{ ...commonStyles, width: "20%" }} > 
        {!isFinalized? <> <Input type="number" disabled={isFinalized} onChange={ handlePercentageChange}  value={progress} sx={{  width:"60px" }}  /> %</> : <>{value}%</>} 
    </TableCell>,
    [StateKeys.generalComments]: (
      <TableCell align="center" sx={{ ...commonStyles, width: "30% !important",maxWidth:"150px" }} >
        {!isFinalized?
         <TextareaAutosize 
         disabled={isFinalized}
         onChange={handleGeneralCommentsChange} 
         onBlur={handleGeneralCommentsOnBlur}
         style={{minHeight:"100px" ,resize:"none",width:"100%"}} placeholder='General Comments' value={comment} />:
                  <Typography  textAlign={"left"} sx={{  minWidth: "100px", overflow: "hidden",  textOverflow: "ellipsis", wordWrap: "break-word" }}>
                     {comment}
                </Typography>
         }
      </TableCell>),
    [StateKeys.reportedProgress]: <TableCell align="center" sx={{ ...commonStyles, width: "10%", minWidth: "150px" }} >{value}</TableCell>

  }[identifier]

  let printingOrDownloadingCells = null
  printingOrDownloadingCells = {
    [StateKeys.period]: <TableCell align="center" sx={{ ...commonStyles, borderTop: "3px solid rgba(0, 0, 0, 0.16)", borderLeft: "3px solid rgba(0, 0, 0, 0.16)", width: "5%" }} >{rowPosition}</TableCell>,
    [StateKeys.objectiveStartDate]: <TableCell align="center" sx={{ ...commonStyles, minWidth: "120px", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "15%" }}>{value}</TableCell>,
    [StateKeys.objectivesEndDate]: <TableCell align="center" sx={{ ...commonStyles, minWidth: "120px", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "15%" }} >{value}</TableCell>,
    [StateKeys.makingAdequateProgress]: (<TableCell sx={{ ...commonStyles, borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "10%" }}>
      <Stack sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
        {value}
      </Stack>
    </TableCell>),
    [StateKeys.progressMeasured]: <TableCell align="center" sx={{ ...commonStyles, width: "20%" }} >{value}</TableCell>,
    [StateKeys.generalComments]: (
      <TableCell align="center" sx={{ ...commonStyles, width: "30% !important" }}  >
        {value}
      </TableCell>),
    [StateKeys.reportedProgress]: <TableCell align="center" sx={{ ...commonStyles, width: "10%", minWidth: "150px" }}>{value}</TableCell>

  }[identifier]


  return isPrintingOrDownloading ? printingOrDownloadingCells : children
};

export default React.memo(ObjectivesEditFilterTable);
 
