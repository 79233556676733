import { useState } from "react";
import { SxProps, Typography } from "@mui/material";
import { getSizing } from "../../../design/sizing";
import XNGHyperlink from "../../../design/low-level/hyperlink";
import dayjs from "dayjs";
import { JustDay_FORMATTER } from "../../../design/date_format";
import Box from "../../../design/components-dev/BoxExtended";
import { SessionResponse } from "../../../session-sdk";
import useNotatorProgressColor from "../../../hooks/useNotatorProgressColor";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { timezoneAdjustedStartOrEndTimes } from "../../../utils/timeZones";
import EditSessionMetadataModal from "../modals/edit_session_metadata_modal";
import { useNotatorTools } from "../tools";
import useTotalBillableTimesForAllStudentsInCurrentSession from "../hooks/useTotalBillableTimesForAllStudentsInCurrentSession";
import ProgressBar from "./progress_bar_with_gradient_for_exceeding_value";
import useCheckIfPersonalCareSession from "../hooks/use_check_is_personal_care_session";

function SessionMetadataEditor(
  props: Readonly<{
    saveSession: (freshSession: SessionResponse) => Promise<SessionResponse>;
    disableInputs?: boolean;
  }>,
) {
  const { draftSession: editedSession } = useNotatorTools();
  const { color } = useNotatorProgressColor(editedSession.status ?? "Info");
  const totalBillableMinutesForAllStudents = useTotalBillableTimesForAllStudentsInCurrentSession();
  
  // STATES
  const [schedulerModalOpen, setSchedulerModalOpen] = useState(false);
  const userStateInUS = useXNGSelector(selectStateInUS);

  // CONSTANTS
  const METADATA_ROW: SxProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: getSizing(4),
    maxHeight: getSizing(4),
  };

  // Timezone Adjustments
  const { timezoneAdjustedStartTime: START, timezoneAdjustedEndTime: END } =
    timezoneAdjustedStartOrEndTimes(
      userStateInUS,
      "display",
      dayjs(editedSession.meetingDetails?.startTime),
      dayjs(editedSession.meetingDetails?.endTime),
    );
  
  /***
   * TOTAL MINUTES ALLOTED FOR THE SESSION 
   */
  const sessionMinutes = dayjs(END).diff(START, "minutes")
  const isPersonalCareSession = useCheckIfPersonalCareSession()
  return (
    <Box sx={{ display: "flex", marginLeft: getSizing(5), width: "100%", maxWidth: getSizing(47) }}>
      <EditSessionMetadataModal
        open={schedulerModalOpen}
        startDate={editedSession.meetingDetails?.startTime!}
        endDate={editedSession.meetingDetails?.endTime!}
        onClose={() => setSchedulerModalOpen(false)}
        editedSession={editedSession}
        saveSession={props.saveSession}
      />
      <Box name="left" sx={{ minWidth: getSizing(5), paddingTop: getSizing(1) }}>
        <Box
          sx={{
            width: "20px",
            height: "20px",
            border: [2, 5].includes(editedSession.status!) ? "1px solid white" : "none",
            borderRadius: 999,
            bgcolor: color,
          }}
        />
      </Box>
      <Box name="right" sx={{ minWidth: getSizing(34), width: "100%" }}>
        <Box sx={METADATA_ROW}>
          <Typography variant="h6">{editedSession.title}</Typography>
          <XNGHyperlink
            onClick={() => {
              if (!props.disableInputs) {
                setSchedulerModalOpen(true);
              }
            }}
            color="white"
          >
            Edit
          </XNGHyperlink>
        </Box>
        <Box sx={METADATA_ROW}>
          <Typography variant="body2">{editedSession.service?.name}</Typography>
          <Typography variant="body2">
            {dayjs(editedSession.meetingDetails?.date).format(JustDay_FORMATTER)}
          </Typography>
        </Box>
        <Box sx={METADATA_ROW}>
          <Typography variant="body2">
            at {editedSession.meetingDetails?.location?.name} -{" "}
            {editedSession.meetingDetails?.location?.description}
          </Typography>
          <Typography variant="body2">
            {dayjs(START).format("hh:mm A")}-{dayjs(END).format("hh:mm A")}
          </Typography>
        </Box>
        <Box sx={METADATA_ROW}>
          <Typography variant="body2">
            {editedSession.groupSetting
              ? `In a group setting (${editedSession.groupSize} students)`
              : "In an individual setting"}
          </Typography>
          <Typography variant="body2">{dayjs(END).diff(START, "minutes")} minutes</Typography>
        </Box>
        {/* shows overage billable minutes  */}
        {isPersonalCareSession&&<ProgressBar totalBilledTime={totalBillableMinutesForAllStudents} totalSessionTime={sessionMinutes}/>}
      </Box>
    </Box>
  );
}

export default SessionMetadataEditor;
