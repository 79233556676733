import { API_CLIENT_SESSION_COUNTS } from "../../../../../../api/api";
import { V1SessionReportsClientSessionCountsDownloadCsvPostRequest } from "@xng/reporting";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";
import useApiMutatePollData from "../../../../../../api/hooks/use_api_mutate_poll_data";
import { MSBInferPollingApiResponse } from "../../../../../../types/msb_infer_polling_api_response";

type Data = MSBInferPollingApiResponse<
  typeof API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsDownloadCsvPostRaw
>;
type Body = V1SessionReportsClientSessionCountsDownloadCsvPostRequest; // generic parameter B is used to define body the type of the useApiMutateData hook

export const useApiMutatePollUnpostedSessionsCsvDownload = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  return useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsDownloadCsvPostRaw(body),
    mutationKey: ["v1SessionReportsClientSessionCountsDownloadCsvPostRaw"],
    ...(paramObject?.options || {}),
  });
};

export type {
  Data as UnpostedSessionsReportCsvDownloadData,
  Body as UnpostedSessionsReportCsvDownloadBody,
};
