import {
  Checkbox,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { MSBIconRenderer } from "../../../../../fortitude";
import { IoAddCircle, IoCheckmarkCircle } from "react-icons/io5";
import { ValidationResponse } from "../../../../../types/validation_response";
import { NotatorObservation } from "../types/observation";

/**
 * Purely presentational component that handles staging singular, new custom observations.
 */
export function StagingInput(
  props: Readonly<{
    value: NotatorObservation;
    onChange: (v: NotatorObservation) => void;
    onValidate: (v: NotatorObservation) => ValidationResponse;
    onConfirm: (v: NotatorObservation) => void;
    disabled?: boolean;
  }>,
) {
  const { onValidate, onConfirm, value, onChange } = props;

  const validationResponse = useMemo(() => onValidate(value), [value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && validationResponse.isValid) {
        onConfirm(value);
      }
    },
    [onConfirm, value, validationResponse.isValid],
  );

  return (
    <Tooltip
      title={validationResponse.error}
      placement="top"
      arrow
      disableInteractive
      open={!validationResponse.isValid}
    >
      <OutlinedInput
      disabled={props.disabled}
        value={value.text}
        onChange={(e) => onChange({ text: e.target.value, checked: value.checked })}
        onKeyDown={handleKeyDown}
        sx={{
          p: 0,
          height: "2.5rem",
          ".plus-icon": { transition: "all .1s ease", pointerEvents: "none" },
          ".check-icon": { display: "none" },
          ":focus-within, :hover": {
            ".plus-icon": { display: "none" },
            ".check-icon": { display: "block" },
          },
        }}
        placeholder="New observation..."
        startAdornment={
          <InputAdornment position="start" sx={{ paddingLeft: 0, mr: 0 }}>
            <Checkbox
              checked={value.checked}
              onChange={() => onChange({ text: value.text, checked: !value.checked })}
              disabled={props.disabled}
            />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" sx={{ pr: ".3rem" }}>
            <CheckIcon
              onClick={() => {
                if (validationResponse.isValid) onConfirm(value);
              }}
              disabled={!validationResponse.isValid}
            />
            <PlusIcon />
          </InputAdornment>
        }
      />
    </Tooltip>
  );
}

function CheckIcon(props: Readonly<{ onClick: () => void; disabled: boolean }>) {
  const { onClick, disabled } = props;
  const { palette } = useTheme();

  return (
    <div className="check-icon">
      <IconButton onClick={onClick} sx={{ m: 0, right: "-.5rem" }} disabled={disabled}>
        <MSBIconRenderer
          color={disabled ? palette.text.disabled : palette.primary.main}
          i={<IoCheckmarkCircle />}
          size="lg"
        />
      </IconButton>
    </div>
  );
}

function PlusIcon() {
  const { palette } = useTheme();

  return (
    <div className="plus-icon">
      <MSBIconRenderer color={palette.text.disabled} i={<IoAddCircle />} size="lg" />
    </div>
  );
}
