import { useCallback, useEffect, useMemo, useState } from "react";
import { GoalDisplay } from "../../../../profile-sdk";
import { useNotatorTools } from "../../tools";
import { Typography, Button, Dialog, Box, Stack } from "@mui/material";
import { GoalCard } from "../../components/GoalCard";
import { MSBScrollShadowViewport, MSBSearchSingleSelect } from "../../../../fortitude";
import MSBClose from "../../../../fortitude/components/button_close";
import { pushError } from "../../../../context/slices/errorEntries";
import { useXNGDispatch } from "../../../../context/store";
import { produce } from "immer";
import { getGoalDisplayIsWithinServiceArea } from "./utils/goal_display_is_within_service_area";
import { ServiceArea } from "../../../../session-sdk";
import { UnifiedGoal } from "./hooks/use_unified_goals";

const CONSISTENT_GAP = "1rem";
const STATIC_CARD_VIEWPORT_HEIGHT = "calc(100vh - 20rem)";

export function EditGoalListModal(
  props: Readonly<{
    open: boolean;
    onClose: () => void;
    fetchedActiveGoals: GoalDisplay[] | null;
    selectedStudentIndex: number;
    shownGoalReferenceList: UnifiedGoal[];
  }>,
) {
  const { selectedStudentIndex, open, onClose, fetchedActiveGoals } = props;
  const {
    setDraftSession,
    editGoalListModalGoalAreaDropdownOptions,
    goalAreasOfFocusInventory,
    draftSession,
  } = useNotatorTools();
  const dispatch = useXNGDispatch();
  const sessionServiceArea: ServiceArea | null = useMemo(
    () => draftSession.service?.area ?? null,
    [draftSession],
  );

  const [selectedGoalAreaOption, setSelectedGoalAreaOption] = useState<string>("All");

  const goalOptions = useGoalOptionsByFilter({
    goalDisplays: fetchedActiveGoals ?? [],
    selectedGoalAreaOfFocus: selectedGoalAreaOption,
  });

  const logLocalError = useCallback(
    (titleID: string) => {
      dispatch(
        pushError({
          titleID,
        }),
      );
    },
    [dispatch, pushError],
  );

  const { goals, handleToggle } = useSelectedGoalsState({
    selectedStudentIndex,
    onError: (title) => logLocalError(title),
  });

  const handleSaveClick = ()=>{
    setDraftSession(
      produce((draft) => {
        const careProvisionLedger =
          draft.studentJournalList?.[selectedStudentIndex].careProvisionLedger;

        if (!goals || !careProvisionLedger) {
          logLocalError("Issue attempting to save selected goals");
          return;
        }

        careProvisionLedger.additionalGoals = goals.additionalGoals;
        careProvisionLedger.excludedGoals = goals.excludedGoals;
      }),
    );
    props.onClose();
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xl">
      <Box sx={{ overflow: "hidden", p: CONSISTENT_GAP }}>
        <Stack gap={CONSISTENT_GAP} mb={CONSISTENT_GAP}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Edit Goal List</Typography>
            <MSBClose onClick={() => onClose()} />
          </Box>

          <Typography variant="body1">
            You can edit which goals are visible within your session. Please select the goals you
            would like to appear in this session series.
          </Typography>

          <MSBSearchSingleSelect<string>
            label="Area of Focus"
            selectedOption={selectedGoalAreaOption}
            options={["All", ...(editGoalListModalGoalAreaDropdownOptions ?? [])]}
            getOptionLabel={(v) => v}
            onChange={(v) => setSelectedGoalAreaOption(v ?? "All")}
            defaultOption="All"
          />
        </Stack>

        <MSBScrollShadowViewport
          maxHeight={STATIC_CARD_VIEWPORT_HEIGHT}
          sx={{
            display: "flex",
            gap: CONSISTENT_GAP,
            minHeight: STATIC_CARD_VIEWPORT_HEIGHT,
            overflowY: "auto",
            overflowX: "hidden",
            maxWidth: "78.5rem",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
          refreshDependencies={[goalOptions.length]}
        >
          {goalOptions.map((goal) => {
            const isAdditional = goals?.additionalGoals?.includes(goal.internalId ?? "") ?? false;
            const isExcluded = goals.excludedGoals?.includes(goal.internalId ?? "") ?? false;
            const goalIsCurrentlyRenderingForStudent = props.shownGoalReferenceList.some(
              (g) => g.goalDisplay.internalId === goal.internalId,
            );

            const goalIsChecked =
              (isAdditional || goalIsCurrentlyRenderingForStudent) && !isExcluded;

            return (
              <GoalCard
                key={goal.internalId}
                goal={goal}
                isChecked={goalIsChecked}
                onClick={() => handleToggle(goal, goalIsChecked ? "removing" : "adding")}
              />
            );
          })}
        </MSBScrollShadowViewport>

        <Box
          paddingY={CONSISTENT_GAP}
          mt={CONSISTENT_GAP}
          display={"flex"}
          justifyContent={{ justifyContent: "center", sm: "right" }}
          sx={{
            paddingRight: CONSISTENT_GAP,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { width: "100%", sm: "fit-content" },
              maxWidth: "295px",
            }}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

function useGoalOptionsByFilter(
  props: Readonly<{ selectedGoalAreaOfFocus: string | null; goalDisplays: GoalDisplay[] }>,
) {
  const { goalDisplays: goals } = props;
  const selectedGoalAreaOfFocus = props.selectedGoalAreaOfFocus ?? "All";

  const [filteredGoals, setFilteredGoals] = useState<GoalDisplay[]>(goals);
  useEffect(() => {
    if (selectedGoalAreaOfFocus === "All") {
      setFilteredGoals(goals);
    } else {
      const tempFilteredGoals = goals.filter(
        (g) =>
          g.goalAreaOfFocus?.name?.toLocaleLowerCase() ===
          selectedGoalAreaOfFocus.toLocaleLowerCase(),
      );
      setFilteredGoals(tempFilteredGoals);
    }
  }, [selectedGoalAreaOfFocus, goals]);

  return filteredGoals;
}

type SelectedGoalsState = { additionalGoals: string[]; excludedGoals: string[] };
function useSelectedGoalsState(props: {
  selectedStudentIndex: number;
  onError: (title: string) => void;
}) {
  const { selectedStudentIndex } = props;
  const { draftSession } = useNotatorTools();

  const [goals, setGoals] = useState<SelectedGoalsState>({
    additionalGoals:
      draftSession.studentJournalList?.[selectedStudentIndex].careProvisionLedger
        ?.additionalGoals ?? [],
    excludedGoals:
      draftSession.studentJournalList?.[selectedStudentIndex].careProvisionLedger?.excludedGoals ??
      [],
  });

  const handleToggle = useCallback(
    (goal: GoalDisplay, action: "adding" | "removing") => {
      setGoals(
        produce((draft) => {
          const internalID = goal.internalId;
          // Display errors if any obligatory data is null
          if (internalID === undefined) {
            props.onError("Goal Internal ID was undefined!");
            return;
          }
          if (!draft) {
            props.onError("Issue(s) occurred attempting to read student CareProvisionLedger!");
            return;
          }

          // Create the arrays if they don't exist yet
          if (!draft.additionalGoals) {
            draft.additionalGoals = [];
          }
          if (!draft.excludedGoals) {
            draft.excludedGoals = [];
          }

          // Handle user action
          if (action === "adding") {
            draft.additionalGoals.push(internalID);
            draft.excludedGoals = draft.excludedGoals.filter((goal) => goal !== internalID);
          }
          if (action === "removing") {
            draft.excludedGoals.push(internalID);
            draft.additionalGoals = draft.additionalGoals.filter((goal) => goal !== internalID);
          }
        }),
      );
    },
    [setGoals, selectedStudentIndex],
  );

  return { goals, handleToggle };
}
