import { useNotatorTools } from "../tools";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { timezoneAdjustedStartOrEndTimes } from "../../../utils/timeZones";
import dayjs from "dayjs";

 

const useSessionTime = ():number => {
  const { draftSession: editedSession } = useNotatorTools();
  const userStateInUS = useXNGSelector(selectStateInUS);
  const { timezoneAdjustedStartTime: START, timezoneAdjustedEndTime: END } =
    timezoneAdjustedStartOrEndTimes(
      userStateInUS,
      "display",
      dayjs(editedSession.meetingDetails?.startTime),
      dayjs(editedSession.meetingDetails?.endTime),
    );
  
  /***
   * TOTAL MINUTES ALLOTED FOR THE SESSION 
   */
  const sessionMinutes = dayjs(END).diff(START, "minutes")

  return sessionMinutes
}

export default useSessionTime