import { useMemo } from 'react'

const useSemiCircleChartData = (totalBillableMinutes:number|undefined,sessionMinutes:number,supportServiceMinutes:number|undefined) => { 
    const chartData = useMemo(()=>{
      const remainingTime = Math.max(sessionMinutes - (totalBillableMinutes ?? 0) - (supportServiceMinutes ?? 0), 0)
      const chartData = [
        { value: totalBillableMinutes ?? 0, category: `${totalBillableMinutes} Minutes of Billable Activities`, color: "#4bc0c0" }, // Green
        { value: supportServiceMinutes ?? 0, category: `${supportServiceMinutes} Support Service Minutes`, color: "#36a2eb" }, // Red
        { value: remainingTime, category: `${remainingTime} Remaining Minutes`, color: "#bdbdbd" }, // Blue
      ];
      return chartData 
    },[totalBillableMinutes,sessionMinutes,supportServiceMinutes])

    return chartData
}

export default useSemiCircleChartData